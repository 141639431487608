/* Component  */
import React from "react";
/* Library  */
import { Navigate } from "react-router-dom";
/* Services  */
import baseComponent from "../../shared/baseComponent";
import ProductdashboardService from "../../services/domainServices/productdashboardService";
import CountUp from "react-countup";
import { Bars } from "react-loader-spinner";
export default class ProductDashboard extends baseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props);
    this.productdashboardService = new ProductdashboardService();
    this.state = {
      ProductCountList: [],
      ProductCount: [],
      ProductInActiveCount: [],
      TotalBackordered: [],
      TotalInProduction: [],
      TotalInStock: [],
      TotalSuspended: [],
      setIsLoad: false,
    };
    this.productdashboardService.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.productdashboardService.onGetproductCountList();
    document.title = "User List";
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex">
        <div className="relative flex flex-col flex-1">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center">
                <div className="">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Product Dashboard
                  </h1>
                </div>
              </div>

              <div className="grid grid-cols-dash-12 gap-6 UserDashboard ">
                <div className="card-part first-card col-span-full sm:col-span-6 xl:col-span-3">
                  <div class="card-body flex items-center">
                    <div class="icon mr-3">
                      <svg
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 122.88 114.58"
                      >
                        <path d="M118.13,9.54a3.25,3.25,0,0,1,2.2.41,3.28,3.28,0,0,1,2,3l.57,78.83a3.29,3.29,0,0,1-1.59,3L89.12,113.93a3.29,3.29,0,0,1-2,.65,3.07,3.07,0,0,1-.53,0L3.11,105.25A3.28,3.28,0,0,1,0,102V21.78H0A3.28,3.28,0,0,1,2,18.7L43.89.27h0A3.19,3.19,0,0,1,45.63,0l72.5,9.51Zm-37.26,1.7-24.67,14,30.38,3.88,22.5-14.18-28.21-3.7Zm-29,20L50.75,64.62,38.23,56.09,25.72,63.17l2.53-34.91L6.55,25.49V99.05l77.33,8.6V35.36l-32-4.09Zm-19.7-9.09L56.12,8,45.7,6.62,15.24,20l16.95,2.17ZM90.44,34.41v71.12l25.9-15.44-.52-71.68-25.38,16Z" />
                      </svg>
                    </div>
                    <div>
                      <h2 class="text-white invoice-num">
                        <CountUp
                          duration={5}
                          end={this.state.ProductCount.totalProduct}
                        />
                      </h2>
                      <span class="text-white fs-18">Total Product</span>
                    </div>
                  </div>
                </div>
                <div className="card-part second-card col-span-full sm:col-span-6 xl:col-span-3">
                  <div class="card-body flex items-center">
                    <div class="icon mr-3">
                    <svg
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 122.88 114.58"
                      >
                        <path d="M118.13,9.54a3.25,3.25,0,0,1,2.2.41,3.28,3.28,0,0,1,2,3l.57,78.83a3.29,3.29,0,0,1-1.59,3L89.12,113.93a3.29,3.29,0,0,1-2,.65,3.07,3.07,0,0,1-.53,0L3.11,105.25A3.28,3.28,0,0,1,0,102V21.78H0A3.28,3.28,0,0,1,2,18.7L43.89.27h0A3.19,3.19,0,0,1,45.63,0l72.5,9.51Zm-37.26,1.7-24.67,14,30.38,3.88,22.5-14.18-28.21-3.7Zm-29,20L50.75,64.62,38.23,56.09,25.72,63.17l2.53-34.91L6.55,25.49V99.05l77.33,8.6V35.36l-32-4.09Zm-19.7-9.09L56.12,8,45.7,6.62,15.24,20l16.95,2.17ZM90.44,34.41v71.12l25.9-15.44-.52-71.68-25.38,16Z" />
                      </svg>
                    </div>
                    <div>
                      <h2 class="text-white invoice-num">
                        <CountUp
                          duration={5}
                          end={
                            this.state.ProductInActiveCount.totalIsActiveProduct
                          }
                        />
                      </h2>
                      <span class="text-white fs-18">Total Active Product</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-form mt-10">
                <div className="mb-4">
                  <h4 className="text-slate-800 font-bold">
                    Product Availability
                  </h4>
                </div>
                {this.state.setIsLoad ? (
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: "150px" }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad}
                  />
                ) : (
                  <div className="col-span-full productdashboard-card xl:col-span-8 bg-white border-radius-10 activity_details">
                    <div className="p-5">
                      <div className="">
                        <div className="grid grid-cols-1 backordered-card md:grid-cols-2 lg:grid-cols-2 gap-6">
                          <div className="flex p-6 gap-2 useractivitydetails">
                            <label>
                              Total Backordered :{" "}
                              {this.state.TotalBackordered.totalBackordered}
                            </label>
                          </div>
                          <div className="flex p-6 gap-2 useractivitydetails">
                            <label>
                              Total In Production :{" "}
                              {this.state.TotalInProduction.totalInProduction}
                            </label>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4">
                          <div className="flex p-6 gap-2 useractivitydetails">
                            <label>
                              Total In Stock :{" "}
                              {this.state.TotalInStock.totalInStock}
                            </label>
                          </div>
                          <div className="flex p-6 gap-2 useractivitydetails">
                            <label>
                              Total Suspended :{" "}
                              {this.state.TotalSuspended.totalSuspended}{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
