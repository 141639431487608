import React from 'react'
import BaseComponent from "../../shared/baseComponent";
import SettingServices from '../../services/domainServices/settingService';
import ProductCode from '../product-details/ProductCode';
import ExportLink from '../ExportLink';
export default class Setting extends BaseComponent {
    constructor(props) {
        super(props)
        this.settingService = new SettingServices();
        this.state = {
            setIsLoad: false,
            isBtnLoading: false,
            totalIpCount: ''
        }
        this.settingService.onUpdateState = this.updateState.bind(this);
    }
    SyncFun() {
        this.settingService.ongetAllIPAddresses()
    }
    componentDidMount() {
        this.settingService.onGetIPAddressCounts()
    }

    render() {
        return (
            <>
                <div className="flex overflow-hidden">
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        <main>
                            <div className="pr-2 sm:pr-3 lg:pr-4  py-5 w-full max-w-9xl mx-auto">
                                <div className="sm:flex sm:justify-between sm:items-center">
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Setting Configuration</h1>
                                    </div>
                                </div>
                                <div className="col-span-full xl:col-span-8 bg-white shadow-lg border-radius-10 border border-slate-200 activity_details">
                                    <div className="p-5">
                                        <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1 gap-6'>
                                            <div className='d-flex p-6 gap-3 useractivitydetails sm:flex sm:justify-between'>
                                                <button className=" btn-primary  gap-2">
                                                    <span><b>Remaning IP Sync : </b><span className='sync_count'>{this.state.totalIpCount}</span></span>
                                                </button>

                                                {this.state.totalIpCount === 0 ?
                                                    <button className="btn gap-2 commanColorbtn text-dark not-allowed" disabled>
                                                        <><span>IP Sync</span>
                                                            <i class='fas fa-file-excel'></i></>
                                                    </button>
                                                    :
                                                    <button className="btn sync-button gap-2 commanColorbtn text-dark" onClick={() => this.SyncFun()}>
                                                        {this.state.setIsLoad ? <div class="loader"></div> : <><span>IP Sync</span>
                                                            <i class='fas fa-file-excel'></i></>}
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <ProductCode />

                <ExportLink />
            </>
        )
    }
}
