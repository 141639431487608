/* Component  */
import React from 'react';
import Button from '../../components/common/Button';
import BaseComponent from '../../shared/baseComponent';

/* Library  */
import { Navigate } from 'react-router';
import { ButtonTypes } from '../../utils/Constants';
import { checkParameters } from "../../utils/Encryption";
import ProductDetails from '../../components/productDetails/ProductDetails';

/* Services  */
import ProductDomainService from '../../services/domainServices/productDomainService';

export class ProductDetail extends BaseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.productService = new ProductDomainService();
    this.state = {
      priceList: [],
      setIsLoad: false,
      isBtnLoading: false,
      productsDetails: [],
      deliveryDetails: {
        deliveryTimeSpan: ""
      },
      productStockAndDeliveryTime: [],
      openTab: 1
    }
    this.handleChange = this.handleChange.bind(this);
    this.onPriceSubmit = this.onPriceSubmit.bind(this);
    this.onDeliveryTime = this.onDeliveryTime.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.productService.onUpdateState = this.updateState.bind(this);
  }

  // API Function
  componentDidMount() {

    let params = new URLSearchParams(window.location.search);
    let aurumCatalogId = params.get("");
    if (aurumCatalogId && (aurumCatalogId = checkParameters(aurumCatalogId, "String"))) {
      this.productService.onGetProductById(aurumCatalogId);
      document.title = 'Product details';
    }
  }

  updateproductById() {
    this.productService.onUpdateProductById(this.state.productsDetails);
  }

  updateProductDeliveryTime = (deliveryAvailibilityId, deliveryTimeSpan) => {
    let request = {
      deliveryAvailibilityId: deliveryAvailibilityId,
      deliveryTimeSpan: deliveryTimeSpan,
    }
    this.productService.onUpdateDeliveryProduct(request);
  }

  // price
  updateProductPriceById = (priceId, price) => {
    let pricee = price.split('$')
    let request = {
      priceId: priceId,
      price: pricee[1],
    }
    this.productService.onUpdateProductPrice(request);
  }

  // Event Function 
  handleChange = (index, packQuantity) => {
    let quantityList = [...this.state.productStockAndDeliveryTime]
    quantityList[index].deliveryTimeSpan = packQuantity;
    this.setState({ deliveryDetails: quantityList });
  }
  handleChangePrice = (index, packQuantity, productSize) => {
    let quantityList = [...this.state.priceList]
    quantityList[index].price = packQuantity;
    this.setState({ deliveryDetails: quantityList });
  }

  // Other Function 
  setFormValues = (key, value) => {
    let newFormvalues = { ...this.state.productsDetails };
    newFormvalues[key] = value;
    this.setState({ productsDetails: newFormvalues });
  }
  onDeliveryTime = (deliveryAvailibilityId, deliveryTimeSpan) => {
    this.updateProductDeliveryTime(deliveryAvailibilityId, deliveryTimeSpan)
  }
  back = () => {
    this.setState({ redirect: "/ProductList" });
  }
  onPriceSubmit = (priceId, price) => {
    this.updateProductPriceById(priceId, price)
  }


  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-4 w-full max-w-9xl mx-auto">
              <div className='flex items-center justify-between mb-4'>
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Product Details</h1>
                </div>
                <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.back()} />
              </div>
              <ProductDetails
                productStockAndDeliveryTime={this.state.productStockAndDeliveryTime}
                handleChange={this.handleChange.bind(this)}
                productsDetails={this.state.productsDetails}
                onDeliveryTime={this.onDeliveryTime.bind(this)}
                priceList={this.state.priceList}
                handleChangePrice={this.handleChangePrice.bind(this)}
                onPriceSubmit={this.onPriceSubmit.bind(this)}
                form={this.productService.form}
                formValues={this.state.productsDetails}
                setFormValues={this.setFormValues.bind(this)}
                isBtnLoading={this.state.isBtnLoading}
                submitForm={this.updateproductById.bind(this)}
                cancel={this.back.bind(this)}
                setIsLoad={this.state.setIsLoad}
              />
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default ProductDetail;
