/* Component  */
import { axiosGet, axiosGetAuthorize, axiosGetMultiParamsAuthorize, axiosGetMultiParamsFileAuthorizeblob, axiosPostAuthorize } from '../AxiosRequests';
import { AddInformaticsComponentWizard, GetInformaticsComponentWizardByIdList, GetInformaticsComponentWizardById, GetComponentWizardsList, DeleteInformaticsComponentWizard, UpdateInformaticsComponentWizard , ExportGetComponentWizardsList} from '../ApiEndPoints';

export default class ComponentWizardServices {

    async addInformaticsComponentWizard(request) {
        return axiosPostAuthorize(AddInformaticsComponentWizard, request);
    }
    async getInformaticsComponentWizardByIdList(request) {
        return axiosPostAuthorize(GetInformaticsComponentWizardByIdList, request);
    }
    async getInformaticsComponentWizardById(request) {
        return axiosGetAuthorize(GetInformaticsComponentWizardById, request);
    }
    async updateInformaticsComponentWizard(request) {
        return axiosPostAuthorize(UpdateInformaticsComponentWizard, request);
    }
    async deleteInformaticsComponentWizard(request) {
        return axiosPostAuthorize(DeleteInformaticsComponentWizard, request);
    }
    async getComponentWizardsList(request) {
        // return axiosGetMultiParams(GetComponentWizardsList, request);
        return axiosGetMultiParamsAuthorize(GetComponentWizardsList, request);
    }
    async exportGetComponentWizardsList(request) {
        return axiosGetMultiParamsFileAuthorizeblob(ExportGetComponentWizardsList, request);
    }


}
