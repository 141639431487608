
/* Component  */
import React from 'react';
import { Navigate } from 'react-router-dom';
import Button from '../common/Button';

/* Services  */
import DataImportService from '../../services/domainServices/dataImportService';

/* Library  */
import DataImportDetails from './DataImportDetails';
import { ButtonTypes } from '../../utils/Constants';
import BaseComponent from '../../shared/baseComponent';

export default class DataImport extends BaseComponent {
  /* Constructor  and Component method */
  constructor(props) {
    super(props)
    this.dataImportService = new DataImportService();
    this.state = {
      importDetails: {},
      redirect: null,
      setIsLoad: false
    }
    this.dataImportService.onUpdateState = this.updateState.bind(this);
  }


  // Other function 
  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    let ImportId = params.get("")
    this.dataImportService.onGetDetails(ImportId);
  }


  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="flex overflow-hidden">
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <main>
              <div className="pr-2 sm:pr-3 lg:pr-4 py-4 w-full max-w-9xl mx-auto">
                <div className='flex items-center justify-between mb-4'>
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Data Import Detail</h1>
                  </div>
                  <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.dataImportService.onRedirectoList()} />
                </div>
                <DataImportDetails
                  importDetails={this.state.importDetails}
                  isLoading={this.state.setIsLoad} />
              </div>
            </main>
          </div>
        </div>
      </>
    )
  }
}