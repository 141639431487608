// Component 
import './css/style.scss';
import LayoutNavigation from './navigation/LayoutNavigation';
import ComponentNavigation from '../src/navigation/ComponentNavigation';

// Library 
import { getAuthProps, setAuthProps } from './utils/AuthenticationLibrary';

const App = () => {

  const authDetails = getAuthProps();


  return (
    <LayoutNavigation componentRoutes={ComponentNavigation} authDetails={authDetails} />
  );  
}

export default App;
