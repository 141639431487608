
/* Component  */
import React from 'react';
import Table from '../../components/tables/Table';

/* Library  */
import { Bars } from 'react-loader-spinner';
import { Navigate } from "react-router-dom";
import { encryptAES } from "../../utils/Encryption";
import BaseComponent from '../../shared/baseComponent';
import FormFieldGroup from '../../components/formGroupFields/FormFieldGroup';

/* Services  */
import ProductDomainService from '../../services/domainServices/productDomainService';

export class ProductList extends BaseComponent {

  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.productService = new ProductDomainService();
    this.state = {
      dataList: [],
      setIsLoad: false,
      searchingText: "",
      pagination: this.productService.pagination,
      searchList: this.productService.dataItem,
    }
    this.productService.onUpdateState = this.updateState.bind(this);
    this.productService.onActionCall = this.onActionCall.bind(this);
  }

  //** Constructor  and Component method */
  componentDidMount() {
    this.productService.onInitService()
    this.productService.onGetProductsList(this.state.pagination);
    document.title = 'Product List';
  }

  /* Event function */
  handleChangeEvent = (value) => {
    this.setState({ searchingText: value })
  }

  handleSearching = (searchQueryy) => {
    let searchQuery = searchQueryy.trim();
    let request = {
      pageNo: 1,
      pageSize: 50,
      orderByColumn: "SearchDate",
      orderFlag: 1,
      searchText: searchQuery,
    }
    if (searchQuery !== "") {
      this.state.pagination.pageNo = 1;
    }
    this.productService.onGetProductsList(request);
  }

  // *** Other function **//
  onActionCall = (type, index) => {

    switch (type) {
      case "View":
        let editProductList = [...this.state.dataList];
        window.open(`/NewProduct/productId?=${encryptAES(editProductList[index].productId)}`, '_blank');

    }
  }



  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Product History</h1>

                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3">
                  <div className="relative">
                    {this.productService.formFields.map((Item, index) => {
                      return (
                        <FormFieldGroup className='ms-auto relative search-bar-input'
                          column={Item}
                          value={this.state.searchingText[Item.fieldName]}
                          onChange={(event) => this.handleChangeEvent(event.target.value)}
                          onKeyPress={(e) => e.key === "Enter" && this.handleSearching(this.state.searchingText)}
                          onBlur={() => {
                            this.productService.validateField(Item.fieldName, this.state.searchingText, this.state.validationRules, this.state.validState)
                          }} />
                      );
                    })}
                  </div>
                  <button className="btn commanColorbtn" onClick={(e) => { e.stopPropagation(); this.handleSearching(this.state.searchingText) }}>
                    <span className="xs:block">Search</span>
                  </button>
                </div>
              </div>
              <div className='main-product-list'>
              {
                (this.state.setIsLoad) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad} />
                  :
                  <Table
                    columns={this.productService.gridColmns}
                    rows={this.state.dataList}
                    sortingColumns={["aurumCatalogId", "casNo", "productName"]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.TotalCount}
                    totalCount={this.state.TotalCount}
                    setPagination={this.productService.onPageChange.bind(this)}
                    customScope={[
                      {
                        column: 'Actions',
                        renderTableData: this.productService.gridActionViewCol.bind(this)
                      },
                    ]} />
              }
            </div>
            </div>

          </main>
        </div>
      </div >
    )
  }
}

export default ProductList
