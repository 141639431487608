import BaseDomainService from "./baseDomainServices";
import moment from 'moment';
import { DateFromat } from "../../utils/DateFromat";


const contactUsList = {
    searchText: ""
}


export default class contactUsServices extends BaseDomainService {

    gridColmns = [{ name: 'fullName', title: 'User Full Name' },
    { name: 'emailAddress', title: 'Email Address' },
    { name: 'createdDate', title: 'Date Time' },
    { name: 'contactUsId', title: 'View Detail' },
    ];

    getList = this.apiService.contactUsService.admin_GetContactUsList;
    getById = this.apiService.contactUsService.admin_GetContactUsById;

    // Define the state variables
    dataItem;
    formFields = [
        {
            inputType: "text",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]

    contactusForm = [
        {
            inputType: "label",
            tittle: "Name :",
            fieldName: "fullName",
            className: '',
        },
        {
            inputType: "label",
            tittle: "Email Address :",
            fieldName: "emailAddress",
            className: 'col-md-5',
        },
        {
            inputType: "label",
            tittle: "Phone No :",
            fieldName: "phone",
            className: 'col-md-3',
        },
        {
            inputType: "label",
            tittle: " Company Name :",
            fieldName: "company",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: " Product Name :",
            fieldName: "productName",
            className: 'mb-4',
        },
        {
            inputType: "label",
            tittle: "Message :",
            fieldName: "message",
            className: 'mb-4',
        },
    ]
    constructor() {
        super();
        this.onPaginationUpdate = this.onGetContactUs.bind(this);
        this.actionKey = "contactUsId";
        this.dataItem = { ...contactUsList };
    }


    redirectToListPage = () => {
        var contactUrl = localStorage.getItem('ContactUrl');
        if (contactUrl != null) {
            this.onRedirect("/")
            localStorage.clear();
        }
        else {
            this.onRedirect("/ContactUsList")
        }
    }
    onGetContactUs = (requst) => {
        this.apiService.onAPICall("getContactUs", this.getList, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetById = (requst) => {
        this.apiService.onAPICall("getById", this.getById, requst, this.onAPIResponse, this.onUpdateState);
    }

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getContactUs":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.productName = i.productName ? i.productName : "-";
                        i.createdDate = i.createdDate ? DateFromat(i.createdDate) : '-'        

                    })
                    this.onUpdateState({
                        dataList: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    });
                }
            case "getById":
                let contactDet = ({ ...responseData });
                this.dataItem = { ...contactDet };
                this.onUpdateState({
                    getContactDetails: {
                        fullName: contactDet.fullName,
                        emailAddress: contactDet.emailAddress,
                        phone: contactDet.phone,
                        company: contactDet.company,
                        productName: contactDet.productName,
                        message: contactDet.message
                    },
                    "setIsLoad": false
                });

                break;

        }
    }
}

