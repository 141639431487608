/* Component  */
import React from 'react';
import Table from '../../components/tables/Table';
import BaseComponent from "../../shared/baseComponent";
import Date_Range_Pickers from '../../components/DateRanges';
import moment from 'moment';
/* Library  */
import { Navigate } from 'react-router';
import { Bars } from 'react-loader-spinner';
import { encryptAES } from "../../utils/Encryption";
import FormFieldGroup from "../../components/formGroupFields/FormFieldGroup";

/* Services  */
import ViewProductService from '../../services/domainServices/viewProductService';
import { ErrorMessage } from '../../utils/Messages';

export default class ViewProducts extends BaseComponent {
    /* Constructor and Component method */
    constructor(props) {
        super(props)
        this.viewProductService = new ViewProductService();
        let newPagination = { ...this.viewProductService.pagination }
        newPagination.pageSize = 200
        this.state = {
            dataList: [],
            setIsLoad: false,
            redirect: null,
            startDate: '',
            searchList: this.viewProductService.dataItem,
            pagination: {
                ...newPagination,
                toDate: "",
                fromDate: ""
            },
            changeDateValue: {
                startDate: "",
                endDate: ""
            }
        }
        this.viewProductService.onUpdateState = this.updateState.bind(this)
        this.viewProductService.onActionCall = this.onActionCall.bind(this);

    }

    componentDidMount() {
        this.viewProductService.onGetSearchByUser(this.state.pagination);
        document.title = 'Search History List';
    }

    handleLanguage = (changeValue) => {

        this.setState({ changeDateValue: changeValue });
        let request = {
            ...this.state.pagination,
            fromDate: changeValue.startDate,
            toDate: changeValue.endDate
        }
        this.setState({ pagination: request });
        this.viewProductService.onGetSearchByUser(request);
    }
    handleChange = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        if (name === "searchText") {
            detail[name] = value.trim();
            let data = { ...this.state.pagination };
            data.pageNo = 1;
            this.viewProductService.onGetSearchByUser(data);
            this.setState({ pagination: { ...data } });
        }
        this.setState({ searchList: { ...detail } });
    }

    handleChangeEvent = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ searchList: { ...detail } });
    }

    // *** Other function **//
    onActionCall = (type, data) => {
        let det = this.state.dataList;
        switch (type) {
            case "productIds":
                let value = det.find(x => x.catalogId === data);
                window.open(`/ProductDetail/productId?=${encryptAES(value.productId)}`, '_blank');
                break;
            case "View":
                window.open(`${(data)}`, '_blank');
                break;
            case "userIPAddress":
                let ipAddressDetails = det.find(x => x.userIPAddress === data);
                if (ipAddressDetails && ipAddressDetails.ipAddressesId !== null) {
                    window.open(`/IpAddressDetails/ipAddressesId?=${encryptAES(ipAddressDetails.ipAddressesId)}`, '_blank');
                } else {
                    this.viewProductService.apiService.swalServices.Alert(ErrorMessage.ipAddressDetailNotExists);
                }
                break;
        }
    };


    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0 pt-3">
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold"> Viewed Product</h1>
                                </div>
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                                    <div className="flex items-center dashboard-date-range">
                                        <Date_Range_Pickers onSelectDate={this.handleLanguage} />
                                    </div>
                                    {this.viewProductService.formFields.map((Item, index) => {
                                        return (
                                            <>
                                                {Item.inputType === "text" ?
                                                    <FormFieldGroup className='ms-auto relative search-bar-input'
                                                        column={Item}
                                                        value={
                                                            this.state.searchList[Item.fieldName]
                                                        }
                                                        onChange={(event) =>
                                                            this.handleChangeEvent(Item.fieldName, event.target.value)
                                                        }
                                                        onKeyPress={(e) =>
                                                            e.key === "Enter" &&
                                                            this.handleChange(Item.fieldName, e.target.value)
                                                        }
                                                        key={index} /> : null}</>)
                                    })}
                                    <button className="btnn commanColorbtn text-dark" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                                        <span className="xs:block">Search</span>
                                    </button>
                                </div>
                            </div>
                            <div className='main-view-product-list '>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <div className=''>
                                            <Table
                                                columns={this.viewProductService.gridColmnsUserSearch}
                                                rows={this.state.dataList}
                                                sortingColumns={[]}
                                                pagination={this.state.pagination}
                                                totalCount={this.state.TotalCount}
                                                totalResultes={this.state.TotalCount}
                                                setPagination={this.viewProductService.onPageChange.bind(this)}
                                                customScope={[
                                                    {
                                                        column: 'catalogId',
                                                        renderTableData: this.viewProductService.UserProductIdactions.bind(this)
                                                    },
                                                    {
                                                        column: "webURL",
                                                        renderTableData: this.viewProductService.tooltip.bind(this),

                                                    },
                                                    {
                                                        column: 'userIPAddress',
                                                        renderTableData: this.viewProductService.ipAddressAction.bind(this)
                                                    },
                                                    {
                                                        column: "isCrawler",
                                                        renderTableData: this.viewProductService.gridCheckBoxCol.bind(this),
                                                    },
                                                ]}
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }

}