/* Component  */

import { axiosPost } from '../AxiosRequests';
import { Admin_GetBlockIpAddressList, Admin_AddBlockIpAddress, Admin_UpdateIsBlacklist } from '../ApiEndPoints';


export default class ManageBlockIPServices {
    // API function

    async admin_GetBlockIpAddressList(request) {
        return axiosPost(Admin_GetBlockIpAddressList, request);
    }

    async admin_AddBlockIpAddress(request) {
        return axiosPost(Admin_AddBlockIpAddress, request);
    }

    async admin_UpdateIsBlacklist(request) {
        return axiosPost(Admin_UpdateIsBlacklist, request);
    }

}