/* Component  */
import React, { Component } from 'react';
import Table from '../../components/tables/Table';
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router';

/* Services  */
import CompanyServicess from "../../services/domainServices/CompanyServices"
import baseComponent from "../../shared/baseComponent"
import FormFieldGroup from '../../components/formGroupFields/FormFieldGroup';

export default class CompaniesList extends baseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.CompanyServices = new CompanyServicess()
    let newPagination = { ...this.CompanyServices.pagination }
    newPagination.pageSize = 200
    this.state = {
      pagination: newPagination,
      searchList: this.CompanyServices.dataItem,
      getCompanies: [],
      setIsLoad: false,
    }
    this.CompanyServices.onUpdateState = this.updateState.bind(this)
  }

  componentDidMount() {
    this.CompanyServices.OngetCompanylist(this.state.pagination)
  }

  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.setState({ isLoading: true });
      this.CompanyServices.OngetCompanylist(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ searchList: { ...detail } });
  }

  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Companies</h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3">
                  <div className="relative" style={{ display: "contents" }}>
                    <div>
                      {this.CompanyServices.SearchformFields.map((Item, index) => {
                        return (
                          <>
                          {Item.inputType === "text" ?
                            <FormFieldGroup className='ms-auto'
                              column={Item}
                              value={
                                this.state.searchList[Item.fieldName]
                              }
                              onChange={(event) =>
                                this.handleChangeEvent(Item.fieldName, event.target.value)
                              }
                              onKeyPress={(e) =>
                                e.key === "Enter" &&
                                this.handleChange(Item.fieldName, e.target.value)
                              }
                              key={index} />
                            :
                            null}
                          </>)
                      })}
                    </div>
                    <button className="btnn commanColorbtn text-dark"
                      onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}
                    >
                      <span className="xs:block">Search</span>

                    </button>
                  </div>
                </div>
              </div>
              <div className='main-companies-list'>
              {
                (this.state.setIsLoad) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad}
                  />
                  :
                  <Table
                    columns={this.CompanyServices.companylistgrid}
                    rows={this.state.getCompanies}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalCount={this.state.TotalCount}
                    totalResultes={this.state.TotalCount}
                    setPagination={this.CompanyServices.onPageChange.bind(this)}
                  />
              }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }

}