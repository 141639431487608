import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "tailwindcss/tailwind.css";
import { Bars } from 'react-loader-spinner';
import moment from 'moment/moment';

import Table from "../../components/Informatics/Table";
import RenderCharts from "../../components/Informatics/RenderCharts";
import CountCard from "../../components/Informatics/CountCard";
import componentWizardServices from "../../services/domainServices/componentWizardServices"
import BaseComponent from '../../shared/baseComponent'

export default class Daily extends BaseComponent {

    constructor(props) {
        super(props);
        this.componentWizardServices = new componentWizardServices();
        this.state = {
            ...this.state,
            setIsLoad: false,
            dailydataList: [],
            pagination: this.componentWizardServices.pagination,
            selectedDate: new Date(),
            startDate: moment(new Date()).startOf('daily').toDate(),
            endDate: moment(new Date()).endOf('daily').toDate(),
            configurationId: 1,
        };
        this.componentWizardServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.onApiCall();
    }

    onApiCall = () => {
        let req = [1, moment(this.state.selectedDate).format("YYYY-MM-DD")];
        this.componentWizardServices.onGetComponentWizardsList(req);
    }

    handleDateChange = (date) => {
        const startDate = moment(date).startOf('daily').toDate();
        const endDate = moment(date).endOf('daily').toDate();

        this.setState({
            selectedDate: date,
            startDate,
            endDate,
        }, () => {
            this.onApiCall();
        });
    };

    capitalizeFirstLetter(str) {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    }

    render() {
        return (
            <>
                <div className="grid justify-end">
                    <div className="flex Charts-date items-center">
                        {/* <span>Start Date: <b>{moment(this.state.startDate).format("MM-DD-YYYY")}</b></span>
            <span className="ml-2">End Date: <b>{moment(this.state.endDate).format("MM-DD-YYYY")}</b></span> */}
                        <span className="text-[15px]">
                            Date:{" "}
                        </span>
                        <DatePicker
                            selected={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            className="custom-datepicker ml-2"
                        />
                    </div>
                </div>
                <div className="widgets-section sticky-container">
                    <div className="pt-3 grid grid-cols-12 gap-6 p-3">
                        {this.state.setIsLoad ? (
                            <div className="col-span-12 mt-5">
                                <Bars
                                    className="row"
                                    wrapperStyle={{ marginTop: "0px" }}
                                    color="#3c4b64"
                                    height={55}
                                    width="100%"
                                    visible={this.state.setIsLoad}
                                />
                            </div>
                        ) : (
                            <>
                                {this.state.dailydataList != null &&
                                    this.state.dailydataList.length > 0 ? (
                                    <>
                                        {this.state.dailydataList.map((list, index) => {
                                            const capitalizedTitle = this.capitalizeFirstLetter(list.title);
                                            return list.componentWizardType === "Counts" ? (
                                                <CountCard data={list.dataList} size={list.size} />
                                            ) : list.componentWizardType === "Table" ? (
                                                <Table
                                                    title={capitalizedTitle}
                                                    key={index}
                                                    data={list.dataList[0]}
                                                    size={list.size}
                                                    selectedDate={this.state.selectedDate}
                                                    configurationId={this.state.configurationId}
                                                    componentWizardId={this.state.dailydataList[index].componentWizardId}
                                                // height={list.height}
                                                />
                                            ) : (
                                                <>
                                                    <div className={`col-span-${list.size === 1 ? 4 : list.size === 2 ? 6 : list.size === 3 ? 12 : list.size}`} key={index}>
                                                        <div className="center-div Dashboard-card-count card-bg-light ">
                                                            <div className="card-title">
                                                                {capitalizedTitle}
                                                            </div>
                                                            <RenderCharts chartType={list.chartType}
                                                                data={list.dataList}
                                                                title={capitalizedTitle}
                                                                // height={list.height}
                                                                size={list.size}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <div className="col-span-12 text-center mt-5">
                                        <h1 style={{ fontSize: "28px" }}>No Record Found.</h1>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }
}
