// Library
import validator from 'validator';

// Validation functions 
export const required = (value) => {
  if (value === undefined || value === null) {
    return false;
  }
  if (!value.toString().trim().length) {
    return false;
  }
  if (typeof (value) !== 'string') {
    if (value === 0) {
      return false;
    }
  }
  return true;
};

export const email = (value) => {
  if (value === undefined || value === null || value.toString() === "") {
    return true;
  }
  if (!validator.isEmail(value)) {
    return false;
  }
  return true;
};

export const number = (value) => {
  if (value === 0) {
    return false;
  }
  return true;
};

export const maxLength = (value, maxLen) => {
  if ((value.toString().trim().length <= maxLen)) {
    return true;
  }
  return false;
};

export const minLength = (value, minLen) => {
  if ((value.toString().trim().length >= minLen)) {
    return true;
  }
  return false;
};

export const compare = (value, compareValue) => {
  if (!(value === compareValue)) {
    return false;
  }
  return true;
};

export const distinct = (value, compareValue) => {
  if (value !== "") {
    if (value === compareValue) {
      return false;
    }
  }
  return true;
};

export const maxProspects = (value, maxProspectsValue) => {
  if ((value <= maxProspectsValue)) {
    return true;
  }
  return false;
};

export const minEndDate = (date, minEndDateValue) => {
  if ((date >= minEndDateValue)) {
    return true;
  }
  return false;
};
export const maxSum = (sum, maxSumValue) => {
  if ((sum <= maxSumValue)) {
    return true;
  }
  return false;
};

export const password = (value, props, components) => {
  if (value !== components['confirm'][0].value) {
    return <span className="error">Passwords are not equal.</span>
  }
};

export const uniqueIdentifier = (value) => {
  if (value !== undefined && value !== null && value.toString().trim().length) {
    const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
};

export const isvalidPassword = (value) => {
  if (value !== undefined && value !== null && value.toString().trim().length) {
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

export const regex = (value) => {
  if (value !== undefined && value !== null && value.toString().trim().length) {
    // Check if the query starts with select
    if (!value.trim().toLowerCase().startsWith('select')) {
      return false; // Query must begin with select
    }

    // Check if the query contains disallowed keywords
    const disallowedKeywords = ['HashedPassword', 'PasswordSalt', 'IsUsedActivationLink', 'Password', 'Query'];
    for (const keyword of disallowedKeywords) {
      if (new RegExp(`\\b${keyword}\\b`, 'i').test(value)) {
        return false; // Disallowed keyword found
      }
    }

    // Check if both start date and end date are present in the query
    const startDateRegex = /\b@StartDate\b/i;
    const endDateRegex = /\b@EndDate\b/i;
    
    if (!startDateRegex.test(value) || !endDateRegex.test(value)) {
      return false; // Compulsory start date and end date are required
    }

    // If all checks pass, return true
    return true;
  }
  return false; // Empty or undefined value
};

export const sqlvalidation = (value) => {
  if (value !== undefined && value !== null && value.toString().trim().length) {
    // Check if * exists in the query
    if (value.includes('*')) {
      return false; // * is not allowed
    }

    // Check if the query starts with select
    if (!value.trim().toLowerCase().startsWith('select')) {
      return false; // Query must begin with select
    }

    // Check if the query contains disallowed keywords
    const disallowedKeywords = ['HashedPassword', 'PasswordSalt', 'IsUsedActivationLink', 'Password' , 'Query'];
    for (const keyword of disallowedKeywords) {
      if (new RegExp(`\\b${keyword}\\b`, 'i').test(value)) {
        return false; // Disallowed keyword found
      }
    }

    // If all checks pass, return true
    return true;
  }
  return false; // Empty or undefined value
};