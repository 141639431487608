import React from "react";
import ModalBasic from "../ModalBasic";
import BaseComponent from "../../shared/baseComponent";
import databaseHealthServices from "../../services/domainServices/databaseHealthServices";
import Button from "../common/Button";
import { ButtonTypes } from "../../utils/Constants";
import FormFieldGroup from "../formGroupFields/FormFieldGroup";
import Captcha from "../reactcaptcha/CaptchaTest";
import ReCAPTCHA from "react-google-recaptcha";

class AddEditQueryModal extends BaseComponent {
  constructor(props) {
    super(props)
    this.databaseHealthServices = props.databaseHealthServices;
    this.databaseHealthServices = new databaseHealthServices();
    this.databaseHealthServices.onUpdateState = this.updateState.bind(this);
  }
  render() {
    return (
      <>
        <ModalBasic modalOpen={this.props.openStateModal}  key={this.props.shouldRerenderFormCreator} setModalOpen={() => { this.props.onCloseModal() }}
          title={this.props.dbhealth.databaseHealthId ? "Database Health" : "Database Health"}
        // title="Add"
        >
          <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
            <div className="border-slate-200">
              <div className="space-y-4 mt-4 pb-5">
                <div className="grid gap-5 md:grid-cols-12">
                  {this.databaseHealthServices.formFields.map((Item, index) => {
                    return (
                      <>
                        <FormFieldGroup
                          column={Item}
                          value={this.props.dbhealth[Item.fieldName]}
                          error={this.props.validState.error[Item.fieldName]}
                          options={Item.options}
                          onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                          onChange={Item.inputType === 'checkbox' ? (event) => this.props.handleChange(Item.fieldName, event.target.checked)
                            : Item.inputType === "file" ? (event) => this.props.handleFileChange(event) :
                              Item.inputType === "select" || Item.inputType === "text" ? (event) => this.props.handleChange(Item.fieldName, event.target.value)
                                : (event) => this.props.handleChange(Item.fieldName, event.target.value)}
                          checked={this.props.dbhealth[Item.fieldName]}
                          onBlur={() => {
                            this.props.validateField(Item.fieldName, this.props.dbhealth, this.props.validationRules, this.props.validState)
                          }}
                        />
                      </>
                    );
                  })}
                </div>
                <div className="form-group mt-3">
                  <ReCAPTCHA
                    sitekey={this.props.testSiteKey}
                    onChange={this.props.handleCaptchaChange}
                  />
                </div>
                <div className="flex flex-wrap justify-end mt-4 mb-4 gap-3">
                  {this.props.dbhealth.databaseHealthId ?
                    <>
                      <Button
                        type={ButtonTypes.Primary} title="Update"
                        buttonClick={(e) => this.props.updateDbhealth(e)}
                      />
                      <Button type={ButtonTypes.Secondary} className="ml-3" title="Cancel" buttonClick={() => this.props.onCloseModal()} />
                    </>
                    :
                    <> <Button
                      type={ButtonTypes.Primary} title="Add"
                      buttonClick={(e) => this.props.addDbhealth(e)}
                    />
                      <Button type={ButtonTypes.Secondary} className="ml-3" title="Cancel" buttonClick={() => this.props.onCloseModal()} />
                    </>
                  }

                </div>
              </div>
            </div>
          </div>
        </ModalBasic>
      </>
    );
  }
}

export default AddEditQueryModal;