import React, { useState, useEffect, useMemo } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet, useLocation } from "react-router";
import { getAuthProps } from "./../utils/AuthenticationLibrary";
import AuthorizationWrap from "./hoc/AuthorizationWrap";

const Layout = ({ componentRoutes }) => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(open => !open);

  const basePath = useMemo(() => location.pathname.split('/')[1] || '', [location.pathname]);
  const breadCrumbs = useMemo(() => componentRoutes.filter(x => x.id === basePath), [basePath, componentRoutes]);

  useEffect(() => {
    const authDetails = getAuthProps();
    if (location.pathname === "/UnAuthorized" || !authDetails) {
      window.location.href = !authDetails ? "/login" : "/UnAuthorized";
    }
  }, [location.pathname]);

  return (
    <AuthorizationWrap authorizeUserType={breadCrumbs[0]?.allowrole || 'defaultRole'}>
      <div className="flex h-screen overflow-hidden" style={{ gap: '20px' }}>
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} componentRoutes={componentRoutes} />
          <Outlet />
        </div>
      </div>
    </AuthorizationWrap>
  );
};

export default Layout;
