import APISererviceManager from "../../libs/apiSererviceManager";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
export default class BaseDomainService {
    pagination = {
        pageNo: 1,
        pageSize: 25,
        orderByColumn: "CreatedDate",
        orderFlag: 1,
        searchText: "",
    };

    apiService;
    serviceManager;
    authUser;
    onUpdateState;
    onActionCall;
    onPaginationUpdate;
    actionKey;
    ipActionKey;
    productActionKey;
    actionOrderId;
    actionContactUsId;

    constructor() {
        this.apiService = new APISererviceManager();
        this.authUser = getAuthProps();
    }


    // Validation
    validateField = (key, data, rules, currentState) => {
        const isValidData = validate(key, data, rules, currentState);
        this.onUpdateState({ validState: isValidData });
    }
    isValidSubmit = (data, rules, currentState) => {
        const isValidData = isValidForm(data, rules, currentState);
        this.onUpdateState({ validState: isValidData });
        return isValidData.isValid;
    }

    //Redirect to other page
    onRedirect = (redirectUrl) => {
        this.onUpdateState({ redirect: redirectUrl });
    }
    // Pagination Metods
    onPageChange = (newPagination) => {
        this.pagination = newPagination;
        this.onUpdateState({ "pagination": newPagination }, () => {
            this.onPaginationUpdate(this.pagination);
        });
    }

    gridActionsCol = (element, index, value, isDeleteRequired = false) => {
        return (element !== this.actionKey) ? null
            : <>
                <td className="">
                    <i className="fa fa-pencil cursor-pointer"
                        onClick={() => this.onActionCall("Edit", value)}></i>

                    {isDeleteRequired === true ?
                        <><i class="fa fa-trash ms-3" aria-hidden="true"
                            onClick={() => this.onActionCall("Delete", value)}></i></>
                        : null}
                </td>

            </>
    }
    gridActionViewCols = (element, index, value) => {
        return (element !== this.actionKey) ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
                <i className="fas fa-eye  cursor-pointer ... d-flex justify-content-center" onClick={() => this.onActionCall("View", value)}></i>
            </td>
    }
    gridActionErrorCols = (element, index, value) => {
        return (element !== "errorLogId") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
                <i className="fas fa-eye  cursor-pointer ... d-flex justify-content-center" onClick={() => this.onActionCall("View", value)}></i>
            </td>
    }
    gridActionlogIdCols = (element, index, value) => {
        return (element !== "logId") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
                <i className="fas fa-eye  cursor-pointer ... d-flex justify-content-center" onClick={() => this.onActionCall("View", value)}></i>
            </td>
    }

    gridActionViewColpayment = (element, index, value) => {
        return (element !== "paymentType") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer">
                {
                    value === "Net30" ?
                        <>Net 30</>
                        : <i className="fas fa-money-check  cursor-pointer ... d-flex justify-content-center" ></i>
                }
            </td>
    }
    gridActionStatusIdCol = (element, value) => {
        return (element !== "errorStatusId") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer archive_color">
                {
                    value === 2 ? <p>Archive</p> : <p>Open</p>
                }
            </td>
    }
    gridActionViewCol = (element, index, value) => {
        return (element !== this.actionKey) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
                {this.actionKey === 'Actions' ?
                    <i className="fas fa-eye cursor-pointer" onClick={() => this.onActionCall("View", index)}></i>
                    : <i className="fas fa-eye cursor-pointer" onClick={() => this.onActionCall("View", value)}></i>
                }

            </td>
    }
    gridActionOrderId = (element, index, value) => {
        return (element !== this.actionOrderId) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
                <i className="fas fa-eye cursor-pointer" onClick={() => this.onActionCall("edit", value)}></i>
            </td>
    }
    gridSctionContactUsId = (element, index, value) => {
        return (element !== this.actionContactUsId) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
                <i className="fas fa-eye cursor-pointer" onClick={() => this.onActionCall("editcontactus", value)}></i>
            </td>
    }

    gridaRFQctions = (element, index, value) => {
        return (element !== "rfqId") ? null
            : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
                <i className="fas fa-eye themeColor  cursor-pointer ..." onClick={() => this.onActionCall("RFQEdit", value)}></i>
            </td>
    }
    gridOrderactions = (element, index, value) => {
        return (element !== "orderId") ? null
            : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
                <i className="fas fa-eye  themeColor cursor-pointer ..." onClick={() => this.onActionCall("Edit", value)}></i>
            </td>
    }
    UserListactions = (element, index, value, userListState = []) => {
        let details = userListState;
        let x = details.find(x => x.userId === value);
        return (element !== "userId") ? null
            :
            <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer username-text">
                <i className="fas fa-eye  cursor-pointer ... d-flex justify-content-center username-text mr-3" onClick={() => this.onActionCall("UserList", index, value)}></i>
                {x.isActive === true ?
                    <i className="fas fa-sign-in-alt cursor-pointer" style={{ color: 'rgb(32 168 60 / var(--tw-bg-opacity))' }} data-toggle="tooltip" data-placement="top"
                        title={"Login"} onClick={() => this.onActionCall("userLogin", index, value)}></i>
                    : <i class="fas fa-times text-red-500" data-toggle="tooltip" data-placement="top"
                        title={"User Not Active"}></i>
                }
            </td>
    }
    UserStockactions = (element, index, value) => {
        return (element !== "PackId") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer username-text">
                <i className="fas fa-edit  cursor-pointer ... d-flex justify-content-center username-text" onClick={() => this.onActionCall("PackId", value)}></i>
            </td>

        // return (element !== "PackId") ? null
        // : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
        //     <i class="fas fa-solid fa-trash themeColor cursor-pointer mr-3"></i>
        //     <i class="fas fa-edit themeColor cursor-pointer" onClick={(e) => this.openModal(e, index)}></i>
        // </td>
    }
    ViewIpAddress = (element, index, value) => {
        return (element !== "ipAddressesId") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer username-text">
                <i className="fas fa-eye  cursor-pointer ... d-flex justify-content-center username-text" onClick={() => this.onActionCall("SettingServices", value)}></i>
            </td>
    }
    UserEmailactions = (element, index, value) => {
        return (element !== "userName") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text" >
                {
                    value === "userName" ?
                        <a href="" target="_blank"> <i className="username-text" onClick={() => this.onActionCall("userName", value)}>{value}</i></a>
                        : <i className="username-text" onClick={() => this.onActionCall("userName", value)}>{value}</i>
                }
            </td>
    }
    UserProductIdactions = (element, index, value) => {
        return (element !== "catalogId") ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text">
                {
                    value === "catalogId" ?
                        <a href="" target="_blank"><i className="username-text" onClick={() => this.onActionCall("productIds", value)}>{value}</i></a>
                        : <i className="username-text" onClick={() => this.onActionCall("productIds", value)}>{value}</i>
                }
            </td>
    }
    gridActionViewColOrderId = (element, index, value) => {
        return (element !== this.actionKey) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
                {this.actionKey === 'Actions' ?
                    <a href="" target="_blank"><i className="fas fa-eye cursor-pointer" onClick={() => this.onActionCall("View", index)}></i></a>
                    :
                    <>
                        <i className="fas fa-eye cursor-pointer" onClick={() => this.onActionCall("View", value)}></i>
                        <i class="fas fa-trash cursor-pointer m-2" aria-hidden="true"
                            onClick={() => this.onActionCall("Delete", value)}></i>
                    </>
                }
            </td>
    }
    tooltip = (element, index, value) => {
        return (element !== this.actionKey) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  themeColor pointer cursor-pointer " >
                <div class="tooltip">
                    <a href="">  <i className="fas fa-info-circle cursor-pointer  " onClick={() => this.onActionCall("View", value)}></i>
                        <span class="tooltiptext">{value} </span></a>
                </div>
            </td>
    }
    activityTooltip = (element, index, value) => {
        return (element !== this.actionKey) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  themeColor pointer cursor-pointer " >
                <div class="tooltipp">
                    <a href="">  <i className="fas fa-info-circle cursor-pointer  " onClick={() => this.onActionCall("View", value)}></i>
                        <span class="tooltipptext">{value} </span></a>
                </div>
            </td>
    }
    UrlToltip = (element, index, value) => {
        return (element !== this.actionKey) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  themeColor pointer cursor-pointer " >
                <div class="tooltipp">
                    <i className="fas fa-info-circle cursor-pointer " onClick={() => this.onActionCall("UrlView", value)}></i>
                    <span class="tooltipptext">{value} </span>
                </div>
            </td>
    }
    gridActionProductView = (element, index, value) => {
        return (element !== this.productActionKey) ? null
            :
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer">
                <i class="fas fa-edit themeColor cursor-pointer" onClick={(e) => this.onActionCall("View", index, e)}></i>
            </td>
    }
    gridAction = (element, index, value, isDelete = false) => {
        return (element !== this.actionKey) ? null
            : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap themeColor pointer d-flex">
                <i class="fas fa-edit themeColor cursor-pointer mr-3" onClick={(e) => this.onActionCall("Edit", e, index, value)}></i>
                {isDelete === true ?
                    <i class="fas fa-solid fa-trash themeColor cursor-pointer" onClick={(e) => this.onActionCall("Delete", e, index, value)}></i>
                    : null}
            </td>
    }


    gridActionsCol = (element, index, value, isActionRequired = false, isViewRequired = false, isDeleteRequired = false) => {
        return (element !== this.actionKey) ? null
            : <>
                <td className="">
                    {isViewRequired === true ?
                        <i className="fas fa-eye  cursor-pointer themeColor mr-3"
                            onClick={() => this.onActionCall("View", index, value)}></i>
                        : null}
                    {isDeleteRequired === true ?
                        <i class="fas fa-solid fa-trash themeColor cursor-pointer"
                            onClick={() => this.onActionCall("Delete", index, value)}></i>
                        : null}
                </td>
            </>
    }
    gridCheckBoxCol = (element, index, value) => {
        return (element !== this.actionActive) ? null
            : <td className="text-left ml">
                <div className="form-check">
                    <input disabled className="form-check-input ml-0" style={{ position: 'relative' }} type="checkbox" id="inlineFormCheck" checked={value} />
                </div>
            </td>
    }

    ipAddressAction = (element, index, value) => {
        return (element !== this.ipActionKey) ? null
            : <td class="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap pointer  username-text" >
                <button onClick={() => this.onActionCall(this.ipActionKey, value)}>
                    <i className="username-text" >{value}</i>
                </button>

            </td>
    }

    ipAddressUserActivityAction = (element, index, value) => {
        // Check if the index matches the ipActionKey
        if (index !== this.ipActionKey) return null;

        // Extracting values from the log object at the specified index
        const { crawlerName, isp, isCrawler, ipAddress } = element[value];

        return (
            <td className="text-[#1e293b] px-2 first:pl-5 last:pr-5 py-3 themeColor whitespace-nowrap themeColor pointer cursor-pointer username-text">
                <button className="tooltipp" onClick={() => this.onActionCall(this.ipActionKey, ipAddress)}>
                    <i className="username-text">{ipAddress}</i>
                    {/* Tooltip */}
                    <span className="tooltipptextUserActivity">
                        {/* Display tooltip content */}
                        <div className="flex  margin-bottom-0">
                            <p className="flex min-w-[111px] justify-start text-[12px] ">Crawler Name</p> <span className=""> : &nbsp;{crawlerName}</span>
                        </div>
                        <div className="flex  margin-bottom-0">
                            <p className="flex min-w-[111px] justify-start text-[12px] ">ISP</p> <span className=""> : &nbsp;{isp}</span>
                        </div>
                        <div className="flex  margin-bottom-0">
                            <p className="flex min-w-[111px] justify-start text-[12px] ">Is Crawler</p> <span className=""> : &nbsp;{isCrawler}</span>
                        </div>

                    </span>
                </button>
            </td>
        );
    }

}
