
/* Component  */
import React, { useState } from 'react';
import Form from '../../components/form/Form';

/* Library  */
import { ImageURLGenerator, ProjectType } from '../../utils/ImageURLGenerator';

/* Images  */
import NoStructure from '../../images/NoStructure.png';

const ProductDetails = (props) => {
  /* Event function */
  const [openTab, setOpenTab] = useState(1);

  const addDefaultSrc = (event) => {
    event.target.src = NoStructure
  }

  return (
    <>
      <div className="col-span-full xl:col-span-8 bg-white shadow-lg border border-slate-200 mb-3 border-radius-10">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-full xl:col-span-4  rounded-sm  border-slate-200">
            <div className="product-details-img">
              <img width="200" src={ImageURLGenerator(ProjectType.AurumPharmatech, props.productsDetails.catalogId)} onError={addDefaultSrc} alt="" align="center" />
            </div>

          </div>
          <div className="col-span-full xl:col-span-8  rounded-sm  border-slate-200">
            <div>
              <Form form={props.form}
                formValues={props.formValues}
                setFormValues={props.setFormValues}
                isBtnLoading={props.isBtnLoading}
                submitForm={props.submitForm}
                cancel={props.cancel} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetails
