/* Component  */
import { axiosGet, axiosPostAuthorize, axiosPost } from '../AxiosRequests';
import { Admin_GetShoppingCartProductsByUser, GetAddressesList, Admin_GetAllShoppingCartList } from '../ApiEndPoints';

export default class ShoppingCartServices {
    // API function

    async admin_GetShoppingCartProductsByUser(request) {
        return axiosGet(Admin_GetShoppingCartProductsByUser, request);
    }
    async admin_GetAllShoppingCartList(request) {
        return axiosPost(Admin_GetAllShoppingCartList, request);
    }
    async getaddresses(request) {
        return axiosPostAuthorize(GetAddressesList, request);
    }

}
