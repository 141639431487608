/* Component  */
import { IMAGESAPI, RenderImageUsingImageType } from "../services/axiosServices/ApiEndPoints";

export const ProjectType = {
    AurumPharmatech:1
}

// Images function 

export const ImageURLGenerator = (type, catalogId) => {
    let url = IMAGESAPI + RenderImageUsingImageType;
    if (type && catalogId) {
        url = url.replace("{0}", type);
        url = url.replace("{1}", catalogId);
        return url;
    }
    return '';
}
