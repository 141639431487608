import BaseDomainService from "./baseDomainServices";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { ErrorMessage, SuccessMessage } from '../../utils/Messages';
const searchHistoryList = {
    searchText: ""
}
const ViewIpDetails = {
    iPAddressesId: 0,
    country: '',
    countryCode: "",
    city: "",
    isp: "",
    regionName: "",
    latitude: "",
    longitude: "",
}

export default class settingService extends BaseDomainService {
    getIpAddressList = this.apiService.settingServices.getIpAddressList;
    getAllIPAddresses = this.apiService.settingServices.getAllIPAddresses;
    getIpAddressById = this.apiService.settingServices.getIpAddressById;
    updateIpAddress = this.apiService.settingServices.updateIpAddress;
    admin_GetIPAddressCounts = this.apiService.settingServices.admin_GetIPAddressCounts;
    getProductsByIpAddress = this.apiService.settingServices.getProductsByIpAddress;

    SettingServicesColmns = [
        { name: "country", title: "Country" },
        { name: "ipAddress", title: "IP Address" },
        { name: "isp", title: "Isp" },
        { name: "ipAddressesId", title: "Action" },
    ]
    // Define the state variables
    dataItem;
    SearchformFields = [
        {
            inputType: "text",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    formFields = [
        {
            inputType: "text",
            tittle: "Ip Address",
            placeholder: "Ip Address",
            fieldName: "ipAddress",
            className: 'col-md-6',
            disabled: true,
        },
        {
            inputType: "text",
            tittle: "Country Name",
            placeholder: "Country Name",
            fieldName: "country",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            }
        },
        {
            inputType: "text",
            tittle: "Country Code",
            placeholder: "Country Code",
            fieldName: "countryCode",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            }
        },
        {
            inputType: "text",
            tittle: "City",
            placeholder: "City",
            fieldName: "city",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            }
        },
        {
            inputType: "text",
            tittle: "ISP",
            placeholder: "ISP",
            fieldName: "isp",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            }
        },
        {
            inputType: "text",
            tittle: "Region Name",
            placeholder: "Region Name",
            fieldName: "regionName",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            }
        },
        {
            inputType: "number",
            tittle: "Latitude",
            placeholder: "Latitude",
            fieldName: "latitude",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onKeyDown: (e) => { /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault() }

        },
        {
            inputType: "number",
            tittle: "Longitude",
            placeholder: "Longitude",
            fieldName: "longitude",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onKeyDown: (e) => { /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault() }

        },
        {
            inputType: "checkbox",
            tittle: "isBlocked",
            placeholder: "isBlocked",
            fieldName: "blockIpAddressId",
            className: 'col-md-6 mt-3',
            checkLabelClass: 'ml-2',
            disabled: true,

        }
    ]

    gridColumn = [
        { name: "userName", title: "Login By User" },
        { name: "", title: "" }

      ]

    constructor() {
        super();
        this.onPaginationUpdate = this.ongetIpAddressList.bind(this);
        this.actionKey = "ipAddressesId";
        this.ipActionKey = "ipAddress";
        this.dataItem = { ...searchHistoryList };
    }

    //** Service Event */
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    //** API Calls
    ongetIpAddressList = (requst) => {
        this.apiService.onAPICall("getIpAddressList", this.getIpAddressList, requst, this.onAPIResponse, this.onUpdateState);
    };
    ongetAllIPAddresses = (requst) => {
        this.apiService.onAPICall("getAllIPAddresses", this.getAllIPAddresses, requst, this.onAPIResponse, this.onUpdateState);
    };
    ongetIpAddressById = (requst) => {
        this.apiService.onAPICall("getIpAddressById", this.getIpAddressById, requst, this.onAPIResponse, this.onUpdateState);
    };
    onupdateIpAddress = (requst) => {
        this.apiService.onAPICall("updateIpAddress", this.updateIpAddress, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetIPAddressCounts = (requst) => {
        this.apiService.onAPICall("admin_GetIPAddressCounts", this.admin_GetIPAddressCounts, requst, this.onAPIResponse, this.onUpdateState);
    };
    ongetProductsSearchByIpAddress = (requst) => {
        this.apiService.onAPICall("getProductsByIpAddress", this.getProductsByIpAddress, requst, this.onAPIResponse, this.onUpdateState);
    };
    onRedirectoList = () => {
        this.onRedirect("/ViewIpAddress");
    }
    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "getIpAddressList":
                this.onUpdateState({
                    getViewIpList: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });
                break;
            case "getAllIPAddresses":
                this.onUpdateState({ setIsLoad: false });
                window.location.reload()
                break;
            case "getIpAddressById":
                if (responseData !== null) {
                    let userDet = ({ ...responseData[0] });
                    this.onUpdateState({
                        ViewIpDetails: {
                            country: userDet.country,
                            countryCode: userDet.countryCode,
                            city: userDet.city,
                            isp: userDet.isp,
                            regionName: userDet.regionName,
                            latitude: userDet.latitude,
                            iPAddressesId: userDet.iPAddressesId,
                            longitude: userDet.longitude,
                            ipAddress: userDet.ipAddress,
                            blockIpAddressId: userDet.blockIpAddressId != null ? true : false


                        }
                    })
                }
                break;
            case "updateIpAddress":
                if (responseData.errorType == "EXISTS" && responseData.hasError == true) {
                    this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "IPAddress"));
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "IPAddress Details"));
                }
                break;
            case "admin_GetIPAddressCounts":
                this.onUpdateState({
                    totalIpCount: responseData.ipAddressCount,
                    setIsLoad: false
                });
                break;
                case "getProductsByIpAddress":
                    if (responseData.length > 0) {
                        let ProductsCount = responseData[0]
                        let newDataList = responseData.map((data) => ({
                            userName: data.userName ? data.userName : "-"
    
                        }));
                        this.onUpdateState({
                            UserList: newDataList,
                            ProductsCount: ProductsCount.productsCounts,
                        })
                    }
                    else {
                        this.onUpdateState({
                            UserList: responseData,
                            ProductsCount: 0,
                        })
                    }
                    this.onUpdateState({
                        setIsLoad: false
                    })
                    break;
        }
    };
}

