/* Component  */
import React, { useState } from 'react';

function SidebarLinkGroup({ children, activecondition }) {
    // Hooks
    const [open, setOpen] = useState(activecondition);
    const handleClick = () => {
        setOpen(!open);
    }

    return (
        <li className={`background-hover-sidebar new-padding-sidebar rounded-sm mb-0.5 last:mb-0 ${activecondition && 'text-white'}`}>
            {children(handleClick, open)}
        </li>
    );
}

export default SidebarLinkGroup;