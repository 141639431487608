/* Component  */
import React from 'react';
import { Navigate } from 'react-router-dom';

/* Library  */
import { Bars } from 'react-loader-spinner';
import Table from '../../components/tables/Table';
import BaseComponent from '../../shared/baseComponent';

/* Services  */
import DataImportService from '../../services/domainServices/dataImportService';

export class DataImportList extends BaseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.dataImportService = new DataImportService();
    this.state = {
      getImport: [],
      redirect: null,
      setIsLoad: false,
      pagination: this.dataImportService.pagination
    }
    this.dataImportService.onUpdateState = this.updateState.bind(this);
    this.dataImportService.onActionCall = this.onActionCall.bind(this);
  }

  //** Constructor  and Component method */
  componentDidMount() {
    this.dataImportService.onGetList(this.state.pagination);
    document.title = 'Data Import List';
  }

  // *** Other function **//
  onActionCall = (type, data) => {
    switch (type) {
      case "View":
        window.open("/DataImportInfo/ImportId?=" + data, '_blank');
    }
  }


  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-4 w-full max-w-9xl mx-auto pb-0">
              <div className="mb-5">
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Data Import List</h1>
              </div>

              <div className='main-data-import-list'>
                {
                  (this.state.setIsLoad) ?
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: '150px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                    :
                    <div>
                      <Table
                        columns={this.dataImportService.gridColmns}
                        rows={this.state.getImport}
                        sortingColumns={["importTitle", "ImportFileName", "ImportTypeId", "status", "startDate"]}
                        pagination={this.state.pagination}
                        totalCount={this.state.TotalCount}
                        totalResultes={this.state.TotalCount}
                        setPagination={this.dataImportService.onPageChange.bind(this)}
                        customScope={[
                          {
                            column: 'importId',
                            renderTableData: this.dataImportService.gridActionViewCol.bind(this)
                          },
                        ]}
                      />
                    </div>
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default DataImportList

