/* Component  */
import React from 'react'

/* Library  */
import { Bars } from 'react-loader-spinner';

const Loader = ({ isLoading }) => {
    return (
        <Bars
            className="row"
            wrapperStyle={{ marginTop: '150px' }}
            color="#3c4b64"
            height={55}
            width="100%"
            visible={isLoading}
        />
    )
}

export default Loader;
