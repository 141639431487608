/* Component  */

import { axiosGet, axiosPost,axiosPostAuthorize,axiosGetAuthorize } from '../AxiosRequests';
import {
    Admin_GetOrderList,
    Admin_GetOrderDetailsById,
    Admin_UpdateOrderStatusRequest,
    Admin_GetOrderStatusById,
    Admin_GetPaymentInformationById,
    Admin_GetOrderDetails,
    Admin_DeleteOrderById
} from '../ApiEndPoints';

export default class ManageOrderServices {
// API function

    async admin_GetOrderList(request) {
      
        return axiosPostAuthorize(Admin_GetOrderList, request);

    }

    async admin_GetOrderDetails(request) {
    
        return axiosGetAuthorize(Admin_GetOrderDetailsById, request);
    }

    async admin_UpdateOrderStatusRequest(request) {

        return axiosPostAuthorize(Admin_UpdateOrderStatusRequest, request);

    }

    async admin_GetOrderStatus(request) {
     
        return axiosGetAuthorize(Admin_GetOrderStatusById, request);

    }

    async admin_GetPaymentInformationById(request) {
   
        return axiosGet(Admin_GetPaymentInformationById, request);

    }
    async admin_GetOrderDetailsById(request) {
        return axiosPost(Admin_GetOrderDetails, request);
    }
    async admin_DeleteOrderById(request) {
        return axiosPost(Admin_DeleteOrderById, request);
    }

}