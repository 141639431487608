/* eslint-disable default-case */
import BaseDomainService from "./baseDomainServices";
import { ErrorMessage, SuccessMessage } from '../../utils/Messages';
import { getValidationRuleList } from '../../utils/validation/generateValidationRule';
const safetyProduct = {
    singleWord: "",
    hazardStatements: "",
    precautionaryStatements: "",
    tsca: "",
    safetyStatements: "",
    riskStatements: "",
};

export default class SafetyDetailsDomainSevice extends BaseDomainService {

    getSafetyDetailById = this.apiService.productService.getSafetyDetailsById;
    updateSafetyDetail = this.apiService.productService.admin_UpdateProductSafetyDetailsById;
    addSafetyDetail = this.apiService.productService.admin_AddProductSafetyDetailsById;
    deleteSafetyDetailsById = this.apiService.productService.deleteSafetyDetailsById;

    SafetyDetailsTab = [
        { name: "singleWord", title: "Signal Word" },
        { name: "hazardStatements", title: " Hazard Statements" },
        { name: "precautionaryStatements", title: "Precautionary Statements" },
        { name: "tsca", title: " TSCA" },
        { name: "safetyStatements", title: "Safety Statements" },
        { name: "riskStatements", title: " Risk Statements" },
        { name: "productSafetyDetailId", title: " Action" },
    ];

    productSafetyForm = [
        {
            inputType: "text",
            tittle: "Signal Word",
            placeholder: "Signal Word",
            fieldName: "singleWord",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Hazard Statements",
            placeholder: "Hazard Statements",
            fieldName: "hazardStatements",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Precautionary Statements",
            placeholder: "Precautionary Statements",
            fieldName: "precautionaryStatements",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "TSCA",
            placeholder: "TSCA",
            fieldName: "tsca",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Safety Statements",
            placeholder: "Safety Statements",
            fieldName: "safetyStatements",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Risk Statements",
            placeholder: "Risk Statements",
            fieldName: "riskStatements",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
    ]

    seaftyProduct;
    constructor() {
        super();
        this.actionKey = "productSafetyDetailId";
        this.onPaginationUpdate = this.onGetSafetyDetailsById.bind(this);
        this.seaftyProduct = { ...safetyProduct };
    }
    onGetSafetyDetailsById = (requst) => {
        this.apiService.onAPICall("getSafetyDetailById", this.getSafetyDetailById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onUpdate = (requst, getRequest) => {
        this.apiService.onAPICall("updateSafetyDetail", this.updateSafetyDetail, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onAddSafety = (requst, getRequest) => {
        this.apiService.onAPICall("addSafetyDetail", this.addSafetyDetail, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onDeleteSafetyDetailsById = (requst, getRequest) => {
        this.apiService.onAPICall("deleteSafetyDetailsById", this.deleteSafetyDetailsById, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }

    onProductSafetyForm() {
        //This method only call when we use form component and we can add all items which we on on form load
        let valRule = getValidationRuleList(this.productSafetyForm)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    onResetData = () => {
        this.seaftyProduct = { ...safetyProduct }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            updateSafetyState: this.seaftyProduct,
            safetyDetails: this.seaftyProduct,
            validState: resetValidation
        });
    }

    onAPIResponse = (key, isSuccess, responseData, pdId) => {
        if (!isSuccess) {
            this.onUpdateState({
                "setIsLoad": false,
            });
            return;
        }
        switch (key) {
            case "getSafetyDetailById":
                let temp = [];
                if (responseData != null) {
                    temp.push(responseData);
                    this.onUpdateState({
                        seaftyProduct: temp,
                        setIsLoad: false
                    });
                } else if (responseData == null) {

                    this.onUpdateState({
                        seaftyProduct: temp,
                        setIsLoad: false
                    })
                }
                break;
            case "addSafetyDetail":
                this.onUpdateState({
                    SefteyDetails: responseData,
                    "setOpenModal": false,
                    "pageLoader": false,
                    "setIsLoad": false,
                });
                this.onGetSafetyDetailsById(pdId);
                this.onResetData();
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Product Safety"));
                break;
            case "deleteSafetyDetailsById":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Product Safety"));
                this.onUpdateState({
                    "pageLoader": false,
                    "setOpenModal": false
                });
                this.onGetSafetyDetailsById(pdId)
                break;
            case "updateSafetyDetail":
                if (responseData.errorType === "EXISTS" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
                }
                else {
                    this.onUpdateState({
                        "pageLoader": false,
                        "setOpenModal": false
                    });
                    this.onGetSafetyDetailsById(pdId)
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Product Safety"));
                }
                break;
        }
    }
}