/* Component  */
import BaseComponent from '../../shared/baseComponent';

/* Images  */
import NoStructure from '../../images/NoStructure.png';

/* Library  */
import { Bars } from 'react-loader-spinner';
import Table from "../../components/tables/Table";
import FormFieldGroup from '../formGroupFields/FormFieldGroup';
import rfqServices from '../../services/domainServices/rfqServices';
import { ImageURLGenerator, ProjectType } from '../../utils/ImageURLGenerator';
import { DateFromat } from '../../utils/DateFromat';
import { propTypes } from 'react-bootstrap/esm/Image';


export class RfqDetails extends BaseComponent {
  constructor(props) {
    super(props)
    this.rfqService = new rfqServices();
    this.state = {
      pagination: this.rfqService.pagination,
      setIsLoad: false
    }
    this.rfqService.onUpdateState = this.updateState.bind(this);
  }
  addDefaultSrc = (event) => {
    event.target.src = NoStructure
  }
  render() {
    return (
      <>

        {
          (this.props.setIsLoad) ?
            <Bars
              className="row"
              wrapperStyle={{ marginTop: '150px' }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.props.setIsLoad}
            />
            :
            <div className="w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200">
              {this.props.isMulti === true ?
                <div className="rfq-detail-list px-4 sm:px-6 lg:px-8 p-5 relative">
                  <div className='p-5'>
                    <div className="flex gap-5">
                      <div style={{ width: '600px' }}>
                        <h3 className="text-xl leading-snug text-slate-800 font-bold mb-3">Product Details</h3>
                        <span className='font-bold' style={{ fontSize: '15px' }}>CatalogId:</span>
                        {this.props.RFQMultiData && this.props.RFQMultiData.map((RFQitem, index) => {
                          return (
                            <>
                              <span style={{ fontSize: '15px' }} key={index} > {RFQitem.aurumCatalogId}</span>
                              {index < this.props.RFQMultiData.length - 1 && ', '}
                            </>
                          )
                        })}
                        <div className="mt-2">
                          <span className='font-bold' style={{ fontSize: '15px' }}>Expected Delivery Date:</span>
                          <span style={{ fontSize: '15px' }}> {DateFromat(this.props.RFQMultiData ? this.props.RFQMultiData[0].expectedDeliveryDate : null)}</span>
                        </div>
                        <div className="mt-2">
                          <span className='font-bold' style={{ fontSize: '15px' }}>Special Request Note:</span>
                          <span style={{ fontSize: '15px' }}> {this.props.RFQMultiData ? this.props.RFQMultiData[0].specialRequestNote : null}</span>
                        </div>
                      </div>
                      {this.props.RFQDetails ?
                        <div>
                          <h3 className="text-xl leading-snug text-slate-800 font-bold mb-3">Company Details</h3>
                          {this.rfqService.formFields.map((Item, index) => {
                            return (
                              <>
                                {Item.companyDetials === 1 ? (
                                  <FormFieldGroup
                                    column={Item}
                                    value={this.props.RFQDetails[0][Item.fieldName]}
                                    key={index} />
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="pagination-remove overflow-x-auto">
                    <div className="Price-forr-rfq">
                      <Table
                        columns={this.rfqService.gridPriceRangeColmns}
                        rows={this.props.RFQDetails[0].packageList ? this.props.RFQDetails[0].packageList : null}
                        pagination={"null"}
                        sortingColumns={"null"}
                        totalCount={"null"}
                        totalResultes={"null"}
                        setPagination={"null"} />
                    </div>
                  </div>
                </div>
                :
                <div className="rfq-detail-list px-4 sm:px-6 lg:px-8 p-5 relative">
                  {this.props.packageList.map((item, index) => <>
                    {item.emailId ?
                      <div className='email-mark-icon bg-[#20a83c] py-1 pl-4 pr-3 absolute right-0 top-0'>
                        <i className='fas fa-envelope text-white'></i>
                      </div> : " "}
                  </>)}
                  <div className="">
                    <div className="space-y-8">
                      <div className='grid gap-5 md:grid-cols-3'>
                        <div className="image rfq-details-img">
                          <img width="200" src={ImageURLGenerator(ProjectType.AurumPharmatech, this.props.RFQDetails.aurumCatalogId)} onError={this.addDefaultSrc} alt="" />
                        </div>
                        <div>
                          <div className="mb-3">
                            <h3 className="text-xl leading-snug text-slate-800 font-bold">Product Details</h3>
                          </div>
                          {this.rfqService.formFields.map((Item, index) => {
                            return (
                              <>
                                {Item.rfqDetials === 1 ? (
                                  <FormFieldGroup
                                    column={Item}
                                    value={this.props.RFQDetails[Item.fieldName]}
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                        <div>
                          <div className="mb-3">
                            <h3 className="text-xl leading-snug text-slate-800 font-bold">Company Details</h3>
                          </div>
                          {this.rfqService.formFields.map((Item, index) => {
                            return (
                              <>
                                {Item.companyDetials === 1 ? (
                                  <FormFieldGroup
                                    column={Item}
                                    value={this.props.RFQDetails[Item.fieldName]}
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pagination-remove overflow-x-auto">
                    <Table
                      columns={this.rfqService.gridPriceColmns}
                      rows={this.props.packageList}
                      pagination={'null'}
                      sortingColumns={'null'}
                      totalCount={'null'}
                      totalResultes={'null'}
                      setPagination={'null'}
                    />
                  </div>
                </div>
              }
            </div>
        }

      </>
    )
  }
}

export default RfqDetails