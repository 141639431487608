import React from 'react'
import BaseComponent from "../../shared/baseComponent";
import ExceptionServices from '../../services/domainServices/exceptionService';
import { checkParameters } from "../../utils/Encryption";
import Button from '../../components/common/Button';
import { ButtonTypes } from '../../utils/Constants';
import FormFieldGroup from '../../components/formGroupFields/FormFieldGroup';
import { Navigate } from 'react-router-dom';
import ButtonGroup from '../../components/common/ButtonGroup';
import { DateFromat } from '../../utils/DateFromat';

export default class ExceptionDetails extends BaseComponent {
    constructor(props) {
        super(props)
        this.exceptionServices = new ExceptionServices();
        let newPagination = { ...this.exceptionServices.pagination }
        newPagination.pageSize = 25
        this.state = {
            ...this.state,
            setIsLoad: false,
            ExceptionUpdate: this.exceptionServices.dataItem,
            pagination: newPagination,
            callingMethod: "",
            customErrorMessage: "",
            requestedData: "",
            trackTrace: "",
            errorLogId: "",
            systemErrorMessage: "",
            redirect: null,
            Exceptionlog: 0,
            createdDate: ''
        };
        this.exceptionServices.onUpdateState = this.updateState.bind(this);
    }
    componentDidMount() {
        this.exceptionServices.onInitService()
        let params = new URLSearchParams(window.location.search);
        let errorLogId = params.get("");
        if (errorLogId && (errorLogId = checkParameters(errorLogId, "Number"))) {
            if (errorLogId > 0) {
                this.exceptionServices.ongetErrorLogsById(errorLogId);
                this.setState({ Exceptionlog: errorLogId });
            }
        }
    }
    handleChange = (name, value) => {
        let detail = this.state.ExceptionUpdate;
        detail[name] = value;
        this.setState({ ExceptionUpdate: { ...detail } });
    }
    updateException = () => {
        let request = {
            errorLogId: this.state.errorLogId,
            errorNote: this.state.ExceptionUpdate.errorNote,
            isSolved: this.state.ExceptionUpdate.isSolved,
            solvedBy: this.state.ExceptionUpdate.solvedBy
        }
        if (this.exceptionServices.isValidSubmit(this.state.ExceptionUpdate, this.state.validationRules, this.state.validState)) {
            this.exceptionServices.onupdateErrorLogStatus(request, this.state.errorLogId);
        }
    }
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div>
                <div className="flex  ">
                    <div className="relative flex flex-col flex-1 ">
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto">
                            <div className='flex items-center justify-between '>
                                <div className=" sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Exception Details </h1>
                                </div>
                                <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.exceptionServices.onRedirectoList()} />
                            </div>
                            <div className="col-span-full xl:col-span-8 bg-white shadow-lg border border-slate-200 activity_details border-radius-10">
                                <div className="p-5">
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1 '>
                                        <div className='flex p-6 gap-2 useractivitydetailsException'>
                                            <label>Exception Date : </label>
                                            <span>{this.state.createdDate ? DateFromat(this.state.createdDate) : "Data Not Found"}</span>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1 mt-3'>
                                        <div className='flex p-6 gap-2 useractivitydetailsException'>
                                            <label>Calling Method : </label>
                                            <span>{this.state.callingMethod ? this.state.callingMethod : "Data Not Found"}</span>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1  mt-3'>
                                        <div className='flex p-6 gap-2 useractivitydetailsException'>
                                            <label>Custom Error Message : </label>
                                            <span>{this.state.customErrorMessage ? this.state.customErrorMessage : "Data Not Found"}</span>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1  mt-3'>
                                        <div className='flex p-6 gap-2 useractivitydetailsException'>
                                            <label>Requested Data: </label>
                                            <span> {this.state.requestedData ? this.state.requestedData : "Data Not Found"}</span>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1  mt-3'>
                                        <div className='flex p-6 gap-2 useractivitydetailsException'>
                                            <label>Track Trace : </label>
                                            <span>{this.state.trackTrace ? this.state.trackTrace : "Data Not Found"}</span>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1  mt-3'>
                                        <div className='flex p-6 gap-2 useractivitydetailsException'>
                                            <label>System Error Message : </label>
                                            <span>{this.state.systemErrorMessage ? this.state.systemErrorMessage : "Data Not Found"}</span>
                                        </div>
                                    </div>
                                    <div className='px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto useractivitydetailsException rounded-sm border border-slate-200 p-5 mt-5'>
                                        <div className='grid sm:gap-4 md:grid-cols-2'>
                                            {this.exceptionServices.ExceptionformFields.map((Item, index) => {
                                                return (
                                                    <>
                                                        <FormFieldGroup
                                                            column={Item}
                                                            value={this.state.ExceptionUpdate[Item.fieldName]}
                                                            error={this.state.validState.error[Item.fieldName]}
                                                            checked={Item.inputType === 'checkbox' ? this.state.ExceptionUpdate[Item.fieldName] : null}
                                                            onChange={(event) => Item.inputType === 'checkbox' ?
                                                                this.handleChange(Item.fieldName, event.target.checked)
                                                                : this.handleChange(Item.fieldName, event.target.value)}
                                                            onBlur={() => this.exceptionServices.validateField(Item.fieldName, this.state.ExceptionUpdate, this.state.validationRules, this.state.validState)}
                                                            key={index}
                                                        />
                                                    </>
                                                );
                                            }
                                            )}
                                        </div>
                                        <ButtonGroup
                                            onCancelClick={() => this.exceptionServices.onRedirectoList()}
                                            onUpdateClick={() => this.updateException()}
                                            updateState={this.state.errorLogId}
                                            isLoading={this.state.setIsLoad} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
