/* Component  */
import React from "react";
/* Library  */
import { Navigate } from "react-router-dom";
/* Services  */
import baseComponent from "../../shared/baseComponent";
import UserService from "../../services/domainServices/userService";
import { Bars } from "react-loader-spinner";
import CountUp from "react-countup";
import Table from "../../components/tables/Table";
export default class UserDashboard extends baseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props);
    this.userService = new UserService();
    this.state = {
      UserList: [],
      UserCount: [],
      UserInActiveCount: [],
      LoggedUserCount: [],
      pagination: this.userService.pagination,
    };
    this.userService.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.userService.ongetUserDashboardList(this.state.pagination);
    document.title = "User List";
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0 mb-5">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    User Dashboard
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-dash-12 gap-6 UserDashboard ">
                <div className="card-part third-card col-span-full sm:col-span-6 xl:col-span-3">
                  <div class="card-body flex items-center">
                    <div class="icon mr-3">
                      <i className="far fa-user text-[45px] text-[#fff]"> </i>
                    </div>
                    <div>
                      <h2 class="text-white invoice-num">
                        <CountUp
                          duration={5}
                          end={this.state.UserCount.activeUsers}
                        />
                      </h2>
                      <span class="text-white fs-18">Active User</span>
                    </div>
                  </div>
                </div>
                <div className="card-part fourth-card col-span-full sm:col-span-6 xl:col-span-3">
                  <div class="card-body flex items-center">
                    <div class="icon mr-3">
                      <i className="far fa-user text-[45px] text-[#fff]"> </i>
                    </div>
                    <div>
                      <h2 class="text-white invoice-num">
                        <CountUp
                          duration={5}
                          end={this.state.UserInActiveCount.inActiveUsers}
                        />
                      </h2>
                      <span class="text-white fs-18">InActive User</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-list-table countDashboard pagination-remove">
                <header className=" py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Frequently Logged Users
                  </h2>
                </header>
                {this.state.setIsLoad ? (
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: "150px" }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad}
                  />
                ) : (
                  <Table
                    columns={this.userService.UserDashListgridColmns}
                    rows={this.state.UserList}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalCount={this.state.TotalCount}
                    totalResultes={this.state.TotalCount}
                    setPagination={this.userService.onPageChange.bind(this)}
                  />
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
