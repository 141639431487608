/* Component  */
import { axiosGet, axiosPost } from '../AxiosRequests';
import { GetUserActivityLogById, GetUserActivityLogList,GetUserActivityLogByUserId } from '../ApiEndPoints';

export default class ActivityService {
    // API function

    async getUserActivityLogById(request) {
        return axiosGet(GetUserActivityLogById, request);
    }
    async getUserActivityLogByUserId(request) {
        return axiosGet(GetUserActivityLogByUserId, request);
    }
    async getUserActivityLogList(request) {
        return axiosPost(GetUserActivityLogList, request);
    }


}
