import BaseDomainService from "./baseDomainServices";
import moment from 'moment';
import { atobEnc, formatPrice } from "../../utils/Encryption";
const searchHistoryList = {
    searchText: ""
}
export default class shoppingCartService extends BaseDomainService {

    shoppingList = this.apiService.shoppingCartServices.admin_GetAllShoppingCartList;
    getShoppingCartProductsByUser = this.apiService.shoppingCartServices.admin_GetShoppingCartProductsByUser;

    gridColmns = [
        { name: 'userdisplayname', title: 'Full Name' },
        { name: 'userName', title: 'Email Address' },
        { name: 'companyname', title: 'Company Name' },
        { name: 'totalproduct', title: '#Product' },
        { name: 'phoneno', title: 'Contact No' },
        { name: 'totalPrice', title: 'Total Price' },
        { name: 'userId', title: 'Action' }
    ];
    // Define the state variables
    dataItem;
    formFields = [
        {
            inputType: "text",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.onPaginationUpdate = this.onShoppingCartList.bind(this);
        this.actionKey = "userId";
        this.dataItem = { ...searchHistoryList };
    }

    onShoppingCartList = (requst) => {
        this.apiService.onAPICall("shoppingList", this.shoppingList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetShoppingCartProductsByUser = (requst) => {
        this.apiService.onAPICall("getShoppingCartProductsByUser", this.getShoppingCartProductsByUser, requst, this.onAPIResponse, this.onUpdateState);
    };
    onRedirectoList = () => {
        this.onRedirect("/ShoppingCart");
    }



    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "shoppingList":
                let itemList = responseData.itemList;
                itemList.forEach(function (i) {
                    // i.totalPrice = '$' + i.totalPrice ? '$' + i.totalPrice : "-"
                    
                        {i.totalPrice = formatPrice(i.totalPrice)}
                    // return i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                });
                this.onUpdateState({
                    dataList: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });
                break;

            case "getShoppingCartProductsByUser":
                if (responseData !== null) {
                    let getshoppingcart = responseData
                    let cartTotal = 0;
                    getshoppingcart.forEach(value => {
                        value.productPrice = (value.quantityAmount) * (value.price);
                        value.selected = true;
                        cartTotal = cartTotal + value.productPrice;
                    });
                    this.onUpdateState({
                        getshoppingcart: getshoppingcart,
                        totalPrice: cartTotal,
                        isAllSelected: true
                    });
                }
                break;
        }
    }
}

