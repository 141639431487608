/* Component  */
import { createCookie, getCookie, removeAllCookies, isCookieExist, setCookie } from './cookies/CookieHandler';

const authCookieName = 'APAAuthUser';
const tokenCookieName = 'APAToken';

//  Cookies Function 
export const setAuthProps = (data) => {
    const authProps = {
        cookieName: authCookieName,
        cookieValue: data,
        expirationTime: data.sessionTimeout
    }
    createCookie(authProps);
    setTokenProps(data.token);
}

export const getAuthProps = () => {
    return getCookie(authCookieName);
}

export const isAuthorized = () => {
    return isCookieExist(authCookieName);
}

export const setTokenProps = (data) => {
    const tokenProps = {
        cookieName: tokenCookieName,
        cookieValue: data
    }
    setCookie(tokenProps);
}

export const getTokenProps = () => {
    return getCookie(tokenCookieName);
}

export const signOut = () => {
    removeAllCookies();
    window.location.href = "/login";
}

export const getNativationByLogin = (menuItems) => {
    let cookie = getAuthProps();
    let loginRole = cookie.roles;
    let loginUserRoleList = loginRole.roleName.toLowerCase();
    let newMenueItem = [];
    menuItems.forEach((item, i) => {
        let role = item["allowrole"];
        if (role) {
            if (role === "") {
                let newMenu = { ...item };
                let newChildMenu = new Array();
                newChildMenu = getChildMenu(item, loginUserRoleList);
                newMenu["subMenus"] = [...newChildMenu];
                newMenueItem.push(newMenu);
                return;
            }
            else {
                const roleList = role;
                let hasAccess = false;
                roleList.forEach((role, i) => {
                    if (!hasAccess && loginUserRoleList.includes(role.toLowerCase())) {
                        hasAccess = true;
                    }
                });
                if (hasAccess) {
                    let newMenu = { ...item };
                    if (item.subMenus) {
                        let newChildMenu = new Array();
                        newChildMenu = getChildMenu(item, loginUserRoleList);
                        newMenu["subMenus"] = [...newChildMenu];
                    }
                    newMenueItem.push(newMenu);
                }
            }
        }
    });

    return newMenueItem;

}

const getChildMenu = (childMenuList, userRole) => {
    let newChildMenu = new Array();
    childMenuList["subMenus"].forEach((childItem, i) => {
        let childRole = childItem["allowrole"];
        if (childRole === "") {
            newChildMenu.push(childItem);
            return;
        }
        else {
            const roleList = childRole;
            let hasAccess = false;
            roleList.forEach((role, i) => {
                if (!hasAccess && userRole.includes(role.toLowerCase())) {
                    hasAccess = true;
                }
            });
            if (hasAccess) {
                newChildMenu.push(childItem);
                return;
            }
        }
    });
    return newChildMenu;
}

export const IsAuthorizeUser = (allowUserType) => {
    let cookie = getAuthProps();
    if (cookie) {
        let loginRole = cookie.roles;
        let loginUserRoleList = loginRole.roleName.toLowerCase();
        if (allowUserType !== undefined) {
            return allowUserType.find(x => x === loginUserRoleList)
        }
    }
    return ''
}
