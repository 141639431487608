import React from 'react';
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import Button from '../../components/common/Button';
import { ButtonTypes } from '../../utils/Constants';
import BaseComponent from '../../shared/baseComponent';
import { checkParameters } from "../../utils/Encryption";
import TinyEceEditor from '../../components/TinyEceEditor';
import ValidationText from '../../utils/validation/ValidationText';
import { isValidForm } from '../../utils/validation/CommonValidator';
import FormFieldGroup from '../../components/formGroupFields/FormFieldGroup';

// Service
import digestEmailServices from '../../services/domainServices/digestEmailServices';
import ButtonGroup from '../../components/common/ButtonGroup';


export default class DigestEmailDetails extends BaseComponent {
    constructor(props) {
        super(props)
        this.digestEmailServices = new digestEmailServices();
        this.state = {
            ...this.state,
            resetValidationRule: {},
            emailDetails: [],
            isBtnLoading: false,
            setIsLoad: false,
            redirect: null,
            paramList: []
        }
        this.digestEmailServices.onUpdateState = this.updateState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleChangeDefault = this.handleChangeDefault.bind(this);
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.emailDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    handleChange(name, value) {
        let detail = this.state.emailDetails;
        detail[name] = value;
        this.setState({ emailDetails: { ...detail } });
    }

    handleChangeDefault = (e) => {
        const { name, checked } = e.target;
        let detail = this.state.emailDetails;
        detail[name] = checked;
        this.setState({ emailDetails: { ...detail } });
    }

    onUpdateEmailDetailsById = () => {
        if (this.digestEmailServices.isValidSubmit(this.state.emailDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                digestTemplateName: this.state.emailDetails.digestTemplateName,
                digestSubject: this.state.emailDetails.digestSubject,
                digestEmailBody: this.state.emailDetails.digestEmailBody,
                toEmail: this.state.emailDetails.toEmail,
                ccEmail: this.state.emailDetails.ccEmail ? this.state.emailDetails.ccEmail : "",
                isDailyReport: this.state.emailDetails.isDailyReport ? this.state.emailDetails.isDailyReport : 0,
                isWeeklyReport: this.state.emailDetails.isWeeklyReport ? this.state.emailDetails.isWeeklyReport : 0,
                isMonthlyReport: this.state.emailDetails.isMonthlyReport ? this.state.emailDetails.isMonthlyReport : "",
                isActive: this.state.emailDetails.isActive,
                UpdatedBy: this.digestEmailServices.authUser.adminUserId
            }
            this.digestEmailServices.onUpdateDigestEmailDetails(request);
        }
    }
    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }
    onChange(event) {
        var newContent = event.editor.getData();
        let detail = this.state.emailDetails;
        detail['digestEmailBody'] = newContent;
        this.setState({ emailDetails: { ...detail } });
    }
    componentDidMount() {
        this.digestEmailServices.onInitService();
        let params = new URLSearchParams(window.location.search);
        let emailTemplateId = params.get("");
        if (emailTemplateId && (emailTemplateId = checkParameters(emailTemplateId, "Number"))) {
            this.digestEmailServices.onGetDigestEmailDetailsById(emailTemplateId)
            this.digestEmailServices.onGetQueryParameters()
            document.title = 'Digest Email Details';
        }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    back = () => {
        this.setState({ redirect: "/EmailHistory" });
    }

    setDescription = (data) => {
        if (data || data == "") {
            let detail = this.state.emailDetails;
            detail.digestEmailBody = data;
            this.setState({ emailDetails: { ...detail } });
        }
    };
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        {
                            (this.state.setIsLoad) ?
                                <Bars
                                    className="row"
                                    wrapperStyle={{ marginTop: '150px' }}
                                    color="#3c4b64"
                                    height={55}
                                    width="100%"
                                    visible={this.state.setIsLoad}
                                />
                                :
                                <div className="pr-2 sm:pr-3 lg:pr-4 py-4 w-full max-w-9xl mx-auto">
                                    <div className='flex items-center justify-between mb-4'>
                                        <div className="mb-4 sm:mb-0">
                                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Edit Digest Email</h1>
                                        </div>
                                        <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.digestEmailServices.onRedirectoList()} />
                                    </div>
                                    <div className="col-span-full xl:col-span-8 bg-white shadow-lg shadowColor mt-4 mb-3 ">
                                        <div className="grid mb-3">
                                            <div className="col-span-full xl:col-span-8  rounded-sm  ">
                                                <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto ">
                                                    <div className="space-y-8">
                                                        <div className="grid gap-5 md:grid-cols-3">
                                                            {this.digestEmailServices.formFields.map(
                                                                (Item, index) => {
                                                                    return (
                                                                        <>
                                                                            {Item.isUpdate === true ? (
                                                                                <FormFieldGroup
                                                                                    column={Item}
                                                                                    value={this.state.emailDetails[Item.fieldName]}
                                                                                    error={this.state.validState.error[Item.fieldName]}
                                                                                    onChange={Item.inputType === "checkbox" ?
                                                                                        (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                                        :
                                                                                        (event) => this.handleChange(Item.fieldName, event.target.value)
                                                                                    }
                                                                                    onBlur={() => this.digestEmailServices.validateField(Item.fieldName, this.state.emailDetails, this.state.validationRules, this.state.validState)}
                                                                                    key={index}
                                                                                    checked={this.state.emailDetails[Item.fieldName]}
                                                                                />
                                                                            ) : null}
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm mb-3 mt-5">
                                                <div className="grid mb-3">
                                                    <div className="col-span-full xl:col-span-8  rounded-sm  ">
                                                        <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                                                            <div className="mb-4 sm:mb-0">
                                                                <h4 className=" md:text-3xl text-slate-800 ">
                                                                    Select  Placeholders :
                                                                </h4>
                                                            </div>
                                                            <div className="flex flex-wrap mt-4 mb-4 sm:mb-0  md:grid-cols-2">
                                                                <Button
                                                                    type={ButtonTypes.Primary}
                                                                    title="Get Query Parameters"
                                                                    buttonClick={() => this.digestEmailServices.onGetQueryParameters()}
                                                                    isLoading={false}
                                                                />
                                                            </div>
                                                            <div className="flex flex-wrap mt-4 justify-center ">
                                                                <div className="mr-1">
                                                                    <h1 className=" text-slate-800 ">
                                                                        PlaceHolders :
                                                                    </h1>
                                                                    {this.state.paramList.map((e, key) => {
                                                                        return (
                                                                            <div className=" md:grid-cols-2">
                                                                                <h1 className=" text-slate-800 ">
                                                                                    #{e}#
                                                                                </h1>
                                                                            </div>

                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto'>

                                                <TinyEceEditor
                                                    key={"fullPageCKEditor"}
                                                    onEditorChange={(data) => {
                                                        this.setDescription(data);
                                                    }}
                                                    data={this.state.emailDetails.digestEmailBody}
                                                    onBlur={() => {
                                                        this.validateField("digestEmailBody", this.state.emailDetails.digestEmailBody, this.state.validationRules, this.state.validState);
                                                    }}
                                                />
                                                {
                                                    this.state.emailDetails.digestEmailBody ?
                                                        null
                                                        :
                                                        <ValidationText error={this.state.validState.error.digestEmailBody} />
                                                }

                                            </div>
                                            <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                                                <div className="mr-1">
                                                    <label className="flex items-center">
                                                        {this.digestEmailServices.formFields.map((Item, index) => {
                                                            return (
                                                                <>
                                                                    {Item.checked && Item.emailTemplate == true ? (
                                                                        <FormFieldGroup
                                                                            column={Item}
                                                                            value={this.state.emailDetails[Item.fieldName]}
                                                                            onChange={(event) => this.handleChange(Item.fieldName, event.target.checked)}
                                                                            checked={this.state.emailDetails[Item.fieldName]}
                                                                            key={index}
                                                                        />
                                                                    ) : null}
                                                                </>
                                                            );
                                                        }
                                                        )}

                                                        <span className="text-sm ml-2"><b>IsActive </b></span>
                                                    </label>
                                                </div>
                                                <div className='flex items-center'>
                                                    <ButtonGroup
                                                        onCancelClick={() => this.digestEmailServices.onRedirectoList()}
                                                        onUpdateClick={(e) => this.onUpdateEmailDetailsById(e)}
                                                        updateState={1}
                                                        isLoading={this.state.setIsLoad}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            // </div>
                        }
                    </main>
                </div>
            </div>
        )
    }
}


