import React, { Component } from 'react'
import Date_Range_Picker from '../../components/DateRange';
import moment from 'moment';
import { saveAs } from 'file-saver'
import ReportServices from '../../services/axiosServices/apiServices/ReportServices';
import { DateFromat } from '../../utils/DateFromat';

export default class ProductReports extends Component {
    constructor(props) {
        super(props)
        this.reportServices = new ReportServices();
        const today = moment();
        this.state = {
            ipAddressReport: {
                fromDate: '',
                toDate: ''
            }
        }
    }
    componentDidMount() {
        let today = new Date()
        let currentDate = DateFromat(Date());
        let pastdate = today.setDate(today.getDate() - 7);
        let pDate = DateFromat(pastdate)
        this.setState({ start: pDate });
        this.setState({ end: currentDate });
        let det = this.state.ipAddressReport;
        det.fromDate = pDate;
        det.toDate = currentDate;
        this.setState({ ipAddressReport: det });
        this.getDate()
        document.title = 'Report';
    }

    //Other function 
    getDate = () => {
        let currentDate = DateFromat(Date());
        let endDate = (new Date().setDate(new Date().getDate() - 7));
        let pDte = DateFromat(endDate);
        this.setState({ startDate: currentDate });
        this.setState({ endDate: pDte });
    }

    handleLanguage = (changeValue) => {
        let details = this.state.ipAddressReport;
        details.fromDate = changeValue.startDate;
        details.toDate = changeValue.endDate;
        this.setState({ ipAddressReport: details });
    }

    // Function to download report
    ExportReports = (type) => {
        let details = this.state.ipAddressReport;
        details.reportType = type;
        this.reportServices.ExportReports(details).then(
            (res) => {
                var formattedDate = DateFromat(Date())
                var filename = type + formattedDate;
                var blob = new Blob([res.data])
                saveAs(blob, filename + ".xlsx");
            }
        )
    }

    render() {
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="mb-4 sm:mb-0 pr-2 sm:pr-3 lg:pr-4 pt-5 w-full max-w-9xl mx-auto">
                            <h1 className="text-2xl md:text-3xl manageReport_text text-slate-800 font-bold">Viewed Product Reports</h1>
                        </div>
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-8 w-full max-w-9xl mx-auto border-radius-10">
                            <div className="col-span-full xl:col-span-8 bg-white shadow-lg border border-slate-200 mb-3 border-radius-10">
                                <div className="grid mb-3">
                                    <div className="col-span-full xl:col-span-8  rounded-sm p-5 border-slate-200">
                                        <div>
                                            <label className="date_select">Please Select Start Date & End Date.</label>
                                            <div className="flex items-center dashboard-date-range mb-5 mt-2 ">
                                                <Date_Range_Picker onSelectDate={this.handleLanguage} />
                                            </div>
                                            <div className="d-flex reports-button">
                                                <button
                                                    className="btn btn-primary ExpoBtn d-flex justify-content-end gap-2 commanColorbtn text-dark"
                                                    onClick={() => this.ExportReports("ProductByIPAddress")}
                                                > <span>Export by IP Address</span>
                                                    <i class='fas fa-file-excel'></i>
                                                </button>
                                                <button
                                                    className="btn btn-primary ExpoBtn d-flex justify-content-end gap-2 commanColorbtn text-dark"
                                                    onClick={() => this.ExportReports("ProductByUserName")}
                                                > <span>Export by User Name </span>
                                                    <i class='fas fa-file-excel'></i>
                                                </button>
                                                <button
                                                    className="btn btn-primary ExpoBtn d-flex justify-content-end gap-2 commanColorbtn text-dark"
                                                    onClick={() => this.ExportReports("ProductByCatalogId")}
                                                > <span>Export by CatalogId</span>
                                                    <i class='fas fa-file-excel'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
