/* Component  */
import React, { useState } from "react";

/* Library  */
import { Bars } from "react-loader-spinner";
import orderServices from "../../services/domainServices/orderServices";
import BaseComponent from "../../shared/baseComponent";
import NoStructure from "../../images/NoStructure.png";
import FormFieldGroup from "../formGroupFields/FormFieldGroup";
import Table from "../../components/tables/Table";

export class OrdersDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.orderService = new orderServices();

    this.state = {
      pagination: this.orderService.pagination,
      // setOrderStatusType: {
      //   orderStatusId: 1,
      //   orderStatus: "Ordered",
      //   orderStatusId: 2,
      //   orderStatus: "Dispatching",
      //   orderStatusId: 3,
      //   orderStatus: "Shipping",
      //   orderStatusId: 4,
      //   orderStatus: "In Transit",
      //   orderStatusId: 5,
      //   orderStatus: "Delivered",
      // },
      getUsersDetails: this.orderService.dataItem,
    };
    this.orderService.onUpdateState = this.updateState.bind(this);
  }
  addDefaultSrc = (event) => {
    event.target.src = NoStructure;
  };

  render() {
    return (
      <div>
        {this.props.setIsLoad ? (
          <Bars
            className="row"
            wrapperStyle={{ marginTop: "150px" }}
            color="#3c4b64"
            height={55}
            width="100%"
            visible={this.props.setIsLoad} />
        ) : (
          <>
            <div className="grid grid-cols-12 gap-6  mb-3">
              <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 card-label-sec">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">User Details</h2>
                </header>
                <div className="p-3">
                  {this.orderService.formFields.map((Item, index) => {
                    return (
                      <>

                        {Item.userDetails === 1 ? (
                          <FormFieldGroup
                            column={Item}
                            value={this.props.orderDetails[Item.fieldName]}
                            checked={this.state.getUsersDetails[Item.fieldName]}
                          />
                        ) : null}

                      </>
                    );
                  })}
                </div>
              </div>
              <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 card-label-sec">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Shipping Address
                  </h2>
                </header>
                <div className="p-3">
                  <div>

                    <ul className="my-1">
                      {this.orderService.formFields.map((Item, index) => {
                        return (
                          Item.address === true ?
                            <>
                              <div className="self-center">
                                <i className="icon-sm fal fa-map-marker fw-bold pe-2 addressIcon mr-2"></i> {this.props.orderShippingAddress.addressLine1 ? this.props.orderShippingAddress.addressLine1 : "NA"},
                                {this.props.orderShippingAddress.addressLine2 ? this.props.orderShippingAddress.addressLine2 : "NA"}, <br />
                                {this.props.orderShippingAddress.city ? this.props.orderShippingAddress.city : "NA"},
                                {this.props.orderShippingAddress.countryName ? this.props.orderShippingAddress.countryName : "NA"},
                                {this.props.orderShippingAddress.zipCode ? this.props.orderShippingAddress.zipCode : "NA"}
                              </div>
                            </>
                            :
                            <li>

                              {Item.shippingAddress === 1 ? (
                                <FormFieldGroup
                                  column={Item}
                                  value={
                                    this.props.orderShippingAddress[
                                    Item.fieldName
                                    ]
                                  }
                                />
                              ) : null}
                            </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 card-label-sec">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Billing Address
                  </h2>
                </header>
                <div className="p-3">
                  <div>
                    <ul className="my-1">
                      {this.orderService.formFields.map((Item, index) => {
                        return (
                          Item.address === true ?
                            <>
                              <div className="self-center">
                                <i className="icon-sm fal fa-map-marker fw-bold pe-2 addressIcon mr-2"></i>{this.props.orderBillingAddress.addressLine1 ? this.props.orderBillingAddress.addressLine1 : "NA"},
                                {this.props.orderBillingAddress.addressLine2 ? this.props.orderBillingAddress.addressLine2 : "NA"}, <br />
                                {this.props.orderBillingAddress.city ? this.props.orderBillingAddress.city : "NA"},
                                {this.props.orderBillingAddress.countryName ? this.props.orderBillingAddress.countryName : "NA"},
                                {this.props.orderBillingAddress.zipCode ? this.props.orderBillingAddress.zipCode : "NA"}
                              </div>
                            </>
                            :
                            <li>
                              {Item.shippingAddress === 1 ? (
                                <FormFieldGroup
                                  column={Item}
                                  value={
                                    this.props.orderBillingAddress[
                                    Item.fieldName
                                    ]
                                  }
                                />
                              ) : null}
                            </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-span-12 bg-white shadow-lg rounded-sm border border-slate-200">
                <div className="p-3">
                  <div>
                    <div className="grow flex justify-between">

                      {this.orderService.formFields.map((Item, index) => {
                        return (
                          <>
                            {Item.orderDetials === 1 ? (
                              <FormFieldGroup
                                column={Item}
                                value={this.props.orderDetails[Item.fieldName]}
                              />
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200 mb-4">
              <div className="p-3">
                <div className="pagination-remove overflow-x-auto">
                  <div className="price_formate">
                    <Table
                      columns={this.orderService.gridProductColumn}
                      rows={this.props.orderProducts}
                      pagination={'null'}
                      sortingColumns={'null'}
                      totalCount={'null'}
                      totalResultes={'null'}
                      setPagination={'null'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-2 mt-4 ">
              <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 card-label-sec">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Total Price Information{" "}
                  </h2>
                </header>
                <div className="p-3 space-beetwin-sec">
                  {this.orderService.formFields.map((Item, index) => {
                    return (
                      <>
                        {Item.priceInformation === 1 ? (
                          <FormFieldGroup
                            column={Item}
                            value={'$' + this.props.orderDetails[Item.fieldName]}
                          />
                        ) : null}
                        {Item.priceTotal === 1 ? (
                          <FormFieldGroup
                            column={Item}
                            value={'$' + this.props.productTotal}
                          />
                        ) : null}

                        {this.props.orderDetails.handlingCharges || this.props.orderDetails.cardProcessingCharges || this.props.orderDetails.additionalCharges || this.props.orderDetails.tax || this.props.orderDetails.discountPrice ?
                          (
                            <>
                              {Item.handlingCharges === 1 && this.props.orderDetails.handlingCharges ?
                                (
                                  <FormFieldGroup
                                    column={Item}
                                    value={'$' + this.props.orderDetails[Item.fieldName]}
                                  />
                                )
                                : null
                              }
                              {Item.cardProcessingCharges === 1 && this.props.orderDetails.cardProcessingCharges ?
                                (
                                  <FormFieldGroup
                                    column={Item}
                                    value={'$' + this.props.orderDetails[Item.fieldName]}
                                  />
                                )
                                : null
                              }
                              {Item.additionalCharges === 1 && this.props.orderDetails.additionalCharges ?
                                (
                                  <FormFieldGroup
                                    column={Item}
                                    value={'$' + this.props.orderDetails[Item.fieldName]}
                                  />
                                )
                                : null
                              }
                              {Item.tax === 1 && this.props.orderDetails.tax ?
                                (
                                  <FormFieldGroup
                                    column={Item}
                                    value={'$' + this.props.orderDetails[Item.fieldName]}
                                  />
                                )
                                : null
                              }
                              {Item.discountPrice === 1 && this.props.orderDetails.discountPrice ?
                                (
                                  <FormFieldGroup
                                    column={Item}
                                    value={'$' + this.props.orderDetails[Item.fieldName]}
                                  />
                                )
                                : null
                              }
                            </>
                          )
                          : null

                        }
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 card-label-sec">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Shipping Information{" "}
                  </h2>
                </header>
                {
                  this.props.shippingMethod === "Own Shipping" ?
                    <div className=" shrink-0 self-end ml-2 p-3 space-beetwin-sec border-none">
                      {this.orderService.formFields.map((Item, index) => {
                        return (
                          <>
                            {Item.shippingInfo === 2 ? (
                              <FormFieldGroup
                                column={Item}
                                value={this.props.orderDetails[Item.fieldName]}
                              />
                            ) : null}
                          </>
                        );
                      })}
                    </div>
                    : <div className=" shrink-0 self-end ml-2 p-3 space-beetwin-sec border-none">
                      {this.orderService.formFields.map((Item, index) => {
                        return (
                          <>
                            {Item.shippingInformation === 1 ? (
                              <FormFieldGroup
                                column={Item}
                                value={this.props.orderDetails[Item.fieldName]}
                              />
                            ) : null}
                          </>
                        );
                      })}
                    </div>


                }
              </div>
              <div className="col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 card-label-sec">
                <header className="px-5 py-4 border-b border-slate-100">
                  <h2 className="font-semibold text-slate-800">
                    Order Information{" "}
                  </h2>
                </header>
                <div className=" shrink-0 self-end ml-2 p-3 space-beetwin-sec border-none">
                  {this.orderService.formFields.map((Item, index) => {
                    return (
                      <>
                        {Item.otherInformation === 1 && (this.props.orderDetails[Item.fieldName] !== 'NA') ? (
                          <FormFieldGroup
                            column={Item}
                            value={this.props.orderDetails[Item.fieldName]}
                          />
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default OrdersDetail;
