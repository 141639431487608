import { IsProdMode, IsTestMode } from '../../utils/AppSetting';

const liveURL = process.env.REACT_APP__LIVEURLS
const testURL = process.env.REACT_APP_TESTURL
const localHost = process.env.REACT_APP_LOCALURL

const imageRenderUrl = process.env.REACT_APP_IMAGE_RENDERURL

export const IMAGESAPI = (IsProdMode) ? liveURL : (IsTestMode) ? testURL : imageRenderUrl;

const ssoLiveURL = process.env.REACT_APP_SSOLIVEURL
const ssoTestURL = process.env.REACT_APP_SSOTESTURL
const ssolocalHost = process.env.REACT_APP_SSOLOCALURL


// Web API url
export const APIURL = (IsProdMode) ? liveURL : (IsTestMode) ? testURL : localHost;
export const SSOAPIURL = (IsProdMode) ? ssoLiveURL : (IsTestMode) ? ssoTestURL : ssolocalHost;


//User Service
export const Admin_GetUserList = "User/Admin_GetUserList";
export const Admin_GetAdminUsers = "User/Admin_GetAdminUsers";
export const UpdateUserStatus = "User/UpdateUserStatus"
export const sendForgetPasswordLink = "User/Admin_sendForgetPasswordLink"
export const GetUserDetailsByUserId = "User/GetUserDetailsByUserId?id={0}"
export const UpdateUserDetails = "User/UpdateUserDetails_Admin"
export const GetAllCountries = "Addresses/GetAllCountries";
export const AddUserLogin = "User/AddUserLogin";
export const Admin_GetUsersDashboardCounts = "User/Admin_GetUsersDashboardCounts";



///RFQ
export const GetRFQList = "RFQ/Admin_GetRFQList";
export const Admin_GetRFQById = "RFQ/Admin_GetRFQById?id={0}";
export const Admin_GetProductPricesById = "Product/Admin_GetProductPricesById?id={0}";
export const Admin_AddRFQResponse = "RFQ/Admin_AddRFQResponse";
export const Admin_GetUserRFQDetails = "RFQ/GetRFQListBySearch";
export const DeleteRFQById = "RFQ/DeleteRFQById";


//Order Services
export const Admin_GetOrderList = "Order/Admin_GetOrderList";
export const Admin_GetOrderDetailsById = "Order/Admin_GetOrderDetailsById?id={0}";
export const Admin_UpdateOrderStatusRequest = "Order/Admin_UpdateOrderStatus"
export const Admin_GetOrderStatusById = "Order/Admin_GetOrderStatusById?id={0}"
export const Admin_GetPaymentInformationById = "Order/Admin_GetPaymentInformationById?id={0}"
export const Admin_GetOrderDetails = "Order/GetOrdersByUserId"
export const Admin_DeleteOrderById = "Order/Admin_DeleteOrderByOrderId"



// Payment
export const PayOrderViaCard = "Payment/PayOrderViaCard";
export const CreatePaymentIntent = "Payment/CreatePaymentIntent";
export const SaveCard = "Payment/SaveCard";
export const GetPaymentMethodList = "Payment/GetPaymentMethodList";
export const DeletePaymentMethod = "Payment/DeletePaymentMethod";

//Contact Services
export const Admin_GetContactUsList = "ContactUs/Admin_GetContactUsList";
export const Admin_GetContactUsById = "ContactUs/Admin_GetContactUsById?id={0}";
export const Admin_AddContactUsResponse = "ContactUs/Admin_AddContactUsResponse";

//Audit Services
export const Admin_GetAuditLogList = "AuditLog/GetAuditLogList";
export const Admin_GetSiteTrackerIPList = "AuditLog/GetSiteTrackerIPList";

//Product Services
export const Admin_GetAllProductsList = "Product/Admin_GetAllProductsList";
export const Admin_UpdateProductDetailsById = "Product/Admin_UpdateProductDetailsById?id={0}";
export const Admin_GetDeliveryAvailibilityByProductId = "Product/Admin_GetDeliveryAvailibilityByProductId?id={0}";
export const Admin_UpdateProductDeliveryTime = "Product/Admin_UpdateProductDeliveryTime?id={0}";
export const Admin_GetProductsDetailsById = "Product/Admin_GetProductsDetailsById?id={0}";
export const DeleteProductPriceById = "Product/DeleteProductPriceById";
export const Admin_UpdateProductPriceById = "Product/Admin_UpdateProductPriceById";
export const GetAllAvailability = "Search/GetAllAvailability";
export const Admin_AddPriceById = "Product/Admin_AddPriceById";
export const GetAllSizes = "RFQ/GetAllSizes";
export const GetAllCurrencies = "Product/GetAllCurrencies";
export const GetProductPropertyById = "Product/GetProductPropertyById?id={0}";
export const Admin_UpdateProductPropertiesById = "Product/Admin_UpdateProductPropertiesById?id={0}";
export const Admin_AddProductPropertiesById = "Product/Admin_AddProductPropertiesById";
export const GetSafetyDetailsById = "Product/GetSafetyDetailsById?id={0}";
export const Admin_UpdateProductSafetyDetailsById = "Product/Admin_UpdateProductSafetyDetailsById";
export const Admin_AddProductSafetyDetailsById = "Product/Admin_AddProductSafetyDetailsById";
export const DeleteSafetyDetailsById = "Product/DeleteSafetyDetailsById";
export const Admin_GetStockAvailibilityByProductId = "Product/Admin_GetStockAvailibilityByProductId?id={0}";
export const Admin_AddStockById = "Product/Admin_AddStockById";
export const Admin_GetStockById = "Product/Admin_GetStockById";
export const Admin_UpdateProductStockById = "Product/Admin_UpdateProductStockById";
export const DeleteStockById = "Product/DeleteStockById";
export const GetAllStockLocation = "Product/GetAllStockLocation";
export const Admin_GetProductDashboardCounts = "Product/Admin_GetProductDashboardCounts";
export const Admin_GetPriceDetailsByProductId = "Product/Admin_GetPriceDetailsByProductId?id={0}";
export const UpdateProductPriceDetails = "Product/UpdateProductPriceDetails";
export const AdminGetAllEmptyProductCodeCounts = "Product/AdminGetAllEmptyProductCodeCounts";
export const UpdateRandonNumberForProductCode = "Product/UpdateRandonNumberForProductCode";
export const ExportSupplierDirectLink = "Settings/ExportSupplierDirectLink?supplierName={0}";

//Authentication services
export const AdminLogin = "Authentication/AdminLogin";
export const ValidateToken = "Authentication/ValidateToken";
export const ProjectAuthentication = "Account/ProjectAuthentication";
export const GetUserDetailsByClientIdAndSecretkey = "Authentication/GetUserDetailsByClientIdAndSecretkey";

//User Search  Services
export const Admin_GetUserSearchHistory = "Search/GetUserSearchHistory";


//Profile
export const Admin_ResetPassword = "Account/Admin_ResetPassword";
export const Admin_GetAdminUsersById = "User/Admin_GetAdminUsersById?id={0}";
export const Admin_UpdateAdminUsersById = "User/Admin_UpdateAdminUsersById?id={0}";
export const Admin_GetAllSearchHistoryList = "User/Admin_GetAllSearchHistoryList";
export const Admin_GetAllSearchHistoryListByUser = "User/Admin_GetAllSearchHistoryListByUser";



// Email services
export const Admin_GetEmailList = "Email/Admin_GetEmailList";
export const Admin_GetEmailTemplateById = "Email/Admin_GetEmailTemplateById?id={0}";
export const Admin_UpdateEmailDetailsById = "Email/Admin_UpdateEmailDetailsById";
export const Admin_AddEmailTemplate = "Email/Admin_AddEmailTemplate"

//Shopping Cart Services
export const Admin_GetShoppingCartProductsByUser = "ShoppingCart/Admin_GetShoppingCartProductsByUser?id={0}";
export const GetAddressesList = "Addresses/GetAddressesList";
export const Admin_GetAllShoppingCartList = "ShoppingCart/Admin_GetAllShoppingCartList";


//Dashboard Services
export const Admin_GetDashboardCounts = "Dashboard/Admin_GetDashboardCounts";
export const Admin_GetRecentRFQ = "RFQ/Admin_GetRecentRFQ";
export const Admin_GetRecentsOrders = "Order/Admin_GetRecentsOrders";
export const Admin_GetRecentUsers = "User/Admin_GetRecentUsers";
export const Admin_GetRecentContactUs = "ContactUs/Admin_GetRecentContactUs";

//Import Data Services
export const Admin_AddImport = "Import/Admin_AddImport";
export const Admin_GetImportDetailsById = "Import/Admin_GetImportDetailsById?id={0}"
export const Admin_GetImportList = "Import/Admin_GetImportList"

//Admin_GetBlockIpAddressList Services
export const Admin_GetBlockIpAddressList = "BlockIpAddress/Admin_GetBlockIpAddressList";
export const Admin_AddBlockIpAddress = "BlockIpAddress/Admin_AddBlockIpAddress";
export const GetBlockIpAddressList = "BlockIpAddress/GetBlockIpAddressList";
export const Admin_UpdateIsBlacklist = "BlockIpAddress/Admin_UpdateIsBlacklist?id={0}";
export const Admin_ProductsSearchByIpAddressCounts = "BlockIpAddress/Admin_ProductsSearchByIpAddressCounts?id={0}";


//Common 
// export const RenderImageUsingImageType = "Common/RenderImageUsingImageType?type={0}&image={1}";
export const RenderImageUsingImageType = "Image/GetRenderImageUsingCatalogId?type={0}&catalogId={1}";

//CompanyServies
export const GetAllCompaniesList = "Company/GetAllCompaniesList";

//Report Services
export const ExportReports = "Report/ExportReports";

//Exception Services
export const GetErrorLogList = "ErrorLog/GetErrorLogList";
export const UpdateErrorLogStatus = "ErrorLog/UpdateErrorLogStatus";
export const GetErrorLogsById = "ErrorLog/GetErrorLogsById?id={0}";

//Activity Services
export const GetUserActivityLogList = "User/GetUserActivityLogList";
export const GetUserActivityLogById = "User/GetUserActivityLogById?id={0}";
export const GetUserActivityLogByUserId = "User/GetUserActivityLogByUserId?id={0}";


//Setting Services
export const GetIpAddressList = "Settings/GetIpAddressList";
export const GetAllIPAddresses = "Settings/GetAllIPAddresses";
export const UpdateIpAddress = "Settings/UpdateIpAddress";
export const GetIpAddressById = "Settings/GetIpAddressById?id={0}";
export const Admin_GetIPAddressCounts = "Settings/Admin_GetIPAddressCounts";

//Digest Email services
export const Admin_GetDigestEmailList = "DigestEmail/GetDigestEmailList";
export const Admin_GetDigestEmailTemplateById = "DigestEmail/GetDigestEmailById?id={0}";
export const Admin_UpdateDigestEmailDetailsById = "DigestEmail/UpdateDigestEmail";
export const Admin_AddDigestEmailTemplate = "DigestEmail/AddDigestEmail"
export const Admin_GetQueryParameters = "DigestEmail/GetAllQueryParameters"


//Informatics Configurations

export const GetInformaticsConfigurationList = "Informatics/GetInformaticsConfigurationList";
export const GetInformaticsComponentWizardByIdList = "Informatics/GetInformaticsComponentWizardByIdList?id={0}";
export const UpdateInformaticsConfiguration = "Informatics/UpdateInformaticsConfiguration";
export const UpdateInformaticsComponentWizard = "Informatics/UpdateInformaticsComponentWizard";
export const GetInformaticsConfigurationById = "Informatics/GetInformaticsConfigurationById?id={0}";
export const GetInformaticsComponentWizardById = "Informatics/GetInformaticsComponentWizardById?id={0}";
export const AddInformaticsComponentWizard = "Informatics/AddInformaticsComponentWizard";
export const DeleteInformaticsComponentWizard = "Informatics/DeleteInformaticsComponentWizard";

export const GetComponentWizardsList = "Informatics/GetComponentWizardsList?id={0}&date={1}";
export const ExportGetComponentWizardsList = "Informatics/ExportGetComponentWizardsList?configurationId={0}&selectedDate={1}&componentWizardId={2}";

//DatabaseHealth

export const AddDatabaseHealthWizard = "DatabaseHealth/AddDatabaseHealthWizard";
export const GetDatabaseHealthWizardList = "DatabaseHealth/GetDatabaseHealthWizardList";
export const UpdateDatabaseHealthWizard = "DatabaseHealth/UpdateDatabaseHealthWizard";
export const DeleteDatabaseHealthWizard = "DatabaseHealth/DeleteDatabaseHealthWizard";
export const ExportDatabaseHealthWizardOutput = "DatabaseHealth/ExportDatabaseHealthWizardOutput?id={0}";
export const GetDatabaseHealthWizardById = "DatabaseHealth/GetDatabaseHealthWizardById?id={0}";
export const GetDatabaseHealthWizardOutput = "DatabaseHealth/GetDatabaseHealthWizardOutput?id={0}";