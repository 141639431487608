/* Component  */
import React from 'react';
import Form from '../../components/form/Form';

/* Library  */
import { Bars } from 'react-loader-spinner';
import { ErrorMessage } from '../../utils/Messages';

/* Services  */
import BaseComponent from '../../shared/baseComponent';
import ProfileDomainServices from '../../services/domainServices/profileService';

export default class AdminProfile extends BaseComponent {
    /* Constructor and Component method */
    constructor(props) {
        super(props)
        this.state = {
            getAdminUser: [],
            setIsLoad: false,
            formObject: {
                firstName: "",
                lastName: "",
                userName: "",
                isActive: "",
                isSuperAdmin: "",
            },
            reSetFormObject: {
                requiredInput: "",
            }
        }
        this.profileDomainService = new ProfileDomainServices();
        this.profileDomainService.onUpdateState = this.updateState.bind(this);
    }
    componentDidMount() {
        let authUserId = this.profileDomainService.authUser.adminUserId;
        this.profileDomainService.onGetById(authUserId);
        document.title = 'Admin User Profile';
    }

    // Other function
    setFormValues = (key, value) => {
        let newFormvalues = { ...this.state.formObject };
        newFormvalues[key] = value.trim().replace(/^\s+/, '');;
        this.setState({ formObject: newFormvalues });
    }
    setResetFormValues = (key, value) => {
        let newFormvalues = { ...this.state.reSetFormObject };
        newFormvalues[key] = value.trim().replace(/^\s+/, '');;
        this.setState({ reSetFormObject: newFormvalues });
    }
    submitForm = () => {
        this.profileDomainService.onUpdate(this.state.formObject);
    }
    cancel = () => { }
    resetCancel = () => { }

    // API function
    resetPassword = () => {
        let request = {
            adminUserId: this.profileDomainService.authUser.adminUserId,
            password: this.state.reSetFormObject["password"],
        }
        this.profileDomainService.onResetPassword(request);
    }
    resetSubmitForm = () => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})");
        if (typeof this.state.reSetFormObject["password"] !== "undefined" && typeof this.state.reSetFormObject["confirm_password"] !== "undefined") {
            if (!strongRegex.test(this.state.reSetFormObject["password"] && this.state.reSetFormObject["confirm_password"])) {
                this.profileDomainService.apiService.swalServices.Toaster(ErrorMessage.PasswordNotValid);
            }
            else if (this.state.reSetFormObject["password"] !== this.state.reSetFormObject["confirm_password"]) {
                this.profileDomainService.apiService.swalServices.Toaster(ErrorMessage.ComparePassword);
            }
            else if (this.state.reSetFormObject["password"] === this.state.reSetFormObject["confirm_password"]) {
                this.resetPassword();
            }
        }
    }

    render() {
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {(this.state.setIsLoad) ?
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: '150px' }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={this.state.setIsLoad}
                        />
                        :
                        <main>
                            <div className="pr-2 sm:pr-3 lg:pr-4 py-8 w-full max-w-9xl mx-auto">
                                <div className="col-span-full xl:col-span-8 bg-white shadow-lg border border-slate-200 mb-3 border-radius-10">
                                    <div className="grid mb-3">
                                        <ul className="px-5 py-4 ">
                                            <li className="flex px-2">
                                                <div className="grow flex items-center  text-lg ">
                                                    <div className="grow flex justify-between">
                                                        <div className="self-center">
                                                            <h2 className="font-semibold text-slate-800  text-[20px]">Profile Information</h2></div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="col-span-full xl:col-span-8  rounded-sm  border-slate-200">
                                            <div>
                                                <Form form={this.profileDomainService.form}
                                                    formValues={this.state.formObject}
                                                    setFormValues={this.setFormValues.bind(this)}
                                                    submitForm={this.submitForm.bind(this)}
                                                    cancel={this.cancel.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-full xl:col-span-8 bg-white shadow-lg border border-slate-200 mb-3 border-radius-10">
                                    <div className="grid mb-3">
                                        <ul className="px-5 py-4 ">
                                            <li className="flex px-2">
                                                <div className="grow flex items-center  text-lg ">
                                                    <div className="grow flex justify-between">
                                                        <div className="self-center">
                                                            <h1 className="font-semibold text-slate-800 text-[20px]">Reset Password</h1></div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="col-span-full xl:col-span-8  rounded-sm  border-slate-200">
                                            <div>
                                                <Form form={this.profileDomainService.reSetform}
                                                    formValues={this.state.reSetFormObject}
                                                    setFormValues={this.setResetFormValues.bind(this)}
                                                    submitForm={this.resetSubmitForm.bind(this)}
                                                    cancel={this.resetCancel.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    }
                </div>
            </div>
        )
    }
}


