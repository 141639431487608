import baseDomainServices from "./baseDomainServices"

const searchHistoryList = {
    searchText: ""
}

export default class CompanyServices extends baseDomainServices {

    getCompanyList = this.apiService.companiesServices.getAllCompaniesList;

    companylistgrid = [
        { name: "companyName", title: "company Name" },
        { name: "userCount", title: "user Count" },
    ]

    SearchformFields = [
        {
            inputType: "text",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none',
        }
    ]

    dataItem;

    constructor() {
        super();
        this.onPaginationUpdate = this.OngetCompanylist.bind(this);
        this.dataItem = { ...searchHistoryList };
    }

    //  API Calls

    OngetCompanylist = (requst) => {
        this.apiService.onAPICall("companylist", this.getCompanyList, requst, this.onAPIResponse, this.onUpdateState)
    }

    onAPIResponse = (key, iSuccess, responseData) => {
        if (!iSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "companylist":
                if (responseData !== null) {
                    this.onUpdateState({
                        getCompanies: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    })
                }
                break;
        }
    }
}
