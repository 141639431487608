// ButtonGroup.js

import React from 'react';
import Button from './Button';
import { ButtonTypes } from '../../utils/Constants';


const ButtonGroup = ({ onCancelClick, onUpdateClick, onResetPass, resetState, onAddClick, updateState, isLoading }) => {
    return (
        <div className="flex justify-end gap-3 ">
            <Button
                type={ButtonTypes.Secondary}
                title="Cancel"
                isLoading={isLoading}
                buttonClick={onCancelClick}
            />
            {updateState ? (
                <Button
                    type={ButtonTypes.Primary}
                    title="Update"
                    isLoading={isLoading}
                    buttonClick={onUpdateClick}
                />
            ) : (
                <Button type={ButtonTypes.Primary} title="Add" isLoading={isLoading} buttonClick={onAddClick} />
            )}
            {resetState ? (
                <Button
                    type={ButtonTypes.Primary}
                    title="Reset Password"
                    isLoading={isLoading}
                    buttonClick={onResetPass}
                />
            ) : null}
        </div>
    );
};

export default ButtonGroup;
