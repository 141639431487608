/* Component  */
import React from 'react';
import Table from '../../components/tables/Table';

/* Library  */
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import { encryptAES } from "../../utils/Encryption";

/* Services  */
import baseComponent from "../../shared/baseComponent";
import UserService from '../../services/domainServices/userService';
import FormFieldGroup from '../../components/formGroupFields/FormFieldGroup';

export default class UserList extends baseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.userService = new UserService();
    this.state = {
      getusers: [],
      setIsLoad: false,
      userActiveList: [],
      searchList: this.userService.dataItem,
      pagination: this.userService.pagination,
      authUser: this.userService.authUser
    }
    this.userService.onUpdateState = this.updateState.bind(this);
    this.userService.onActionCall = this.onActionCall.bind(this);
  }

  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.setState({ isLoading: true });
      this.userService.onGetUserList(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ searchList: { ...detail } });
  }

  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  }

  componentDidMount() {
    this.userService.onGetUserList(this.state.pagination);
    document.title = 'User List';
  }

  onActionCall = (type, index, data) => {
    switch (type) {
      case "UserList":
        window.open(`/UserDetails/userId?=${encryptAES(data)}`, '_blank');
        break;
      case "userLogin":
        let request = {
          userId: data,
          createdBy: this.state.authUser.adminUserId
        }
        this.userService.onAddUserLogin(request);
        break;
    }
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />
    }
    return (

      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Users </h1>
                </div>

                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3">
                  <div className="relative" style={{ display: "contents" }}>
                    <div>
                      {this.userService.SearchformFields.map((Item, index) => {
                        return (
                          <>
                            {Item.inputType === "text" ?
                              <FormFieldGroup className='ms-auto'
                                column={Item}
                                value={
                                  this.state.searchList[Item.fieldName]
                                }
                                onChange={(event) =>
                                  this.handleChangeEvent(Item.fieldName, event.target.value)
                                }
                                onKeyPress={(e) =>
                                  e.key === "Enter" &&
                                  this.handleChange(Item.fieldName, e.target.value)
                                }
                                key={index} /> : null}</>)
                      })}
                    </div>
                    <button className="btnn commanColorbtn text-dark" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                      <span className="xs:block">Search</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className='main-User-list-grid'>
                {
                  (this.state.setIsLoad) ?
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: '150px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                    :
                    <Table
                      columns={this.userService.UserListgridColmns}
                      rows={this.state.getusers}
                      sortingColumns={[]}
                      pagination={this.state.pagination}
                      totalCount={this.state.TotalCount}
                      totalResultes={this.state.TotalCount}
                      setPagination={this.userService.onPageChange.bind(this)}
                      customScope={[
                        {
                          column: 'userId',
                          column1: this.state.getusers,
                          renderTableData: this.userService.UserListactions.bind(this)
                        },

                      ]}
                    />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
