import React from 'react'

const ViewAddressModelStyle = () => {
    return (
        <style jsx global>
            {
                `
            .address-popup-box {
                position: fixed;
                background: #00000050;
                overflow: auto;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 999;
            }
            .card.custom>.card-body,
.card.custom>.card-body-light {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
}

.card.custom>.card-body-semi-light {
    background-color: var(--primaryLightColor);
}

.card.custom>.card-body-light {
    background-color: #fff !important;
}
            
            .address-box {
                position: relative;
                width: 40%;
                margin: 0 auto;
                height: auto;
                margin-top: calc(62vh - 50vh - 10px);
                background: #fff;
                border-radius: 5px;
                border: 1px solid #999;
                overflow: auto;
                margin-bottom: 10px;
            }
            
            .modal-header {
                background-color: #f7f7f7;
                
            }
            
            
            .card {
                display: block;
                border-radius: 4px;
                -webkit-box-shadow: 0 2px 12px rgb(0 0 0 / 12%);
                box-shadow: 0 2px 12px 11px rgb(0 0 0 / 12%);
                margin-bottom: 20px;
                background-color: #fff;
                -webkit-transition: .3s;
                transition: .3s;
            }
            
         
            
            .bd-highlight {
                color: black;
            }
            
           
            .productInfo {
                background: #f7f9e0 !important;
                border-radius: 5px;
                width: 90rem;
                margin-left: 12px;
            }
            
            .productInfos {
                color: #002e52;
                white-space: nowrap;
                font-size: 16px;
                font-weight: 600;
                // margin-top: 4px;
            }
            
            .producinfohedingone {
                font-weight: 600;
                color: #0062bd;
            }
            
            .datepicker {
                color: #fff;
                color: #333;
                background: #e9ecef;
                width: 12rem;
            }
            
            .InquiryRequest {
                padding: 10px;
            }
            
            .blockquote {
                color: #060606;
                font-weight: 500;
                font-size: 22px;
            }
            
            .blockquote p {
                padding: 12px;
                background: #f7f7f7;
                color: #060606;
                font-weight: 500;
                font-size: 22px;
            }
            
            .PrdouctStyling {
                color: black !important;
                font-size: 18px;
                font-weight: 700;
            }
            
            .SecondSection {
                margin-top: 4px;
            }
            
            .buttonStyling {
                background-color: #ffc107;
                top: 0;
                padding: 4px 9px;
                line-height: 24px;
                color: #fff;
                position: relative;
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                -webkit-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
                cursor: pointer;
                overflow: hidden;
                border: none;
                border-radius: 4px;
                -webkit-box-shadow: 0 4px 12px rgb(102 103 107 / 15%);
                box-shadow: 0 4px 12px rgb(102 103 107 / 15%);
            }
            
            .inputBar {
                height: 53px;
            }
            
            .AdjustForm {
                // width: 43rem;
                // resize: vertical;
                width: 100%;
                border-radius: 4px;
                resize: vertical;
            }
            
            .AdjustForm2 {
                width: 21rem;
            }
            
            .AdjustingForm {
                padding-left: 2%;
                padding-right: 2%;
            }
            
            .backButton {
                display: block;
                font-size: 26px;
                margin-top: calc(92vh - 90vh );
                padding-bottom: 10px;
                color: #333;
            }
            .form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
            
            .ButtonStyling {
                background-color: #ffc107;
                top: 0;
                padding: 4px 9px;
                line-height: 24px;
                color: black;
                position: relative;
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                -webkit-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
                cursor: pointer;
                overflow: hidden;
                border: none;
                border-radius: 4px;
                -webkit-box-shadow: 0 4px 12px rgb(102 103 107 / 15%);
                box-shadow: 0 4px 12px rgb(102 103 107 / 15%);
            }
            .footer_btn {
                display: flex;
                justify-content: space-between;
             }
             .form-check-input:checked {
                background-color: #ffc107;
                border-color: #ffc107;
            }
            input#defaultAddress {
                {/* border-color: orange; */}
            }
            
            @media (max-width: 575.98px) {
                .modalconatiner {
                }
            }
            
            @media (min-width: 576px) and (max-width: 767.98px) {
                .modalconatiner {
                }
            }
            
            @media (min-width: 768px) and (max-width: 991.98px) {
                .modalconatiner {
                }
            }
            
            @media (min-width: 992px) and (max-width: 1199.98px) {
                .modalconatiner {
                }
            }
            
            @media (min-width: 1200px) {
                .modalconatiner {
                }
            }
            
            @media (max-width: 800px) {
                .AdjustingForm input {
                    margin: 10px 0;
                }
            
                .AdjustingForm {
                    flex-direction: column;
                    align-items: stretch;
                }
            }
           
           
  
            `
            }
        </style>
    )
}

export default ViewAddressModelStyle
