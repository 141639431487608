import React from 'react'
import baseComponent from "../../shared/baseComponent"
import { checkParameters } from "../../utils/Encryption";
import { Navigate } from 'react-router-dom';
import UserActivityService from '../../services/domainServices/UserActivityService';
import Button from '../../components/common/Button';
import { ButtonTypes } from '../../utils/Constants';

export default class ActivityDetails extends baseComponent {
  constructor(props) {
    super(props)
    this.userActivityService = new UserActivityService();
    this.state = {
      getActivityDetails: this.userActivityService.dataItem,
      pagination: this.userActivityService.pagination,
      userActivity: [],
      stockIndex: "",
      setIsLoad: false,
      data: "",
      userDisplayName: "",
      userName: "",
      ipAddress: "",
      action: "",
      ActiveUserId: 0
    }
    this.userActivityService.onUpdateState = this.updateState.bind(this);
  }
  componentDidMount() {
    this.userActivityService.onUserActivityLogs(this.state.pagination)
    let params = new URLSearchParams(window.location.search);
    let logId = params.get("");
    if (logId && (logId = checkParameters(logId, "Number"))) {
      if (logId > 0) {
        this.userActivityService.ongetUserActivityLogById(logId);
        this.setState({ ActiveUserId: logId });
      }
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto">
            <div className='flex items-center justify-between mb-5'>
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">User Activity Details </h1>
              </div>
              <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.userActivityService.onRedirectoList()} />
            </div>
            <div className="col-span-full xl:col-span-8 bg-white shadow-lg border-radius-10 border border-slate-200 mt-5 activity_details">
              <div className="p-5">
                <div className="">
                  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6'>
                    <div className='flex p-6 gap-2 useractivitydetails'>
                      <label>User Name : </label>
                      {this.state.userActivity.userDisplayName}
                    </div>
                    <div className='flex p-6 gap-2 useractivitydetails'>
                      <label>Email Address : </label>
                      {this.state.userActivity.userName}
                    </div>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4'>
                    <div className='flex p-6 gap-2 useractivitydetails'>
                      <label>IP Address : </label>
                      {this.state.userActivity.ipAddress}
                    </div>
                    <div className='flex p-6 gap-2 useractivitydetails'>
                      <label>Action : </label>
                      {this.state.userActivity.action}
                    </div>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-0 lg:grid-cols-1 gap-6 mt-4'>
                    <div>
                      {this.state.userActivity.data != null ?
                        <div className='d-flex p-6 gap-3 useractivitydetails'>
                          <label>Request Data : </label>
                          <span>{this.state.userActivity.data}</span>
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
