import { DateFromat } from "../../utils/DateFromat";
import BaseDomainService from "./baseDomainServices";
import { AddressType } from '../../utils/enums/AddressTypeEnum';
import { ErrorMessage, SuccessMessage } from '../../utils/Messages';
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";

const searchHistoryList = {
    searchText: ""
}

const addUserDetails = {
    redirect: '',
    userId: 0,
    userName: "",
    isActive: false,
    firstName: "",
    lastName: "",
    phoneNo: "",
    designation: "",
    companyName: "",
    emailAddress: "",
    userLastLoginTime: "",
    countryId: ""
}

export default class UserService extends BaseDomainService {
    getUsersList = this.apiService.manageUserServices.admin_GetUserList;
    getUserDashboardList = this.apiService.manageUserServices.admin_GetUsersDashboardCounts;
    getUsersDetailsList = this.apiService.searchHistoryServices.admin_GetUserSearchHistory;
    getOrderDetailsList = this.apiService.manageOrderSevices.admin_GetOrderDetailsById;
    getrfqDetails = this.apiService.rfqService.admin_GetUserRFQDetails;
    getUserProfileById = this.apiService.manageUserServices.getUserDetailsByUserId;
    getShoppingCartProductsByUser = this.apiService.shoppingCartServices.admin_GetShoppingCartProductsByUser;
    getAddress = this.apiService.shoppingCartServices.getaddresses;
    getCountries = this.apiService.profileServices.getAllCountries;
    update = this.apiService.manageUserServices.updateUserDetails;
    resetlink = this.apiService.manageUserServices.sendForgetPasswordLink;
    getUserActivityLogByUserId = this.apiService.activityService.getUserActivityLogByUserId;
    userLogin = this.apiService.manageUserServices.addUserLogin;

    RFQgridColmns = [
        { name: "rfqNumber", title: "RFQ Number" },
        { name: "productCatalog", title: " Catalog" },
        { name: "dateOfRFQ", title: " Date" },
        { name: "rfqId", title: " Action" },
    ]
    UserActivitygridColmns = [
        { name: "orderNumber", title: "Dummy" },
        { name: "OrderDate", title: "  Dummy " },
        { name: "PurchaseOrder", title: "  Dummy" },
        { name: "orderId", title: "Action " },
    ]
    OrdergridColmns = [
        { name: "orderNumber", title: "Order" },
        { name: "OrderDate", title: "  Order Date" },
        { name: "PurchaseOrder", title: " Purchase Order" },
        { name: "refNumber", title: "Ref No " },
        { name: "units", title: "Units " },
        { name: "TotalPrice", title: "Total Price " },
        { name: "orderId", title: "Action " },
    ]
    SearchHistorygridColmns = [
        { name: "searchType", title: "Search Types" },
        { name: "UserName", title: " Email Address " },
        { name: "SearchString", title: " Search String  " },
        { name: "UserIpAddress", title: "User Ip Address " },
        { name: "SearchDate", title: "Search Date   " },
    ]
    AdminListgridColmns = [
        { name: "userName", title: "Email Address" },
        { name: "userActivationDate", title: "  User Activation Date " },
        { name: "userLastLoginTime", title: "User Last Login Time" },
    ]
    gridColmnsDetails = [
        { name: 'action', title: 'Action' },
        { name: 'timestamp', title: 'Date & Time' },
        { name: 'ipAddress', title: 'Ip Address' },
    ];
    UserListgridColmns = [
        { name: "userDisplayName", title: " Full Name " },
        { name: "userName", title: "  Email Address " },
        { name: "companyName", title: " Company Name " },
        { name: "countryname", title: " Country Name " },
        { name: "createddate", title: " Created Date " },
        { name: "phoneno", title: " Phone No " },
        { name: "userlastlogintime", title: " User Last Login Time " },
        { name: "isactive", title: " Active " },
        { name: "userId", title: " Action " },
    ]
    UserDashListgridColmns = [
        { name: "userName", title: "  Email Address " },
        { name: "companyName", title: " Company Name " },
        { name: "userLoggedInCounts", title: "User Logged In Counts" },
    ]


    formFields = [
        {
            inputType: "text",
            tittle: "First Name",
            placeholder: "First Name",
            fieldName: "firstName",
            className: 'col-md-6',
            maxLength: 50,
            validation: [{ type: "require" }],
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            }
        },
        {
            inputType: "text",
            tittle: "Last Name",
            placeholder: "Last Name",
            fieldName: "lastName",
            className: 'col-md-6',
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Phone No.",
            placeholder: "Phone No.",
            fieldName: "phoneNo",
            className: 'col-md-6',
            minLength: 7,
            maxLength: 15,
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Email Address",
            placeholder: "Email Address",
            fieldName: "userName",
            className: 'col-md-6',
            maxLength: 200,
            disabled: true,
            defaultValue: true,
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Company Name",
            placeholder: "Company Name",
            fieldName: "companyName",
            className: 'col-md-6',
            maxLength: 100,
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Designation",
            placeholder: "Designation",
            fieldName: "designation",
            className: 'col-md-6',
            maxLength: 100,
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Country",
            placeholder: "Select Country",
            fieldName: "countryId",
            isDefault: true,
            options: [],
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Last Login",
            placeholder: "Last Login",
            fieldName: "userLastLoginTime",
            className: 'col-md-6',
            disabled: true,
            defaultValue: true,
            validation: [{ type: "require" }]
        },
        {
            inputType: "checkbox",
            tittle: "isActive",
            placeholder: "isActive",
            fieldName: "isActive",
            className: 'col-md-6 mt-3',
            checked: true,
            usercheked: "yes",
            checkLabelClass: 'ml-2',
            validation: [{ type: "require" }]
        },
    ]
    formFieldsCheck = [

        {
            inputType: "checkbox",
            tittle: "isActive",
            placeholder: "isActive",
            fieldName: "isActive",
            className: 'col-md-6 mt-3',
            checked: true,
            usercheked: "yes",
            checkLabelClass: 'ml-2',
            validation: [{ type: "require" }]
        },
    ]

    // Define the state variables
    dataItem;
    SearchformFields = [
        {
            inputType: "text",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.onPaginationUpdate = this.onGetUserList.bind(this);
        this.actionKey = "rfqId";
        this.dataItem = { ...searchHistoryList };

    }

    //** Service Event */
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    //** API Calls

    onGetUserList = (requst) => {
        this.apiService.onAPICall("userslist", this.getUsersList, requst, this.onAPIResponse, this.onUpdateState);
    };
    ongetUserDashboardList = (requst) => {
        this.apiService.onAPICall("getUserDashboardList", this.getUserDashboardList, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetSearchList = (requst) => {
        this.apiService.onAPICall("usersDetails", this.getUsersDetailsList, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetOrderDetails = (requst) => {
        this.apiService.onAPICall("orderDetails", this.getOrderDetailsList, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetrfqDetails = (requst) => {
        this.apiService.onAPICall("getrfqDetails", this.getrfqDetails, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetUserProfileById = (requst) => {
        this.apiService.onAPICall("getUserProfileById", this.getUserProfileById, requst, this.onAPIResponse, this.onUpdateState);
    };
    ongetUserActivityLogByUserId = (requst) => {
        this.apiService.onAPICall("getUserActivityLogByUserId", this.getUserActivityLogByUserId, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetShoppingCartProductsByUser = (requst) => {

        this.apiService.onAPICall("getShoppingCartProductsByUser", this.getShoppingCartProductsByUser, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetAddress = (requst) => {
        this.apiService.onAPICall("getAddress", this.getAddress, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetCountries = (requst) => {
        this.apiService.onAPICall("getCountries", this.getCountries, requst, this.onAPIResponse, this.onUpdateState);
    };
    onUpdate = (requst) => {
        this.apiService.onAPICall("update", this.update, requst, this.onAPIResponse, this.onUpdateState);
    };
    onResetLink = (requst) => {
        this.apiService.onAPICall("resetlink", this.resetlink, requst, this.onAPIResponse, this.onUpdateState);
    };
    onAddUserLogin = (requst) => {
        this.apiService.onAPICall("addUserLogin", this.userLogin, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetUserLoginDetails = (requst) => {
        this.apiService.onAPICall("userLoginDetails", this.userLoginDetails, requst, this.onAPIResponse, this.onUpdateState);
    };


    onRedirectoList = () => {
        this.onRedirect("/UserList");
    }
    onRedirectoWebLogin = (webURL) => {
        this.onRedirect(webURL);
    }

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "userslist":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DateFromat(i.createdDate) : "-";
                        i.userLastLoginTime = i.userLastLoginTime ? DateFromat(i.userLastLoginTime) : "-";
                    });
                }
                let userActiveList = [];
                for (let i = 0; i < responseData.itemList.length; i++) {
                    if (responseData.itemList[i].isActive === true) {
                        userActiveList.push({
                            isActive: responseData.itemList[i].isActive,
                        });
                    }
                }
                this.onUpdateState({
                    "getusers": responseData.itemList,
                    "TotalCount": responseData.totalCount,
                    "userActiveList": userActiveList,
                    "setIsLoad": false
                });
                break;
            case "getUserActivityLogByUserId":

                let itemLists = responseData;
                itemLists.forEach(function (i) {
                    i.timestamp = i.timestamp ? DateFromat(i.timestamp) : "-";
                });
                this.onUpdateState({
                    dataListData: responseData,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false,
                });

                break;
            case "usersDetails":

                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.searchDate = DateFromat(i.searchDate);
                    });
                }
                this.onUpdateState({
                    getUserSearch: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });
                break;
            case "getUserDashboardList":
                if (responseData !== null) {

                let ActiveCount = responseData[0]
                let InActiveUsers = responseData[0]
                let FrequentlyLoggedUsers = responseData[0]
                this.onUpdateState({
                    UserList: responseData,
                    UserCount: ActiveCount,
                    UserInActiveCount: InActiveUsers,
                    LoggedUserCount: FrequentlyLoggedUsers,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });}
                this.onUpdateState({
                    setIsLoad: false
                })

                break;
            case "orderDetails":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.orderDate = DateFromat(i.orderDate);
                        i.refNumber = i.refNumber ? i.refNumber : "-"
                        i.purchaseOrder = i.purchaseOrder ? i.purchaseOrder : "-"
                        i.totalPrice = i.totalPrice ? " $ " + i.totalPrice : "-"
                    });
                }
                this.onUpdateState({
                    getOrderSearch: responseData.itemList,
                    totalCount: responseData.totalCount,
                    isLoading: false,
                });
                break;
            case "getrfqDetails":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.dateOfRFQ = DateFromat(i.dateOfRFQ);
                    });
                }
                this.onUpdateState({
                    getRFQSearch: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });
                break;
            case "getUserProfileById":

                if (responseData !== null) {
                    let userDet = ({ ...responseData });
                    this.dataItem = { ...userDet };

                    this.onUpdateState({
                        addUserDetails: {
                            userName: userDet.userName,
                            isActive: userDet.isActive,
                            firstName: userDet.firstName,
                            lastName: userDet.lastName,
                            phoneNo: userDet.phoneNo,
                            designation: userDet.designation,
                            userId: userDet.userId,
                            companyName: userDet.companyName,
                            userDisplayName: userDet.userDisplayName,
                            countryId: userDet.countryId,
                            userLastLoginTime: userDet.userLastLoginTime ? DateFromat(userDet.userLastLoginTime) : "-"
                        }
                    })
                }
                break;
            case "getShoppingCartProductsByUser":

                if (responseData !== null) {
                    let getshoppingcart = responseData
                    let cartTotal = 0;
                    getshoppingcart.forEach(value => {
                        value.productPrice = (value.quantityAmount) * (value.price);
                        value.selected = true;
                        cartTotal = cartTotal + value.productPrice;
                    });
                    this.onUpdateState({
                        getshoppingcart: getshoppingcart,
                        totalPrice: cartTotal,
                        isAllSelected: true
                    });
                }
                break;
            case "getAddress":
                if (responseData !== null) {
                    let filteredShippingAddresses = responseData.itemList.filter(a => a.addressTypeId === AddressType.ShippingAddress);
                    let filteredBillingAddresses = responseData.itemList.filter(a => a.addressTypeId === AddressType.BillingAddress);
                    this.onUpdateState({
                        shippingAddresses: filteredShippingAddresses,
                        billingAddresses: filteredBillingAddresses,
                        address: responseData.itemList
                    });
                }
                break;
            case "getCountries":
                if (responseData !== null) {
                    let allCountrieData = responseData
                    let newDataList = allCountrieData.map((data) => ({
                        id: data.countryId,
                        name: data.countryName,
                        countryCode: data.countryCode,
                        phoneCode: data.phoneCode,
                        shortingCode: data.shortingCode

                    }));
                    this.onUpdateState({ allCountries: newDataList });
                }
                break;
            case "resetlink":
                this.onUpdateState({
                    setIsLoad: false
                });
                this.apiService.serviceManager.swalServices.Success("Reset password link send successfully");
                break;
            case "update":
                if (responseData.errorType == "EXISTS" && responseData.hasError == true) {
                    this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "User"));
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "This User"));
                    this.onRedirectoList();
                }
                break;
            case "addUserLogin":
                if (responseData !== null) {
                    this.onUpdateState({
                        "userLoginDetail": responseData,
                        "setIsLoad": false
                    });
                    window.open(`${responseData.webURL}`, '_blank');
                    // this.onGetUserLoginDetails(responseData.token)
                }
                break;
        }
    };
}

