import React, { useState, useEffect } from 'react';
import UnAuthorized from '../../pages/authentication/UnAuthorized';
import { IsAuthorizeUser } from '../../utils/AuthenticationLibrary';
import Loader from '../../components/common/Loader';

const AuthorizationWrap = ({ authorizeUserType, children }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);  // Added a loading state

    useEffect(() => {
        const checkAuthorization = async () => {
            setIsLoading(true);  // Set loading to true at the start of the effect
            const authorized = await IsAuthorizeUser(authorizeUserType);
            setIsAuthorized(authorized);
            setIsLoading(false);  // Set loading to false once the check is complete
        };

        checkAuthorization();
    }, [authorizeUserType]);

    if (isLoading) {
        return <div>
             <Loader />

        </div>;
    }

    return (
        <>
            {isAuthorized ? children : <UnAuthorized />}
        </>
    );
}

export default AuthorizationWrap;
