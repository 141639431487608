/* Component  */
import { axiosPost, axiosGet } from '../AxiosRequests';
import { Admin_ResetPassword, Admin_GetAdminUsersById, Admin_UpdateAdminUsersById,GetAllCountries } from '../ApiEndPoints';

export default class ProfileServices {
// API function

    async admin_ResetPassword(request) {
        return axiosPost(Admin_ResetPassword, request);
    }

    async admin_GetAdminUsersById(request) {
        return axiosGet(Admin_GetAdminUsersById, request);
    }

    async admin_UpdateAdminUsersById(request) {
        return axiosPost(Admin_UpdateAdminUsersById, request);
    }
    async getAllCountries(request){
        return axiosGet(GetAllCountries,request)
    }  
}
