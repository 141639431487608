import baseDomainServices from "./baseDomainServices";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { setAuthProps } from '../../utils/AuthenticationLibrary';
import { ErrorMessage } from '../../utils/Messages';
import { createCookie } from "../../utils/cookies/CookieHandler";

const userDetails = {
    userName: '',
    password: ''
}

export default class authenticationServices extends baseDomainServices {

    add = this.apiService.authServices.adminLogin;
    adminValidateToken = this.apiService.authServices.validateToken;
    authentication = this.apiService.authServices.projectAuthentication;
    getUserDetailsByClientIdAndSecretkey = this.apiService.authServices.getUserDetailsByClientIdAndSecretkey;

    constructor() {
        super();
        this.dataItem = { ...userDetails };
    }
    //** Service Event */

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;


        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })

    }
    //  API Calls

    onAdd = (request) => {
        this.apiService.onAPICall("login", this.add, request, this.onAPIResponse, this.onUpdateState);
    }

    onValidateToken = (request) => {
        this.apiService.onAPICall("validateToken", this.adminValidateToken, request, this.onAPIResponse, this.onUpdateState);
    }
    onProjectAuthentication = (request, userData) => {
        this.apiService.onAPICall("projectAuthentication", this.authentication, request, this.onAPIResponse, this.onUpdateState, userData);
    }
    onGetUserDetailsByClientIdAndSecretkey = (request) => {
        this.apiService.onAPICall("getUserDetailsByClientIdAndSecretkey", this.getUserDetailsByClientIdAndSecretkey, request, this.onAPIResponse, this.onUpdateState);
    }


    dataItem;

    formFields = [
        {
            inputType: "text",
            tittle: "Email Address",
            fieldName: "userName",
            validation: [{ type: "require" }]
        },
        {
            inputType: "password",
            tittle: "Password",
            fieldName: "password",
            validation: [{ type: "require" }, { type: "password", message: "PasswordNotValid" }]
        },
    ]

    onAPIResponse = (key, iSuccess, responseData, getRequest) => {
        if (!iSuccess) {
            if (responseData.statusCode === 401) {
                this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidAuthorization);
                this.onUpdateState({ setIsLoad: false });
            } else {
                this.onUpdateState({ setIsLoad: false });
                return;
            }
        }
        switch (key) {
            case "login":
                if (responseData.isAuthenticated === true) {
                    let cookieRequest = {
                        cookieValue: responseData.token,
                        cookieName: '__ssoAuth',
                        expirationTime: responseData.sessionTimeout
                    }
                    createCookie(cookieRequest);
                    let req = {
                        Userid: responseData.user.userId
                    }
                    let request = {
                        ...responseData,
                        userName: responseData.user.userName,
                        adminUserId: responseData.user.userId,
                        userDisplayName: responseData.user.firstName + ' ' + responseData.user.lastName,
                    }
                    // this.onUpdateState({ redirect: "/" });
                    this.onProjectAuthentication(req, request);
                } else if (responseData.isAuthenticated === false) {
                    this.apiService.serviceManager.swalServices.Error(responseData.message);
                }
                else {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidAuthorization);
                }
                this.onUpdateState({ setIsLoad: false });
                break;
            case "validateToken":
                if (responseData.isAuthenticated === true) {
                    let request = {
                        ...responseData,
                        userName: responseData.user.userName,
                        adminUserId: responseData.user.userId,
                        userDisplayName: responseData.user.firstName + ' ' + responseData.user.lastName,
                    }
                    let req = {
                        Userid: responseData.user.userId
                    }
                    this.onProjectAuthentication(req, request);

                    let cookieRequest = {
                        cookieValue: responseData.token,
                        cookieName: '__ssoAuth',
                        expirationTime: responseData.sessionTimeout
                    }
                    createCookie(cookieRequest);

                    // setAuthProps(request);
                    // this.onUpdateState({ redirect: "/" });
                    // this.apiService.serviceManager.swalServices.Toaster(responseData.message);

                } else if (responseData.isAuthenticated === false) {
                    this.apiService.serviceManager.swalServices.Error(responseData.message);
                }
                else {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidAuthorization);
                }
                this.onUpdateState({ setIsLoad: false });
                break;
            case "projectAuthentication":
                if (responseData) {
                    let req = {
                        adminUserId: getRequest.adminUserId,
                        isAuthenticated: getRequest.isAuthenticated,
                        sessionTimeout: getRequest.sessionTimeout,
                        userDisplayName: getRequest.userDisplayName,
                        userName: getRequest.userName,
                        token: responseData.token,
                        roles: getRequest.roles
                    }
                    setAuthProps(req);
                    if (req.isAuthenticated === true) {
                        this.onGetUserDetailsByClientIdAndSecretkey()
                    }
                    this.onUpdateState({ redirect: "/" });
                }
                this.onUpdateState({ setIsLoad: false });
                break;
            case "getUserDetailsByClientIdAndSecretkey":
                if (responseData) {
                    let req = {
                        cookieValue: responseData,
                        cookieName: 'userDetails',
                        expirationTime: 60 * 24
                    }
                    createCookie(req);
                }
                break;
            default:
                break;

        }
    }
}
