import React from 'react'
import { Bars } from 'react-loader-spinner';
import FormFieldGroup from '../../components/formGroupFields/FormFieldGroup';
import ExceptionServices from '../../services/domainServices/exceptionService';
import BaseComponent from "../../shared/baseComponent";
import Table from '../../components/tables/Table';
import { encryptAES } from "../../utils/Encryption";
import { ErrorMessage } from '../../utils/Messages';
export default class Exception extends BaseComponent {
    constructor(props) {
        super(props)
        this.exceptionServices = new ExceptionServices();
        let newPagination = { ...this.exceptionServices.pagination }
        newPagination.pageSize = 25
        this.state = {
            ...this.state,
            setIsLoad: false,
            stockIndex: "",
            setOpenModal: false,
            ExceptionUpdate: this.exceptionServices.dataItem,
            searchList: this.exceptionServices.SearchdataItem,
            setCloseModal: false,
            dataList: [],
            pagination: newPagination,
            modalDeatils: {
                trackTrace: "",
                callingMethod: "",
                requestedData: "",
            }
        };
        this.exceptionServices.onUpdateState = this.updateState.bind(this);
        this.exceptionServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.exceptionServices.onExceptionList(this.state.pagination)
    }

    handleChange = (name, value) => {
        let detail = this.state.ExceptionUpdate;
        detail[name] = value;
        this.setState({ ExceptionUpdate: { ...detail } });
    }
    onActionCall = (type, data) => {
        switch (type) {

            case "View":
                window.open(`/ExceptionDetails/id?=${encryptAES(data)}`, '_blank');
                break;
            case "IPAddress":
                let det = this.state.dataList;
                let ipAddressDetails = det.find(x => x.ipAddress === data);
                if (ipAddressDetails && ipAddressDetails.ipAddressesId !== null) {
                    window.open(`/IpAddressDetails/ipAddressesId?=${encryptAES(ipAddressDetails.ipAddressesId)}`, '_blank');
                } else {
                    this.exceptionServices.apiService.swalServices.Alert(ErrorMessage.ipAddressDetailNotExists);
                }
                break;
            case "errorStatusId":
                this.setState({});
                break;
            case "TrackTrace":
                this.setState({});
                break;
        }
    };

    handleChangeEvent = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ searchList: { ...detail } });
    }
    handleChangeSearch = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        if (name === "searchText") {
            detail[name] = value.trim();
            let data = { ...this.state.pagination };
            data.pageNo = 1;
            this.exceptionServices.onExceptionList(data)
            this.setState({ pagination: { ...data } });
        }
        this.setState({ searchList: { ...detail } });
    }

    render() {
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Exception</h1>
                                </div>
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3">
                                    <div className="relative">
                                        <label htmlFor="action-search" className="sr-only">Search</label>
                                        {this.exceptionServices.formFields.map((Item, index) => {
                                            return (
                                                <FormFieldGroup className='ms-auto relative search-bar-input'
                                                    column={Item}
                                                    value={
                                                        this.state.searchList[Item.fieldName]
                                                    }
                                                    onChange={(event) =>
                                                        this.handleChangeEvent(Item.fieldName, event.target.value)
                                                    }
                                                    onKeyPress={(e) =>
                                                        e.key === "Enter" &&
                                                        this.handleChangeSearch(Item.fieldName, e.target.value)
                                                    }
                                                    key={index} />
                                            );
                                        })}
                                    </div>
                                    <button className="btnn commanColorbtn text-dark" onClick={(e) => { e.stopPropagation(); this.handleChangeSearch("searchText", this.state.pagination.searchText) }}>
                                        <span className="xs:block">Search</span>
                                    </button>
                                </div>
                            </div>
                            <div className='main-exception-list'>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <div className="">
                                            <Table
                                                columns={this.exceptionServices.gridColmns}
                                                rows={this.state.dataList}
                                                sortingColumns={[]}
                                                pagination={this.state.pagination}
                                                totalCount={this.state.TotalCount}
                                                totalResultes={this.state.TotalCount}
                                                setPagination={this.exceptionServices.onPageChange.bind(this)}
                                                customScope={[
                                                    {
                                                        column: 'errorLogId',
                                                        renderTableData: this.exceptionServices.gridActionErrorCols.bind(this)
                                                    },
                                                    {
                                                        column: 'isSolved',
                                                        renderTableData: this.exceptionServices.gridCheckBoxCol.bind(this)
                                                    },
                                                    {
                                                        column: 'IPAddress',
                                                        renderTableData: this.exceptionServices.ipAddressAction.bind(this)
                                                    },
                                                ]}
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>)
    }
}
