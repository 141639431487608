
/* Component  */
import React from 'react';
import { Navigate } from 'react-router-dom';
import Button from '../../components/common/Button';
import BaseComponent from '../../shared/baseComponent';

/* Library  */
import { Bars } from 'react-loader-spinner';
import { ButtonTypes } from '../../utils/Constants';
import { checkParameters } from "../../utils/Encryption";
import FormFieldGroup from "../../components/formGroupFields/FormFieldGroup";

/* Services  */
import ContactUsServices from '../../services/domainServices/contactUsServices';


export default class ContactUs extends BaseComponent {
    /* Constructor and Component method */
    constructor(props) {
        super(props)
        this.contactUsServices = new ContactUsServices();
        this.state = {
            getContactDetails: this.contactUsServices.dataItem,
        }
        this.contactUsServices.onUpdateState = this.updateState.bind(this);

    }
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let contactUsId = params.get("");
        if (contactUsId && (contactUsId = checkParameters(contactUsId, "Number"))) {
            if (contactUsId > 0) {
                this.contactUsServices.onGetById(contactUsId);
            }
        }
    }

    render(props) {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <>
                <div className="pr-2 sm:pr-3 lg:pr-4 py-8 pt-4 w-full max-w-9xl mx-auto">

                    <div className='flex items-center justify-between mb-4'>
                        <div className="mb-4 sm:mb-0">
                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Contact Details</h1>
                        </div>
                        <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.contactUsServices.redirectToListPage()} />
                    </div>
                    <div className="w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200">

                        <div className="px-4 sm:px-6 lg:px-8  w-full max-w-9xl mx-auto sm:items-center">
                            <div className="mt-5">
                                {(this.state.setIsLoad) ?
                                    <Bars
                                        className="row"
                                        wrapperStyle={{ margin: '60px 0' }}
                                        color="#3c4b64"
                                        height={55}
                                        width="100%"
                                        visible={this.state.setIsLoad} />
                                    :
                                    <>
                                        <div className='sm:grid sm:gap-4 sm:grid-cols-2'>
                                            {this.contactUsServices.contactusForm.map((Item, index) => {
                                                return (
                                                    <FormFieldGroup
                                                        column={Item}
                                                        value={this.state.getContactDetails[Item.fieldName]}
                                                        checked={this.state.getContactDetails[Item.fieldName]} />
                                                )
                                            })}
                                        </div>
                                    </>}
                            </div>
                        </div>

                    </div>

                </div>


            </>
        )
    }
}

