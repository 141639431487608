/* eslint-disable default-case */
import BaseDomainService from "./baseDomainServices";
import { ErrorMessage, SuccessMessage } from '../../utils/Messages';
import { getValidationRuleList } from '../../utils/validation/generateValidationRule';

const product = {
    stockLocationId: "",
    stockAmount: ""
};

export default class StockDomainService extends BaseDomainService {
    
    deleteStockById = this.apiService.productService.deleteStockById;
    admin_AddStockById = this.apiService.productService.admin_AddStockById;
    getAllStockLocation = this.apiService.productService.getAllStockLocation;
    getStockDetailById = this.apiService.productService.admin_GetStockAvailibilityByProductId;
    adminUpdateProductStockById = this.apiService.productService.admin_UpdateProductStockById;

    Stocktab = [
        { name: "name", title: "Stock" },
        { name: "stockAmount", title: " Stock Amount" },
        { name: "stockAvailibiltyId", title: " Action" },
    ];

    productStockForm = [
        {
            inputType: "select",
            tittle: "Stock",
            placeholder: "Select Stock Location",
            fieldName: "stockLocationId",
            options: [],
            optionFieldName: "stockLocation",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Stock Amount",
            placeholder: "Stock Amount",
            fieldName: "stockAmount",
            className: 'w-full',
            validation: [{ type: "require" }],
        },
    ]

    dataItem;
    constructor() {
        super();
        this.actionKey = "stockAvailibiltyId";
        this.onPaginationUpdate = this.onGetStockDetailsById.bind(this);
        this.dataItem = { ...product };
    }
    onAdmin_AddStockById = (requst, getRequest) => {
        this.apiService.onAPICall("admin_AddStockById", this.admin_AddStockById, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onGetStockDetailsById = (requst) => {
        this.apiService.onAPICall("getStockDetailById", this.getStockDetailById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllStockLocation = (requst) => {
        this.apiService.onAPICall("getAllStockLocation", this.getAllStockLocation, requst, this.onAPIResponse, this.onUpdateState);
    };
    onAdminUpdateProductStockById = (requst, getRequest) => {
        this.apiService.onAPICall("adminUpdateProductStockById", this.adminUpdateProductStockById, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onDeleteStockById = (requst, getRequest) => {
        this.apiService.onAPICall("deleteStockById", this.deleteStockById, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onProductStockForm() {
        let valRule = getValidationRuleList(this.productStockForm)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }
    onResetData = () => {
        this.dataItem = { ...product }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            updateStock: this.dataItem,
            validState: resetValidation
        });
    }

    onAPIResponse = (key, isSuccess, responseData, getRequest) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "admin_AddStockById":
                if (responseData.errorType === "EXIST" && responseData.errorColumn === "Stock") {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "Stock"));
                   
                }
                else{
                    
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Product Stock"));
                    this.onUpdateState({
                        "setOpenModal": false,
                        "pageLoader": false,
                        "setIsLoad": false,
                    });
                }
                this.onGetStockDetailsById(getRequest)
                this.onResetData()
              
                break;
            case "deleteStockById":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Product Stock"));
                this.onUpdateState({
                    "setOpenModal": false,
                    "pageLoader": true,
                    "setIsLoad": false,
                });
                this.onGetStockDetailsById(getRequest)
                break;
            case "getStockDetailById":
                let stockDataById = responseData
                let stockDataList = stockDataById.map((data) => ({
                    productId: data.productId,
                    stockAmount: data.stockAmount,
                    stockAvailibiltyId: data.stockAvailibiltyId,
                    id: data.stockLocationId,
                    name: data.stockLocation,
                    stockLocationId: data.stockLocationId
                }));
                this.onUpdateState({
                    StockDetails: stockDataList,
                    setIsLoad: false
                });
                break;
            case "getAllStockLocation":
                if (responseData !== null) {
                    let stockData = responseData;
                    let newStockDataList = stockData?.map((data) => ({
                        id: data.stockLocationId,
                        name: data.stockLocation,
                    }));
                    this.onUpdateState({
                        stockLocation: newStockDataList,
                        "StockDetails": { ...product }
                    });
                }
                break;
            case "adminUpdateProductStockById":
                 if (responseData.errorType === "EXISTS" && responseData.errorColumn === "Stock") {
                     this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "Stock"));
                    }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Product Stock"));
                    this.onUpdateState({
                        "setOpenModal": false,
                        "pageLoader": false,
                        "setOpenModal": false,
                    });
                }
                this.onGetStockDetailsById(getRequest)
               
                break;
        }
    }
}

