
/* Component  */
import React, { Component } from 'react';
import Table from '../../components/tables/Table';
import Button from '../../components/common/Button';

/* Library  */
import moment from 'moment';
import { Navigate } from 'react-router';
import { Bars } from 'react-loader-spinner';
import { ButtonTypes } from '../../utils/Constants';

/* Services  */
import SearchHistoryServices from '../../services/axiosServices/apiServices/SearchHistoryServices';

export default class SearchHistorylist extends Component {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.getUserSearchHistoryServices = new SearchHistoryServices();
    this.state = {
      getUserSearch: [],
      setIsLoad: false,
      totalResultes: '',
      searchingText: "",
      isLoading: false,
      setuserId: '',
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "SearchDate",
        orderFlag: 1,
        searchText: "",
      },
      redirect: null,
    }
  }

  /* Event function */
  handlePageChange = (pageNumber) => {
    if (this.state.pagination.pageNo !== pageNumber) {
      this.state.pagination.pageNo = pageNumber;
      this.getUserSearchHistory(this.state.pagination);
    }
  };

  handleChangeEvent = (value, userId) => {
    this.setState({ searchingText: value })
    this.handleSearching(value)
    if (value === '') {
      this.getUserSearchHistory(this.state.setuserId);
    }
  }

  handleSearching = (searchQuery) => {
    if (searchQuery.trim() !== "") {
      this.GetSearch(searchQuery, this.state.setuserId);
    }
    else if (searchQuery.trim() === "") {
      this.getUserSearchHistory(this.state.setuserId);
    }
  }


  // Other function 


  setPagination = (newPagination, userId) => {
    this.setState({ pagination: newPagination });
    this.getUserSearchHistory(this.state.setuserId);
  }



  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let userId = params.get("");
    this.getUserSearchHistory(userId);
    this.setState({ setuserId: userId })
    document.title = 'Search History List';
  }

  back = () => {
    this.setState({ redirect: "/UserList" });
  }


  // API function 

  getUserSearchHistory = (userId) => {
    let request = {
      pageNo: 1,
      pageSize: 10,
      orderByColumn: "SearchDate",
      orderFlag: 1,
      searchText: "",
      userId: userId
    }
    this.getUserSearchHistoryServices.admin_GetUserSearchHistory(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getUserSearch: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
        const itemList = response.responseItem.responseContent.itemList
        itemList.forEach(function (i) {
          i.searchDate = i.searchDate ? moment(i.searchDate).format('DD-MM-YYYY ') : '-'           //h:mm a  .... for time format
        })
      }
      else {
        this.swalServices.Toaster(response.message);
      }
    })
  }

  GetSearch = (searching, userId) => {
    let request = {
      pageNo: 1,
      pageSize: 10,
      orderByColumn: "CreatedDate",
      orderFlag: 1,
      searchText: searching,
      userId: userId
    }
    this.getUserSearchHistoryServices.admin_GetUserSearchHistory(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getUserSearch: response.responseItem.responseContent.itemList });
      }
      else {
        this.swalServices.Toaster(response.message);
      }
    })
  }


  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-8 w-full max-w-9xl mx-auto">
              <Button type={ButtonTypes.Cancel} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.back()} />
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Search History </h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                  <div className="relative">
                    <label htmlFor="action-search" className="sr-only">Search</label>
                    <input id="action-search" className="form-input pl-9 focus:border-slate-300" type="search" placeholder="Search..."
                      value={this.state.searchingText}
                      onChange={(e) => this.handleChangeEvent(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && this.handleSearching(this.state.searchingText)} />
                    <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search"  >
                      <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                      </svg>
                    </button>
                  </div>
                  <button className="btn commanColorbtn" onClick={(e) => { e.stopPropagation(); this.handleSearching(this.state.searchingText) }}>
                    <span className="hidden xs:block ml-2">Search</span>
                  </button>
                </div>
              </div>
              {
                (this.state.setIsLoad) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad}
                  />
                  :
                  <Table
                    columns={[{ name: 'searchType', title: 'Search Type' }, { name: 'searchString', title: 'Search String' }, { name: 'userIPAddress', title: 'User IP Address' }, { name: 'searchDate', title: 'Search Date' }]}
                    rows={this.state.getUserSearch}
                    sortingColumns={["searchType", "searchString", "userIPAddress", "searchDate"]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                  />
              }
            </div>
          </main>
        </div>
      </div>
    )
  }

}