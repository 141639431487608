/* Component  */
import React from 'react';
import { Navigate } from 'react-router-dom';
import BaseComponent from '../../shared/baseComponent';
import Date_Range_Picker from '../../components/DateRange';
import DashboardCard from '../../components/dashboard/DashboardCard';

/* Library  */
import moment from 'moment';
import Table from '../../components/tables/Table';
import { encryptAES } from "../../utils/Encryption";

/* Services  */
import dashboardServices from '../../services/domainServices/dashboardServices';
import { DateFromat } from '../../utils/DateFromat';


export default class Dashboards extends BaseComponent {
    /* Constructor and Component method */
    constructor(props) {
        super(props)
        const today = moment();
        this.dashboardServices = new dashboardServices();
        this.state = {
            dataList: [],
            redirect: '',
            totalOrders: '',
            totalEmailLogs: '',
            totalRFQs: '',
            totalUsers: '',
            startDate: '',
            recentsOrders: [],
            recentContactUs: [],
            setIsLoad: false,
            changeDateValue: {
                startDate: "",
                endDate: ""
            }
        }
        this.dashboardServices.onUpdateState = this.updateState.bind(this);
        this.dashboardServices.onActionCall = this.onActionCall.bind(this);
    }
    componentDidMount() {

        let today = new Date()
        let currentDate = DateFromat(Date());
        let pastdate = today.setDate(today.getDate() - 7);
        let pDate = DateFromat(pastdate)
        this.setState({ start: pDate });
        this.setState({ end: currentDate });
        // this.getDashboard()
        this.dashboardServices.onGetdashboardCount();
        let request = {
            fromOrderDate: pDate,
            toOrderDate: currentDate
        }
        this.dashboardServices.onGetRecentRFQ(request);
        this.dashboardServices.onGetRecentOrder(request);
        this.dashboardServices.onGetRecentConatctUs(request);
        this.getDate()
        document.title = 'Dashboard';
    }

    //Other function 
    getDate = () => {

        let currentDate = DateFromat(Date());
        let endDate = (new Date().setDate(new Date().getDate() - 7));
        let pDte = DateFromat(endDate);
        this.setState({ startDate: currentDate });
        this.setState({ endDate: pDte });
    }
    handleLanguage = (changeValue) => {
        this.setState({ changeDateValue: changeValue });
        let request = {
            fromOrderDate: changeValue.startDate,
            toOrderDate: changeValue.endDate
        }
        this.dashboardServices.onGetRecentRFQ(request);
        this.dashboardServices.onGetRecentOrder(request);
        this.dashboardServices.onGetRecentConatctUs(request);
    }

    onActionCall = (type, data) => {
        let det = this.state.recentsOrders;
        switch (type) {
            case "userName":
                let value = det.find(x => x.userName === data);
                window.open(`/UserDetails/userId?=${encryptAES(value.userId)}`, '_blank');
                break;
            case "View":
                localStorage.setItem('RFQURL', 'RFQ');
                window.open(`/RFQDetails/RFQId?=${encryptAES(data)}`, '_blank');

                break;
            case "edit":
                localStorage.setItem('OrderUrl', 'Orders');
                window.open(`/OrderDetails/OrderId?=${encryptAES(data)}`, '_blank');

                break;
            case "editcontactus":
                localStorage.setItem('ContactUrl', 'Contact');
                window.open(`/ContactUs?=${encryptAES(data)}`, '_blank');

                break;
            case "Views":
                this.setState({});
                break;
        }
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden pad-right-20">
                    <main>
                        <div className="pt-4 w-full ">
                            <div className="grid grid-flow-col sm:auto-cols-max sm:justify-end justify-center gap-2 ">
                                <div className="relative">
                                    <div className="flex items-center dashboard-date-range">
                                        <Date_Range_Picker
                                            onSelectDate={this.handleLanguage} />
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 pb-4">
                                <DashboardCard
                                    totalOrders={this.state.totalOrders}
                                    totalShoppingCart={this.state.totalShoppingCart}
                                    totalEmailLogs={this.state.totalEmailLogs}
                                    totalRFQs={this.state.totalRFQs}
                                    totalUsers={this.state.totalUsers} />
                            </div>
                            <div className="grid grid-cols-12 gap-6">
                                <div className="border-radius-10 flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-md border-slate-200">
                                    <header className="px-2 py-2 border-b border-slate-100">
                                        <h2 className="font-semibold text-slate-800">RFQs</h2>

                                    </header>
                                    <div className="relative h-full w-full min-h-[100px]">
                                        <div className="pagination-remove main-dash-rfq-history-list">
                                            {this.state.dataList.length > 50 ? <>
                                                <Table
                                                    columns={this.dashboardServices.rfqGridColmns}
                                                    rows={this.state.dataList}
                                                    sortingColumns={'null'}
                                                    pagination={'null'}
                                                    totalCount={'null'}
                                                    totalResultes={'null'}
                                                    setPagination={'null'}
                                                    isLoading={this.state.setIsLoad}
                                                    customScope={[
                                                        {
                                                            column: "rfqId",
                                                            renderTableData: this.dashboardServices.gridActionViewCol.bind(this),
                                                        },
                                                        {
                                                            column: 'userName',
                                                            renderTableData: this.dashboardServices.UserEmailactions.bind(this)
                                                        },
                                                    ]}
                                                />
                                                <i className="fas fa-external-link Size1 cursor-pointer ..." onClick={(value) => this.dashboardServices.backToRFQListPage()}> Display All</i></>
                                                :
                                                <div className="pagination-remove main-dash-rfq-history-list">
                                                    <Table
                                                        columns={this.dashboardServices.rfqGridColmns}
                                                        rows={this.state.dataList}
                                                        sortingColumns={'null'}
                                                        pagination={'null'}
                                                        totalCount={'null'}
                                                        totalResultes={'null'}
                                                        setPagination={'null'}
                                                        isLoading={this.state.setIsLoad}
                                                        customScope={[
                                                            {
                                                                column: "rfqId",
                                                                renderTableData: this.dashboardServices.gridActionViewCol.bind(this),
                                                            },
                                                            {
                                                                column: 'userName',
                                                                renderTableData: this.dashboardServices.UserEmailactions.bind(this)
                                                            },

                                                        ]}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="border-radius-10 flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-md border-slate-200 ">
                                    <header className="px-2 py-2 border-b border-slate-100">
                                        <h2 className="font-semibold text-slate-800">Orders</h2>
                                    </header>
                                    <div className="relative h-full min-h-[100px] w-full ">
                                        {this.state.recentsOrders.length > 50 ?
                                            <div>
                                                <div className="pagination-remove main-dash-order-history-list">
                                                    {/* <div className='price-for'> */}
                                                    <Table
                                                        columns={this.dashboardServices.orderGridColmns}
                                                        rows={this.state.recentsOrders}
                                                        sortingColumns={'null'}
                                                        pagination={'null'}
                                                        totalCount={'null'}
                                                        totalResultes={'null'}
                                                        setPagination={'null'}
                                                        isLoading={this.state.setIsLoad}
                                                        customScope={[
                                                            {
                                                                column: "orderId",
                                                                renderTableData: this.dashboardServices.gridActionOrderId.bind(this),
                                                            },
                                                            {
                                                                column: "paymentType",
                                                                renderTableData:
                                                                    this.dashboardServices.gridActionViewColpayment.bind(this),
                                                            },
                                                            {
                                                                column: 'userName',
                                                                renderTableData: this.dashboardServices.UserEmailactions.bind(this)
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <i className="fas fa-external-link Size1 cursor-pointer ..." onClick={(value) => this.dashboardServices.backToOrderListPage()}> Display All</i>
                                                {/* </div> */}
                                            </div>
                                            :
                                            <div className='Price-forr'>
                                                <div className="pagination-remove main-dash-order-history-list">
                                                    <Table
                                                        columns={this.dashboardServices.orderGridColmns}
                                                        rows={this.state.recentsOrders}
                                                        sortingColumns={'null'}
                                                        pagination={'null'}
                                                        totalCount={'null'}
                                                        totalResultes={'null'}
                                                        setPagination={'null'}
                                                        isLoading={this.state.setIsLoad}
                                                        customScope={[
                                                            {
                                                                column: "orderId",
                                                                renderTableData: this.dashboardServices.gridActionOrderId.bind(this),
                                                            },
                                                            {
                                                                column: "paymentType",
                                                                renderTableData:
                                                                    this.dashboardServices.gridActionViewColpayment.bind(this),
                                                            },
                                                            {
                                                                column: 'userName',
                                                                renderTableData: this.dashboardServices.UserEmailactions.bind(this)
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="border-radius-10 flex flex-col col-span-full sm:col-span-12 xl:col-span-12 bg-white shadow-lg rounded-md border-slate-200">
                                    <header className="px-2 py-2 border-b border-slate-100">
                                        <h2 className="font-semibold text-slate-800">Contact Us</h2>
                                    </header>
                                    <div className="relative h-full min-h-[100px] w-full ">
                                        {this.state.recentContactUs.length > 50 ?
                                            <div className="pagination-remove main-dash-contact-us-list">
                                                <Table
                                                    columns={this.dashboardServices.contactUsGridColmns}
                                                    rows={this.state.recentContactUs}
                                                    sortingColumns={'null'}
                                                    pagination={'null'}
                                                    totalCount={'null'}
                                                    totalResultes={'null'}
                                                    setPagination={'null'}
                                                    isLoading={this.state.setIsLoad}
                                                    customScope={[
                                                        {
                                                            column: "contactUsId",
                                                            renderTableData: this.dashboardServices.gridSctionContactUsId.bind(this),
                                                        },
                                                    ]}
                                                />
                                                <i className="fas fa-external-link Size1 cursor-pointer my-5 ..." onClick={(value) => this.dashboardServices.backToContactListPage()}> Display All</i>
                                            </div>
                                            :
                                            <div className="pagination-remove main-dash-contact-us-list">

                                                <Table
                                                    columns={this.dashboardServices.contactUsGridColmns}
                                                    rows={this.state.recentContactUs}
                                                    sortingColumns={'null'}
                                                    pagination={'null'}
                                                    totalCount={'null'}
                                                    totalResultes={'null'}
                                                    setPagination={'null'}
                                                    isLoading={this.state.setIsLoad}
                                                    customScope={[
                                                        {
                                                            column: "contactUsId",
                                                            renderTableData: this.dashboardServices.gridSctionContactUsId.bind(this),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
