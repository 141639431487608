/* Component  */
import { axiosPostAuthorize, axiosGetAuthorize, axiosGet, axiosPost, axiosGetMultiParamsFileAuthorizeblob } from '../AxiosRequests';
import {
    Admin_GetAllProductsList, Admin_UpdateProductDetailsById, Admin_GetDeliveryAvailibilityByProductId, Admin_UpdateProductDeliveryTime,
    Admin_GetProductsDetailsById, GetAllAvailability, Admin_UpdateProductPriceById, Admin_AddPriceById, GetAllSizes, GetProductPropertyById,
    Admin_UpdateProductPropertiesById, Admin_AddProductPropertiesById, GetSafetyDetailsById, Admin_UpdateProductSafetyDetailsById, Admin_AddProductSafetyDetailsById,
    GetAllCurrencies, Admin_GetStockAvailibilityByProductId, DeleteSafetyDetailsById, Admin_AddStockById, Admin_GetStockById, Admin_UpdateProductStockById,
    GetAllStockLocation, DeleteStockById, DeleteProductPriceById, Admin_GetPriceDetailsByProductId, Admin_GetProductDashboardCounts, UpdateProductPriceDetails , AdminGetAllEmptyProductCodeCounts , UpdateRandonNumberForProductCode , ExportSupplierDirectLink

} from '../ApiEndPoints';

export default class ProductServices {
    // API function

    async get_Admin_GetAllProductsList(request) {
        return axiosPostAuthorize(Admin_GetAllProductsList, request);
    }

    async admin_UpdateProductDetailsById(request) {
        return axiosPostAuthorize(Admin_UpdateProductDetailsById, request);
    }

    async admin_GetDeliveryAvailibilityByProductId(request) {
        return axiosGetAuthorize(Admin_GetDeliveryAvailibilityByProductId, request);
    }

    async admin_updateProductDeliveryTime(request) {
        return axiosPostAuthorize(Admin_UpdateProductDeliveryTime, request);
    }

    async admin_UpdateProductPriceById(request) {
        return axiosPostAuthorize(Admin_UpdateProductPriceById, request);
    }

    async admin_GetProductsDetailsById(request) {
        return axiosGetAuthorize(Admin_GetProductsDetailsById, request);
    }

    async UpdateProductPriceDetails(request) {
        return axiosPost(UpdateProductPriceDetails, request);
    }

    async getAllAvailability(request) {
        return await axiosGetAuthorize(GetAllAvailability, request);
    }

    async deleteProductPriceById(request) {
        return axiosPostAuthorize(DeleteProductPriceById, request);
    }
    async admin_AddPriceById(request) {
        return axiosPostAuthorize(Admin_AddPriceById, request);
    }

    async getSizes(request) {
        return axiosGetAuthorize(GetAllSizes, request);
    }
    async admin_GetProductDashboardCounts(request) {
        return axiosGetAuthorize(Admin_GetProductDashboardCounts, request);
    }

    async getAllCurrencies(request) {
        return axiosGetAuthorize(GetAllCurrencies, request);
    }

    async getProductPropertyById(request) {
        return axiosGetAuthorize(GetProductPropertyById, request)
    }
    async admin_UpdateProductPropertiesById(request) {
        return axiosPostAuthorize(Admin_UpdateProductPropertiesById, request);
    }
    async admin_AddProductPropertiesById(request) {
        return axiosPostAuthorize(Admin_AddProductPropertiesById, request);
    }
    async getSafetyDetailsById(request) {
        return axiosGetAuthorize(GetSafetyDetailsById, request)
    }
    async admin_UpdateProductSafetyDetailsById(request) {
        return axiosPostAuthorize(Admin_UpdateProductSafetyDetailsById, request);
    }
    async admin_AddProductSafetyDetailsById(request) {
        return axiosPostAuthorize(Admin_AddProductSafetyDetailsById, request);
    }
    async admin_GetStockAvailibilityByProductId(request) {
        return axiosGetAuthorize(Admin_GetStockAvailibilityByProductId, request);
    }
    async admin_AddStockById(request) {
        return axiosPostAuthorize(Admin_AddStockById, request);
    }
    async admin_GetStockById(request) {
        return axiosGetAuthorize(Admin_GetStockById, request)
    }

    async admin_UpdateProductStockById(request) {
        return axiosPostAuthorize(Admin_UpdateProductStockById, request);
    }
    async getAllStockLocation(request) {
        return axiosGetAuthorize(GetAllStockLocation, request);
    }

    async admin_GetPriceDetailsByProductId(request) {
        return axiosGetAuthorize(Admin_GetPriceDetailsByProductId, request);
    }
    async deleteStockById(request) {
        return axiosPostAuthorize(DeleteStockById, request);
    }
    async deleteSafetyDetailsById(request) {
        return axiosPostAuthorize(DeleteSafetyDetailsById, request);
    }

    async adminGetAllEmptyProductCodeCounts(request) {
        return axiosGet(AdminGetAllEmptyProductCodeCounts, request);
    }

    async updateRandonNumberForProductCode(request) {
        return axiosPost(UpdateRandonNumberForProductCode, request);
    }

    async exportSupplierDirectLink(request){
        return axiosGetMultiParamsFileAuthorizeblob(ExportSupplierDirectLink, request)
    }
}