import React from 'react'
import BarChart from '../widgets/charts/BarChart'
import RadarChart from '../widgets/charts/RadarChart'
import PieChart from '../widgets/charts/PieChart'
import LineChart from '../widgets/charts/LineChart'
import HorizontalBarChart from '../widgets/charts/HorizontalBarChart'
import BubbleChart from '../widgets/charts/BubbleChart'
import DoughnutChart from '../widgets/charts/DoughnutChart'

const RanderCharts = (props) => {

    if (props.data.length === 0 || props.data[0].data === "0") {
        return <p className='flex justify-center py-4'>No records found</p>;
    }

    return (
        <div>
            {props.chartType === "1" ? (
                <BarChart
                    data={props.data}
                    title={props.title}
                    // height={props.height}
                    size={props.size}
                />
            ) : props.chartType === "2" ? (
                <HorizontalBarChart
                    data={props.data}
                    title={props.title}
                    // height={props.height}
                    size={props.size}
                />
            ) : props.chartType === "3" ? (
                <RadarChart
                    data={props.data}
                    title={props.title}
                    // height={props.height}
                    size={props.size}
                />
            ) : props.chartType === "4" ? (
                <PieChart
                    data={props.data}
                    title={props.title}
                    // height={props.height}
                    size={props.size}
                />
            ) : props.chartType === "5" ? (
                <LineChart
                    data={props.data}
                    title={props.title}
                    // height={props.height}/
                    size={props.size}
                />
            ) : props.chartType === "6" ? (
                <BubbleChart
                    data={props.data}
                    title={props.title}
                    // height={props.height}
                    size={props.size}
                />
            ) : props.chartType === "7" ? (
                <DoughnutChart
                    data={props.data}
                    title={props.title}
                    // height={props.height}
                    size={props.size}
                />
            ) : null}
        </div>
    )
}

export default RanderCharts