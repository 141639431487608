import React from 'react';
import BaseComponent from '../../shared/baseComponent';
import componentWizardServices from '../../services/domainServices/componentWizardServices';
import { Navigate } from 'react-router-dom';
import Table from '../../components/tables/Table';
import Button from '../../components/common/Button';

/* Services  */
import { Bars } from 'react-loader-spinner';
import { ButtonTypes } from '../../utils/Constants';
import { AddEditComponentWizard } from './AddEditComponentWizard';
import { ErrorMessage } from '../../utils/Messages';
import { encryptionAPI } from '../../utils/Encryption';
export default class ComponetWizardList extends BaseComponent {

    constructor(props) {
        super(props)
        this.componentWizardServices = new componentWizardServices();
        this.state = {
            ...this.state,
            dataList: [],
            setIsLoad: false,
            pagination: {
                ...this.componentWizardServices.pagination,
                pageSize: 10,
                InformaticsConfigurationId: 0
            },
            wizarddetails: this.componentWizardServices.dataItem,
            isUpdate: false,
            setOpenModal: false,
            // activewizardId : 0
            isCaptchaValid: false,
            // activewizardId : 0
            shouldRerenderFormCreator: false

        }
        this.componentWizardServices.onUpdateState = this.updateState.bind(this);
        this.componentWizardServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.componentWizardServices.onInitService();
        let params = new URLSearchParams(window.location.search);
        let informaticsConfigurationId = params.get("");
        if (informaticsConfigurationId > 0) {
            let detail = this.state.pagination;
            detail.InformaticsConfigurationId = informaticsConfigurationId;
            this.setState({ pagination: { ...detail } });
            this.componentWizardServices.onGetInformaticsComponentWizardByIdList(this.state.pagination)
        }
    }

    openModal = (event, index, data, value) => {
        this.setState({
            setOpenModal: true,
            isUpdate: true,
            stockIndex: index
        });
    };

    onCloseModal = (event) => {
        this.setState({ setOpenModal: false, isCaptchaValid: false }, () => {
            console.log("isCaptchaValid 12", this.state.isCaptchaValid);
        }); this.setState({ setOpenModal: false })
        this.componentWizardServices.onResetData();
        this.setState(prevState => ({
            shouldRerenderFormCreator: !prevState.shouldRerenderFormCreator
        }));
    }

    delete = (id) => {
        this.componentWizardServices.apiService.swalServices.Confirm("Delete Warning", "Are you sure, you want to Delete Wizard?", "Yes", "No")
            .then(async confirm => {
                if (confirm) {
                    let request = {
                        Id: id
                    };
                    this.componentWizardServices.onDeleteInformaticsComponentWizard(request, this.state.pagination);
                }
            });
    }

    onActionCall = (type, event, index, value) => {
        switch (type) {
            case "Edit":
                this.componentWizardServices.onGetInformaticsComponentWizardById(value);
                this.setState({
                    setOpenModal: true,
                    isUpdate: true,
                    stockIndex: index
                });
                break;
            case "Delete":
                this.delete(value);
                break;
            default:
                break;
        }
    }

    handleChange(name, value) {
        let detail = this.state.wizarddetails;
        detail[name] = value;
        this.setState({ wizarddetails: { ...detail } });
    }

    proceed = () => {
        let detail = this.state.wizarddetails;
        let isValid = this.componentWizardServices.isValidSubmit(detail, this.state.validationRules, this.state.validState)
        if (isValid) {
            if (detail.componentWizardType === "Table" || detail.componentWizardType === "Counts") {
                this.addWizard();
            }
            else if (detail.componentWizardType === "Charts") {
                if (detail.chartType === "") {
                    this.componentWizardServices.apiService.serviceManager.swalServices.Error(ErrorMessage.charttype);
                }
                else {
                    this.addWizard();
                }

            }
        }
    }

    updateproceed = () => {
        let detail = this.state.wizarddetails;
        let isValid = this.componentWizardServices.isValidSubmit(detail, this.state.validationRules, this.state.validState)
        if (isValid) {
            if (detail.componentWizardType === "Table" || detail.componentWizardType === "Counts") {
                this.update();
            }
            else if (detail.componentWizardType === "Charts") {
                if (detail.chartType === "") {
                    this.componentWizardServices.apiService.serviceManager.swalServices.Error(ErrorMessage.charttype);
                }
                else {
                    this.update();
                }

            }
        }
    }

    addWizard = () => {
        if (!this.state.isCaptchaValid) {
            this.componentWizardServices.apiService.serviceManager.swalServices.Error(ErrorMessage.SelectFieldRequired.replace("{0}", "Captcha Checkbox"));
            this.setState({ setIsLoad: false });
            return;
        }
        else {
            let encryptedQuery = encryptionAPI(this.state.wizarddetails.queryData);
            let request = {
                title: this.state.wizarddetails.title,
                componentWizardtype: this.state.wizarddetails.componentWizardType,
                queryData: encryptedQuery,
                isActive: this.state.wizarddetails.isActive,
                size: this.state.wizarddetails.size,
                informaticsConfigurationId: this.state.pagination.InformaticsConfigurationId,
                chartType: this.state.wizarddetails.chartType,
            }
            // if (this.componentWizardServices.isValidSubmit(this.state.wizarddetails, this.state.validationRules, this.state.validState)) {
            this.componentWizardServices.onAddInformaticsComponentWizard(request, this.state.pagination);
            // }
        }
    };

    update = () => {
        if (!this.state.isCaptchaValid) {
            this.componentWizardServices.apiService.serviceManager.swalServices.Error(ErrorMessage.SelectFieldRequired.replace("{0}", "Captcha Checkbox"));
            this.setState({ setIsLoad: false });
            return;
        }
        else {
            let encryptedQuery = encryptionAPI(this.state.wizarddetails.queryData);
            let request = {
                title: this.state.wizarddetails.title,
                componentWizardtype: this.state.wizarddetails.componentWizardType,
                queryData: encryptedQuery,
                isActive: this.state.wizarddetails.isActive,
                size: this.state.wizarddetails.size,
                informaticsConfigurationId: this.state.pagination.InformaticsConfigurationId,
                chartType: this.state.wizarddetails.chartType,
                componentWizardId: this.state.wizarddetails.componentWizardId,
            }
            // if (this.componentWizardServices.isValidSubmit(this.state.wizarddetails, this.state.validationRules, this.state.validState)) {
            this.componentWizardServices.onUpdateInformaticsComponentWizard(request, this.state.pagination);
            // }
        }
    }

    handleCaptchaChange = (response) => {
        if (response) {
            this.setState({ isCaptchaValid: true });
        } else {
            this.setState({ isCaptchaValid: false });
        }
    };

    render() {
        const testSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="flex h-screen overflow-hidden">

                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto">
                            <div className="grid grid-flow-col sm:auto-cols-max sm:justify-end gap-3">
                                <Button type={ButtonTypes.Primary} title="Add component wizard" buttonClick={(event) => this.openModal(event, '')} isLoading={this.state.setIsLoad} />
                            </div>
                            <div className='mt-3'>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <Table
                                            columns={this.componentWizardServices.gridColmns}
                                            rows={this.state.dataList}
                                            sortingColumns={[]}
                                            pagination={this.state.pagination}
                                            totalCount={this.state.TotalCount}
                                            totalResultes={this.state.TotalCount}
                                            setPagination={this.componentWizardServices.onPageChange.bind(this)}
                                            customScope={[
                                                {
                                                    column: "componentWizardId",
                                                    column1: true,
                                                    renderTableData:
                                                        this.componentWizardServices.gridAction.bind(this),
                                                },
                                            ]}
                                        />
                                }
                            </div>
                        </div>
                    </main>
                </div>
                <AddEditComponentWizard
                    openStateModal={this.state.setOpenModal}
                    onCloseModal={this.onCloseModal.bind(this)}
                    modalOpen={(e) => { this.setState({ setOpenModal: false }) }}
                    onClose={(e) => {
                        this.setState({ setOpenModal: false })
                    }}
                    wizarddetails={this.state.wizarddetails}
                    validState={this.state.validState}
                    validationRules={this.state.validationRules}
                    validateField={this.componentWizardServices.validateField.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                    addWizard={this.addWizard.bind(this)}
                    proceed={this.proceed.bind(this)}
                    updateproceed={this.updateproceed.bind(this)}
                    handleCaptchaChange={this.handleCaptchaChange.bind(this)}
                    testSiteKey={testSiteKey}
                    shouldRerenderFormCreator={this.state.shouldRerenderFormCreator}
                />
            </div>
        )
    }
}
