
/* Component  */
import React, { useState } from 'react';
/* Library  */
import { FormFieldTypes } from '../../utils/Constants';
import ValidationText from '../../utils/validation/ValidationText';
import { validate, isValidForm } from '../../utils/validation/CommonValidator';
import { Bars } from 'react-loader-spinner';
import CurrencyInput from 'react-currency-masked-input'
import ButtonGroup from '../common/ButtonGroup';

const Form = ({ form, formValues, setFormValues, submitForm, isLoading }) => {
  const [setIsLoad, setsetIsLoad] = useState(false)
  const [UpdateState, setUpdateState] = useState(1)
  const [validState, setValidState] = useState({
    isValid: true,
    error: {},
  });

  // Other function 
  const submit = () => {
    if (isValidAllFormFields()) {
      submitForm();
    }
  }

  const validateFields = (key, required) => {
    if (required) {
      let validationRules = { [key]: form.formFields.filter(f => f.key === key)[0].validation };
      const newValidState = validate(key, formValues, validationRules, validState);
      setValidState(newValidState);
    }
  }

  const isValidAllFormFields = () => {
    let validationRules = {};
    form.formFields.forEach(field => {
      if (field.validation) {
        validationRules[field.key] = field.validation;
      }
    });
    const newValidState = isValidForm(formValues, validationRules, validState);
    setValidState(newValidState);
    return newValidState.isValid;
  }

  return (
    (isLoading) ?
      <Bars
        className="row"
        wrapperStyle={{ marginTop: '150px' }}
        color="#3c4b64"
        height={55}
        width="100%"
        visible={isLoading}
      />
      :
      <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
        <div className="border-slate-200">
          <div className="space-y-8 mt-8">
            <div>
              <div className="grid gap-5 md:grid-cols-3">
                {form.formFields && form.formFields.map((field, key) => {
                  switch (field.type) {
                    case FormFieldTypes.Input:
                      return (
                        <div key={key}>
                          <div>
                            <label className="block text-sm font-medium mb-1" htmlFor={`input_${field.key}`}>{field.title} {(field.isRequired) ? <span className="text-rose-500">*</span> : null}</label>
                            <div className="relative">
                              <input id={`input_${field.key}`} className={`form-input w-full ${field.isDisabled ?
                                "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""} 
                          ${validState.error[field.key] ? "border-rose-300" : ""} ${field.isPrefix ? "pl-12" : ""} ${field.isSuffix ? "pr-8" : ""}`} type="text"
                                placeholder={field.placeholder} disabled={field.isDisabled} value={formValues[field.key]} maxLength={field.maxLength} onChange={(event) => setFormValues(field.key, event.target.value)} onBlur={() => validateFields(field.key, field.isRequired)} />
                              {field.isPrefix ?
                                <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                                  <span className="text-sm text-slate-400 font-medium px-3">{field.prefix}</span>
                                </div>
                                : null}
                              {field.isSuffix ?
                                <div className="absolute inset-0 left-auto flex items-center pointer-events-none">
                                  <span className="text-sm text-slate-400 font-medium px-3">{field.suffix}</span>
                                </div>
                                : null}
                            </div>

                          </div>
                          <ValidationText key={key} error={validState.error[field.key]} />
                        </div>
                      )
                    case FormFieldTypes.Select:
                      return (
                        <div key={key}>
                          <div>
                            <label className="block text-sm font-medium mb-1" htmlFor={`select_${field.key}`}>{field.title} {(field.isRequired) ? <span className="text-rose-500">*</span> : null}</label>
                            <select id={`select_${field.key}`} className={`form-select w-full ${field.isDisabled ? "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""} ${validState.error[field.key] ? "border-rose-300" : ""}`}
                              placeholder={field.placeholder} disabled={field.isDisabled} value={formValues[field.key]} onChange={(event) => setFormValues(field.key, event.target.value)} onBlur={() => validateFields(field.key, field.isRequired)}>
                              <option value="">-Select-</option>
                              {field.options && field.options.map((optionValue, optionKey) => {
                                return <option key={optionKey} value={optionValue[field.valueKey]}>{optionValue[field.textKey]}</option>
                              })}
                            </select>
                          </div>
                          <ValidationText key={key} error={validState.error[field.key]} />
                        </div>
                      )
                    case FormFieldTypes.CheckBox:
                      return (
                        <div key={key} className="mt-9 ml-1">
                          <div>
                            <label className="flex items-center">
                              <input type="checkbox" id={`check_${field.key}`} className={`form-checkbox ${field.isDisabled ?
                                "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""}`} disabled={field.isDisabled} checked={formValues[field.key]} onChange={(event) => setFormValues(field.key, event.target.checked)} />
                              <span className="text-sm ml-2" htmlFor={`check_${field.key}`}>{field.title}</span>
                            </label>
                          </div>
                        </div>
                      )
                    case FormFieldTypes.Numeric:
                      return (
                        <div key={key}>
                          <div>
                            <label className="block text-sm font-medium mb-1" htmlFor={`num_${field.key}`}>{field.title} {(field.isRequired) ? <span className="text-rose-500">*</span> : null}</label>
                            <div className="relative">
                              <input id={`num_${field.key}`} className={`form-input w-full ${field.isDisabled ?
                                "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""} 
                        ${validState.error[field.key] ? "border-rose-300" : ""} ${field.isPrefix ? "pl-12" : ""} ${field.isSuffix ? "pr-8" : ""}`} type="number"
                                placeholder={field.placeholder} disabled={field.isDisabled} value={formValues[field.key]} onChange={(event) => setFormValues(field.key, event.target.value)} onBlur={() => validateFields(field.key, field.isRequired)} />
                              {field.isPrefix ?
                                <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                                  <span className="text-sm text-slate-400 font-medium px-3">{field.prefix}</span>
                                </div>
                                : null}
                              {field.isSuffix ?
                                <div className="absolute inset-0 left-auto flex items-center pointer-events-none">
                                  <span className="text-sm text-slate-400 font-medium px-3">{field.suffix}</span>
                                </div>
                                : null}
                            </div>
                          </div>
                          <ValidationText key={key} error={validState.error[field.key]} />
                        </div>
                      )
                    case FormFieldTypes.TextArea:
                      return (
                        <div key={key}>
                          <div>
                            <label className="block text-sm font-medium mb-1" htmlFor={`area_${field.key}`}>{field.title} {(field.isRequired) ? <span className="text-rose-500">*</span> : null}</label>
                            <textarea id={`area_${field.key}`} className={`form-textarea w-full focus:border-slate-300 ${field.isDisabled ?
                              "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""} 
                          ${validState.error[field.key] ? "border-rose-300" : ""}`} rows={field.rows ? field.rows : 4} placeholder={field.placeholder} value={formValues[field.key]}
                              onChange={(event) => setFormValues(field.key, event.target.value)}
                              disabled={field.isDisabled}
                              onBlur={() => validateFields(field.key, field.isRequired)}></textarea>
                          </div>
                          <ValidationText key={key} error={validState.error[field.key]} />
                        </div>
                      )
                    case FormFieldTypes.DatePicker:
                      return (
                        <div key={key}>
                          <div>
                            <label className="block text-sm font-medium mb-1" htmlFor={`date_${field.key}`}>{field.title} {(field.isRequired) ? <span className="text-rose-500">*</span> : null}</label>
                            <div className="relative">
                              <input id={`date_${field.key}`} className={`form-input w-full ${field.isDisabled ?
                                "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""} 
                        ${validState.error[field.key] ? "border-rose-300" : ""}`} type="date"
                                placeholder={field.placeholder} disabled={field.isDisabled} value={formValues[field.key]} onChange={(event) => setFormValues(field.key, event.target.value)}
                                onBlur={() => validateFields(field.key, field.isRequired)} min={field.isDisablePastDates ? new Date().toISOString().slice(0, 10) : null} />
                            </div>
                          </div>
                          <ValidationText key={key} error={validState.error[field.key]} />
                        </div>
                      )
                    case FormFieldTypes.Password:
                      return (
                        <div key={key}>
                          <div>
                            <label className="block text-sm font-medium mb-1" htmlFor={`pwd_${field.key}`}>{field.title} {(field.isRequired) ? <span className="text-rose-500">*</span> : null}</label>
                            <div className="relative">
                              <input id={`pwd_${field.key}`} className={`form-input w-full ${field.isDisabled ?
                                "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""} ${validState.error[field.key] ? "border-rose-300" : ""}`} type="password"
                                placeholder={field.placeholder} disabled={field.isDisabled} value={formValues[field.key]} maxLength={field.maxLength} onChange={(event) => setFormValues(field.key, event.target.value)} onBlur={() => validateFields(field.key, field.isRequired)} />
                            </div>
                          </div>
                          <ValidationText key={key} error={validState.error[field.key]} />
                        </div>
                      )
                    case FormFieldTypes.Currency:
                      return (
                        <div key={key}>
                          <div>
                            <label className="block text-sm font-medium mb-1" htmlFor={`pwd_${field.key}`}>{field.title} {(field.isRequired) ? <span className="text-rose-500">*</span> : null}</label>
                            <div className="relative">
                              <CurrencyInput
                                name="myInput"
                                required
                                disabled={field.isDisabled}
                                onChange={(event) => setFormValues(field.key, event.target.value)}
                                onBlur={() => validateFields(field.key, field.isRequired)}
                                className={`form-input w-full ${field.isDisabled ?
                                  "disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed" : ""} ${validState.error[field.key] ? "border-rose-300" : ""}`}
                              />
                            </div>
                          </div>
                          <ValidationText key={key} error={validState.error[field.key]} />
                        </div>
                      )
                    default:
                      return <>No Fields Configured</>
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap float-right2 mt-3 mb-4">
          <ButtonGroup
            onUpdateClick={() => submit()}
            updateState={UpdateState}
            isLoading={setIsLoad}
          />
        </div>
      </div>
  )
}

export default Form;