/* Component  */
import React from "react";
import { Navigate } from "react-router";
import BaseComponent from "../../shared/baseComponent";

/* Services  */
import SwalServices from "../../services/swalServices/SwalServices";

/* Images  */
import AuthImage from "../../images/9.jpg";
import AuthDecoration from "../../images/auth-decoration.png";
import authenticationServices from "../../services/domainServices/authenticationServices";
import FormFieldGroup from "../../components/formGroupFields/FormFieldGroup";
import { getCookie, getSSOAuthCookie } from "../../utils/cookies/CookieHandler";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";

class Login extends BaseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props);
    this.authenticatinService = new authenticationServices();
    this.authService = new AuthenticationServices();
    this.swalServices = new SwalServices();
    this.authenticatinService.onUpdateState = this.updateState.bind(this);
    this.state = {
      ...this.state,
      setIsLoad: false,
      redirect: "",
      userDetails: this.authenticatinService.dataItem,
      centralizeLogin: false
    };
  }

  // Other function

  componentDidMount() {
    this.authenticatinService.onInitService();
    document.title = "Login";
    const cookieDetail = getCookie("__ssoAuth");
    if (cookieDetail && cookieDetail.token) {
      this.setState({ centralizeLogin: true });
      // this.authenticatinService.onValidateToken(cookieDetail.token);
    }
  }
  redirectToHome = () => {
    this.setState({ redirect: "/" });
  };
  // API function

  login = () => {
    if (this.authenticatinService.isValidSubmit(this.state.userDetails, this.state.validationRules, this.state.validState)) {
      this.authenticatinService.onAdd(this.state.userDetails);
    }
  };

  loginViaSSO = () => {
    const cookieDetail = getCookie("__ssoAuth");
    if (cookieDetail && cookieDetail.token) {
      let request = {
        token: cookieDetail.token
      }
      this.authenticatinService.onValidateToken(request);
    } else {
      //Cookie not found then need to call login API.
      // this.authenticatinService.onAdd(this.state.userDetails);
    }
  };

  // Event function

  loginOnEnter = (e) => {
    if (e.charCode === 13) {
      this.login();
    }
  };

  handleChange = (key, value) => {
    let newUserDetails = { ...this.state.userDetails };
    newUserDetails[key] = value;
    this.setState({ userDetails: newUserDetails });
  };

  render() {
    if (this.props.authDetails) {
      return <Navigate to={"/Dashboard"} />;
    }
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <main className="bg-white">
        <div className="relative md:flex">
          <div className="md:w-1/2">
            <div className="min-h-screen h-full flex items-center login-back-color">
              <div className="margin-left-25 px-4 table-login ui-width w-full login-card pad-login-add">
                <div className="logo-img text-center">
                  <img className="mx-auto mb-4" src="/logo.png" alt="Logo"></img>
                </div>
                <h1 className="text-3xl text-slate-800 font-bold mb-6 text-center">
                  {/* Login to Aurum Admin! */}
                  Sign In
                </h1>
                <div className="space-y-4">
                  {!this.state.centralizeLogin ?
                    <>
                      {this.authenticatinService.formFields.map((Item, index) => {
                        return (
                          <>
                            <FormFieldGroup
                              column={Item}
                              value={this.state.userDetails[Item.fieldName]}
                              error={this.state.validState.error[Item.fieldName]}
                              checked={
                                Item.checked === true
                                  ? this.state.userDetails[Item.fieldName]
                                  : null
                              }
                              onChange={(event) =>
                                this.handleChange(
                                  Item.fieldName,
                                  event.target.value
                                )
                              }
                              maxLength={15}
                              options={Item.Options ? Item.Options : null}
                              onKeyDown={
                                Item.onkeydown ? Item.onkeydown.bind(this) : null
                              }
                              onKeyPress={this.loginOnEnter}
                              onBlur={() =>
                                this.authenticatinService.validateField(
                                  Item.fieldName,
                                  this.state.userDetails,
                                  this.state.validationRules,
                                  this.state.validState
                                )
                              }
                            />
                          </>
                        );
                      })}
                    </>
                    : null}
                </div>
                <div className="flex items-center justify-center mt-10">
                  {this.state.centralizeLogin ?
                    <button className="btn commanColorbtn text-dark btnsign min-width-130" onClick={() => this.loginViaSSO()}>
                      {this.state.setIsLoad ? <div class="loader"></div> : <><span> Login Via CS</span> </>}
                    </button>
                    :
                    <button className="btn commanColorbtn text-dark btnsign min-width-130" onClick={() => this.login()}>
                      {this.state.setIsLoad ? <div class="loader"></div> : <><span> Sign In</span> </>}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
          <div
            className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
            aria-hidden="true"
          >
            <img
              className="object-cover object-center w-full h-full"
              src={AuthImage}
              width="760"
              height="1024"
              alt="Authentication"
            />
            <img
              className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
              src={AuthDecoration}
              width="218"
              height="224"
              alt="Authentication decoration"
            />
          </div>
        </div>
      </main>
    );
  }
}

export default Login;
