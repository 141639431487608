/* Component  */
import React from 'react';
import Table from '../../components/tables/Table';
import { Navigate } from 'react-router';
import BaseComponent from '../../shared/baseComponent';

/* Library  */
import { Bars } from 'react-loader-spinner';
import { encryptAES } from "../../utils/Encryption";
import FormFieldGroup from "../../components/formGroupFields/FormFieldGroup";

/* Services  */
import SwalServices from '../../services/swalServices/SwalServices';
import ContactUsServices from '../../services/domainServices/contactUsServices';



export class ContactUsList extends BaseComponent {
  /* Constructor and Component method */

  constructor(props) {
    super(props)
    this.contactUsServices = new ContactUsServices();
    this.swalServices = new SwalServices();
    this.state = {
      searchList: this.contactUsServices.dataItem,
      pagination: this.contactUsServices.pagination,
      setIsLoad: false,
      contactUsDetails: {}
    }
    this.contactUsServices.onUpdateState = this.updateState.bind(this);
    this.contactUsServices.onActionCall = this.onActionCall.bind(this);
  }

  onActionCall = (type, data) => {
    switch (type) {
      case "View":
        window.open(`/ContactUs/id?=${encryptAES(data)}`, '_blank');
        break;
    }
  };
  // Event function 

  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  }

  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.contactUsServices.onGetContactUs(data);
      this.setState({ pagination: { ...data } });

    }
    this.setState({ searchList: { ...detail } });
  }
  componentDidMount() {
    this.contactUsServices.onGetContactUs(this.state.pagination);
    document.title = 'Contact-Us';
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Contact Us</h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                  <div className="relative">
                    {this.contactUsServices.formFields.map((Item, index) => {
                      return (
                        <>
                          {Item.inputType === "text" ?
                            <FormFieldGroup className='ms-auto relative search-bar-input'
                              column={Item}
                              value={
                                this.state.searchList[Item.fieldName]
                              }
                              onChange={(event) =>
                                this.handleChangeEvent(Item.fieldName, event.target.value)
                              }
                              onKeyPress={(e) =>
                                e.key === "Enter" &&
                                this.handleChange(Item.fieldName, e.target.value)
                              }
                              key={index} /> : null}</>)
                    })}
                  </div>
                  <button className="btn commanColorbtn" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                    <span className="xs:block">Search</span>
                  </button>
                </div>
              </div>
              <div className='main-contact-us-list'>
                {
                  (this.state.setIsLoad) ?
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: '150px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                    :
                    <Table
                      columns={this.contactUsServices.gridColmns}
                      rows={this.state.dataList}
                      sortingColumns={[]}
                      pagination={this.state.pagination}
                      totalCount={this.state.TotalCount}
                      totalResultes={this.state.TotalCount}
                      setPagination={this.contactUsServices.onPageChange.bind(this)}
                      customScope={[
                        {
                          column: 'contactUsId',
                          renderTableData: this.contactUsServices.gridActionViewCol.bind(this)
                        },
                      ]}
                    />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default ContactUsList
