// import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";
import moment from "moment";
import { DateFromat } from "../../utils/DateFromat";
import { formatPrice } from "../../utils/Encryption";
import { SuccessMessage } from "../../utils/Messages";

const rfqDetail = {
  searchRFQNumber: "",
  searchAurumCatalogId: "",
  searchUserName: "",
  searchCASNo: "",
  searchText: ""
};

export default class rfqServices extends BaseDomainService {
  // API Get
  getList = this.apiService.rfqService.getRFQList;
  Delete = this.apiService.rfqService.DeleteRFQById;
  getRFQById = this.apiService.rfqService.admin_GetRFQById

  //Table Grid columns
  gridColmns = [
    { name: "rfqNumber", title: "RFQ#" },
    { name: "userDisplayName", title: "User Full Name" },
    { name: "userName", title: "Email Address" },
    { name: "noOfCountRFQ", title: "#Product" },
    { name: "dateOfRFQ", title: "Date" },
    { name: "rfqId", title: "Action" },
  ];

  gridPriceColmns = [
    { name: "package", title: "SKU-Pack Size" },
    { name: "qunatity", title: "Quantity" },
    { name: "priceRange", title: "Price Range" }
  ];

  // Define the state variables
  dataItem;
  moduleList;
  serverList = [];

  // define the form fields
  formFields = [
    {
      inputType: "text",
      placeholder: "Search by CatalogID",
      fieldName: "searchAurumCatalogId",
      searchIcon: true
    },
    {
      inputType: "text",
      placeholder: "Search by CASNumber",
      fieldName: "searchCASNo",
      searchIcon: true
    },
    {
      inputType: "text",
      placeholder: "Search by RFQ Number",
      fieldName: "searchRFQNumber",
      searchIcon: true

    },
    {
      inputType: "text",
      placeholder: "Search by User Name",
      fieldName: "searchUserName",
      searchIcon: true
    },
    {
      inputType: "label",
      fieldName: "productName",
      tittle: " Product Name :",
      className: '',
      rfqDetials: 1,

    },
    {
      inputType: "label",
      fieldName: "casNo",
      tittle: " CAS : ",
      className: '',
      rfqDetials: 1

    },
    {
      inputType: "label",
      fieldName: "mdlNo",
      tittle: " MDL : ",
      className: '',
      rfqDetials: 1

    },
    {
      inputType: "label",
      fieldName: "expectedDeliveryDate",
      tittle: " Expected Delivery Date : ",
      className: '',
      rfqDetials: 1

    },
    {
      inputType: "label",
      fieldName: "specialRequestNote",
      tittle: " Special Request Note : ",
      className: '',
      rfqDetials: 1

    },
    {
      inputType: "label",
      fieldName: "emailAddress",
      tittle: " Email : ",
      className: '',
      companyDetials: 1

    },
    {
      inputType: "label",
      fieldName: "companyName",
      tittle: " Company : ",
      className: '',
      companyDetials: 1

    },
    {
      inputType: "label",
      fieldName: "firstName",
      tittle: " First Name : ",
      className: '',
      companyDetials: 1

    },
    {
      inputType: "label",
      fieldName: "lastName",
      tittle: " Last Name : ",
      className: '',
      companyDetials: 1

    },

  ];

  gridPriceRangeColmns = [
    { name: "package", title: "SKU-Pack Size" },
    { name: "qunatity", title: "Quantity" },
  ];


  // Validation

  validationRules = {};
  resetValidationRule = {
  };

  constructor() {
    super();
    this.onPaginationUpdate = this.onGetRequests.bind(this);
    this.actionKey = "rfqId";
    this.dataItem = { ...rfqDetail };
  }

  //** API Calls

  onGetRequests = (requst) => {
    this.apiService.onAPICall("getRequest", this.getList, requst, this.onAPIResponse, this.onUpdateState)
  };

  onGetRFQById = (requst) => {
    this.apiService.onAPICall("getRFQById", this.getRFQById, requst, this.onAPIResponse, this.onUpdateState)
  };

  onDelete = (requst) => {
    this.apiService.onAPICall("Delete", this.Delete, requst, this.onAPIResponse, this.onUpdateState);
  };

  //** Service Event */

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
  }

  onResetData = () => {
    this.dataItem = { ...rfqDetail };
    this.onUpdateState({ addRequestDetails: this.dataItem });
  };
  //** Methods     */

  onRedirectoList = () => {
    var rfqURL = localStorage.getItem('RFQURL');
    if (rfqURL != null) {
      this.onRedirect("/");
      localStorage.clear();
    }
    else {
      this.onRedirect("/RFQLists");
    }
  }

  onAPIResponse = (key, isSuccess, responseData) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "getRequest":
        if (responseData.itemList !== null) {
          let itemList = responseData.itemList;
          itemList.forEach(function (i) {
            i.dateOfRFQ = i.dateOfRFQ ? DateFromat(i.dateOfRFQ) : "-"
          });

        }
        this.onUpdateState({
          RfqList: responseData.itemList,
          TotalCount: responseData.totalCount,
          setIsLoad: false,
        });
        break;

        case "getRFQById":
          if (responseData !== null && responseData.length !== 0) {
            if (responseData.length <= 1) {
              responseData = responseData[0];
              responseData.expectedDeliveryDate = DateFromat(responseData.expectedDeliveryDate);
              let itemList = responseData.packageList;
              itemList.forEach(function (i) {
                i.priceRange = "$ " + (i.priceRange).replace("-", " -");;
              });
              this.onUpdateState({
                RfqDetails: responseData,
                packageList: responseData.packageList
              });
            } else {
              let newDataList = responseData ? responseData.reduce((acc, data) => {
                if (!acc.some(item => item.aurumCatalogId === data.aurumCatalogId)) {
                  acc.push({
                    rfqId: data.rfqId,
                    rfqNumber: data.rfqNumber,
                    productId: data.productId,
                    aurumCatalogId: data.aurumCatalogId,
                    specialRequestNote: data.specialRequestNote,
                    expectedDeliveryDate: data.expectedDeliveryDate
                  });
                }
                return acc;
              }, [])
                : [];
              this.onUpdateState({
                isMulti: true,
                RfqDetails: responseData,
                RFQMultiData: newDataList
              });
            }
          }
          this.onUpdateState({ setIsLoad: false });
          break;

      case "Delete":
        if (responseData.errorType === '') {
          this.onUpdateState({ "setIsLoad": false });
          this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "RFQ"));
          this.onGetRequests(this.pagination);
        }
        break;

    }

  };
}
