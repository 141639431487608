import React, { useEffect, useState, useImperativeHandle } from 'react'

const Captcha = React.forwardRef((props, ref) => {
    const SECURITY_CODE_LENGTH = 4;
    const [values, setValues] = useState({ securityCode: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [securityCode, setSecurityCode] = useState("");

    useImperativeHandle(ref, () => ({
        checkValid(e) {
            return (values.securityCode === securityCode)

        }
    }))

    const generateSecurityCodeImage = () => {
        const code = Array.from(Array(SECURITY_CODE_LENGTH), () =>
            Math.floor(Math.random() * 36).toString(36)
        ).join("");

        const securityCodeImageElement = document.getElementById(
            "securityCodeImage"
        );
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = 40;
        canvas.height = 20;

        const imgElement = document.createElement("img");

        imgElement.addEventListener("load", loadImage);
        imgElement.src = `data:image/svg+xml,${encodeURIComponent(
            `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="100"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml" style="display:block;"><span style="margin:auto;">${code}</span></div></foreignObject></svg>`
        )}`;

        function loadImage(e) {
            ctx.drawImage(e.target, 0, 0);
            securityCodeImageElement.src = canvas.toDataURL();
        }

        setSecurityCode(code);
    };

    const onChange = (event) => {
        const { target } = event;
        const { name, value } = target;
        event.persist();
        setValues({ ...values, [name]: value });
    };

    const onEnter = (event) => {

        setIsSubmitting(true);

        if (event) event.preventDefault();

        setTimeout(() => {
            if (values.securityCode !== securityCode) {
                generateSecurityCodeImage();
                setIsSubmitting(false);
                props.onSubmit(false);
                return;
            }

            generateSecurityCodeImage();
            setIsSubmitting(false);
        }, 1000);
    };

    useEffect(() => {
        generateSecurityCodeImage();
    }, []);

    return (
        <>
            <div className="relative w-auto h-auto m-auto">
                <form onSubmit={onEnter}>
                    <div>
                        <div>
                            <div className="captchaparentcode">
                                <label htmlFor="securityCode" className="code block text-sm font-medium mb-1" style={{ display: "inline-block" }}>
                                    Security Code
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                </label>
                                <div className="captchaparent" style={{ backgroundColor: "#fef4d2", padding: "15px 15px", }}>
                                    <div className='captch-img'>
                                        <img
                                            id="securityCodeImage"
                                            alt="Security Code Challange"
                                            className="text-align-center"
                                            style={{
                                                objectFit: "contain",
                                                width: "85%",
                                                height: "30px",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className=" gap-3  d-flex mt-2">
                                    <input
                                        id="securityCode"
                                        style={{ width: "49%", height: "2.7rem" }}
                                        type="text"
                                        name="securityCode"
                                        placeholder="Security Code"
                                        className="form-control mt-1"
                                        onChange={onChange}
                                        value={values.securityCode} />

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
})

Captcha.displayName = 'Captcha';
export default Captcha