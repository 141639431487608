/* Component  */
import { axiosGet, axiosPostAuthorize, axiosPost, axiosGetAuthorize } from '../AxiosRequests';
import { Admin_AddImport, Admin_GetImportDetailsById, Admin_GetImportList } from '../ApiEndPoints';

export default class ImportDataServices {
    // API function

    async admin_AddImport(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("importTypeId", request.importTypeId);
        formData.append("importTitle", request.importTitle);
        formData.append("importFileName", request.importFileName);
        formData.append("createdBy", request.createdBy);
        return axiosPostAuthorize(Admin_AddImport, formData, true)
    }

    async admin_GetImportDetailsById(request) {
        return axiosGetAuthorize(Admin_GetImportDetailsById, request);
    }

    async admin_GetImportList(request) {
        return axiosPostAuthorize(Admin_GetImportList, request);
    }
}