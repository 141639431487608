import BaseDomainService from "./baseDomainServices";
import { AddressType } from "../../utils/enums/AddressTypeEnum";
import { DateFromat } from "../../utils/DateFromat";
import { formatPrice } from "../../utils/Encryption";
import { SuccessMessage } from "../../utils/Messages";

const orderDetail = {
  searchCatalogNumber: "",
  searchUserName: "",
  searchCASNo: "",
  searchProductNo: "",
  searchText: "",
  searchOrderNumber: ""
};

export default class orderServices extends BaseDomainService {
  // API Get
  getList = this.apiService.manageOrderSevices.admin_GetOrderList;
  getordersById = this.apiService.manageOrderSevices.admin_GetOrderDetails
  deleteOrdersById = this.apiService.manageOrderSevices.admin_DeleteOrderById


  //Table Grid columns
  gridColmns = [
    { name: "purchaseOrder", title: "PO" },
    { name: "userdisplayname", title: "User Full Name" },
    { name: "userName", title: "Email Address" },
    { name: "phoneno", title: "Phone" },
    { name: "companyname", title: "Company Name" },
    { name: "paymentType", title: "PM" },
    { name: "totalPrice", title: "Total Price" },
    { name: "noOfProducts", title: "#Products" },
    { name: "orderDate", title: "Date" },
    { name: "orderId", title: "Actions" },
  ];

  gridProductColumn = [
    { name: "productName", title: "Product Name" },
    { name: "aurumCatalogId", title: "Catalog No" },
    { name: "size", title: "Pack Size" },
    { name: "price", title: "Unit Price" },
    { name: "orderPrice", title: "Total Price (USD" },
    { name: "orderStatus", title: "Status" },
  ]

  // Define the state variables
  dataItem;

  // define the form fields
  formFields = [
    {
      inputType: "text",
      placeholder: "Order Number",
      fieldName: "searchOrderNumber",
      searchIcon: true
    },
    {
      inputType: "text",
      placeholder: "PO Number",
      fieldName: "searchProductNo",
      searchIcon: true
    },
    {
      inputType: "text",
      placeholder: "CAS Number",
      fieldName: "searchCASNo",
      searchIcon: true
    },
    {
      inputType: "text",
      placeholder: "Catalog Number",
      fieldName: "searchCatalogNumber",
      searchIcon: true
    },
    {
      inputType: "text",
      placeholder: "User Name",
      fieldName: "searchUserName",
      searchIcon: true
    },
    {
      inputType: "label",
      tittle: "User Name :",
      fieldName: "userDisplayName",
      className: '',
      userDetails: 1
    },
    {
      inputType: "label",
      tittle: "Email Address : ",
      fieldName: "userName",
      className: '',
      userDetails: 1
    },
    {
      inputType: "label",
      tittle: " Contact Number ",
      fieldName: "phoneNo",
      className: '',
      userDetails: 1
    },
    {
      inputType: "label",
      tittle: " Company Name : ",
      fieldName: "companyName",
      className: '',
      userDetails: 1
    },
    {
      inputType: "label",
      tittle: " Designation :",
      fieldName: "designation",
      className: '',
      userDetails: 1
    },
    {
      inputType: "label",
      fieldName: "attendantName",
      className: '<i className="icon-sm fal fa-user fw-bold pe-2 addressIcon mr-2"></i>',
      shippingAddress: 1

    },
    {
      address: true
    },
    {
      inputType: "label",
      fieldName: "attendantPhoneNo",
      className: '<i className="icon-sm fal fa-phone fw-bold pe-2 addressIcon mr-2">  </i> ',
      shippingAddress: 1
    }
    ,
    {
      inputType: "label",
      fieldName: "purchaseOrder",
      tittle: " Purchase Order :",
      className: '',
      orderDetials: 1

    },
    {
      inputType: "label",
      fieldName: "refNumber",
      tittle: " Reference Number :",
      className: '',
      orderDetials: 1

    },
    {
      inputType: "label",
      fieldName: "note",
      tittle: " Special Requests :",
      className: '',
      orderDetials: 1

    },
    // {
    //   inputType: "label",
    //   tittle: "Shipping Method: ",
    //   fieldName: "shippingMethod",
    //   className: '',
    //   otherInformation: 1
    // },
    {
      inputType: "label",
      tittle: "Payment Type:",
      fieldName: "paymentType",
      className: '',
      otherInformation: 1
    },
    {
      inputType: "label",
      tittle: "Card Number:",
      fieldName: "cardLastFourNumber",
      className: '',
      otherInformation: 1
    },
    {
      inputType: "label",
      tittle: "Stripe Customer:",
      fieldName: "stripeCustomerId",
      className: '',
      otherInformation: 1
    },
    {
      inputType: "label",
      tittle: "Payment Status: ",
      fieldName: "paymentStatus",
      className: '',
      otherInformation: 1
    },
    {
      inputType: "label",
      tittle: "Item Total: ",
      fieldName: "productTotal",
      className: '',
      priceTotal: 1
    },
    {
      inputType: "label",
      tittle: "Shipping Total: ",
      fieldName: "orderShippingCharges",
      className: '',
      priceInformation: 1
    },
    {
      inputType: "label",
      tittle: "Handling Charges: ",
      fieldName: "handlingCharges",
      className: '',
      handlingCharges: 1

    },
    {
      inputType: "label",
      tittle: "Card Processing: ",
      fieldName: "cardProcessingCharges",
      className: '',
      cardProcessingCharges: 1

    },
    {
      inputType: "label",
      tittle: "Additional Charges: ",
      fieldName: "additionalCharges",
      className: '',
      additionalCharges: 1

    },
    {
      inputType: "label",
      tittle: "Taxes: ",
      fieldName: "tax",
      className: '',
      tax: 1

    },
    {
      inputType: "label",
      tittle: "Discount: ",
      fieldName: "discountPrice",
      className: '',
      discountPrice: 1

    },
    {
      inputType: "label",
      tittle: "Total: ",
      fieldName: "totalPrice",
      className: '',
      priceInformation: 1,

    },
    {
      inputType: "label",
      tittle: "Shipping Method: ",
      fieldName: "shippingMethod",
      className: '',
      shippingInformation: 1,
      shippingInfo: 2,
    },
    {
      inputType: "label",
      tittle: "Own Shipping Provider: ",
      fieldName: "ownShippingProvider",
      className: '',
      shippingInfo: 2,
    },
    {
      inputType: "label",
      tittle: "Own Shipping Number: ",
      fieldName: "ownShippingNumber",
      className: '',
      shippingInfo: 2,
    }
  ];

  // Validation

  validationRules = {};
  resetValidationRule = {
  };

  constructor() {
    super();
    this.onPaginationUpdate = this.onGetRequests.bind(this);
    this.actionKey = "orderId";
    this.dataItem = { ...orderDetail };
  }

  //** API Calls

  onGetRequests = (requst) => {
    this.apiService.onAPICall(
      "getRequest",
      this.getList,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };

  onGetOrdersById = (requst) => {
    this.apiService.onAPICall(
      "getordersById",
      this.getordersById,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };
  onDeleteOrder = (requst, pagination) => {
    this.apiService.onAPICall(
      "deleteOrder",
      this.deleteOrdersById,
      requst,
      this.onAPIResponse, this.onUpdateState, pagination
    );
  };
  //** Service Event */

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
  }

  onResetData = () => {
    this.dataItem = { ...orderDetail };
    this.onUpdateState({ addRequestDetails: this.dataItem });
  };
  //** Methods     */

  onRedirectoList = () => {
    var rfqURL = localStorage.getItem('OrderUrl');
    if (rfqURL != null) {
      this.onRedirect("/");
      localStorage.clear();
    }
    else {
      this.onRedirect("/OrderLists");
    }
  }

  onAPIResponse = (key, isSuccess, responseData, pagination) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "getRequest":

        if (responseData.itemList !== null) {
          let itemList = responseData.itemList;
          itemList.forEach(function (i) {
            i.orderDate = i.orderDate === null ? "Invalid-date" : DateFromat(i.orderDate)
            i.refNumber = i.refNumber ? i.refNumber : "-"
            i.purchaseOrder = i.purchaseOrder ? i.purchaseOrder : "-"
            i.totalPrice = formatPrice(i.totalPrice)
          });
        }
        this.onUpdateState({
          dataLists: responseData.itemList,
          totalCount: responseData.totalCount,
          setIsLoad: false
        });
        break;

      case "getordersById":
        if (responseData !== null) {
          responseData.cardLastFourNumber = responseData.cardLastFourNumber ? "XXXX " + responseData.cardLastFourNumber : "NA"
          responseData.stripeCustomerId = responseData.stripeCustomerId ? responseData.stripeCustomerId : "NA"

          let shippingAddress = responseData.orderAddressInformation.filter(a => a.addressTypeId === AddressType.ShippingAddress);
          let billingAddress = responseData.orderAddressInformation.filter(a => a.addressTypeId === AddressType.BillingAddress);
          if (responseData.orderAddressInformation.length === 2) { //When user have the same billing and shipping address
            billingAddress = responseData.orderAddressInformation[1];
            shippingAddress = responseData.orderAddressInformation[0];
          }
          else {
            shippingAddress = responseData.orderAddressInformation[0];
            billingAddress = responseData.orderAddressInformation[1]
          }
          let productsList = responseData.productList;
          let proTotal = responseData.productList.reduce((total, m) => total + m.orderPrice, 0).toFixed(2);
          let newDataList = productsList?.map((data) => ({
            additionalCharges: data.additionalCharges,
            aurumCatalogId: data.aurumCatalogId,
            cardHolderName: data.cardHolderName,
            cardLastFourNumber: data.cardLastFourNumber,
            cardProcessingCharges: data.cardProcessingCharges,
            casNo: data.casNo,
            companyName: data.companyName,
            confirmedDeliveryDate: data.confirmedDeliveryDate,
            currencySymbol: data.currencySymbol,
            designation: data.designation,
            discountPrice: data.discountPrice,
            expectedDeliveryDate: data.expectedDeliveryDate,
            firstName: data.firstName,
            handlingCharges: data.handlingCharges,
            isSymbolPrefix: data.isSymbolPrefix,
            lastName: data.lastName,
            mdlNo: data.mdlNo,
            note: data.note,
            orderDate: data.orderDate,
            orderId: data.orderId,
            orderNumber: data.orderNumber,
            orderPrice: formatPrice(data.orderPrice),
            orderShippingCharges: data.orderShippingCharges,
            orderStatus: data.orderStatus,
            orderStatusId: data.orderStatusId,
            orderedProductId: data.orderedProductId,
            paymentMethodId: data.paymentMethodId,
            paymentStatus: data.paymentStatus,
            paymentType: data.paymentType,
            phoneNo: data.phoneNo,
            price: formatPrice(data.price),
            productName: data.productName,
            productShippingCharges: data.productShippingCharges,
            purchaseOrder: data.purchaseOrder,
            purity: data.purity,
            quantity: data.quantity,
            reasonForRejection: data.reasonForRejection,
            refNumber: data.refNumber,
            shipmentNumber: data.shipmentNumber,
            shippingDate: data.shippingDate,
            shippingMethod: data.shippingMethod,
            OwnShippingProvider: data.OwnShippingProvider,
            OwnShippingNumber: data.OwnShippingNumber,
            tax: data.tax,
            totalPrice: data.totalPrice,
            units: data.units,
            userDisplayName: data.userDisplayName,
            userName: data.userName,
            size: data.units + " X " + data.quantity + data.size
          }));

          this.onUpdateState({
            orderDetails: responseData,
            packageList: responseData.packageList,
            shippingMethod: responseData.shippingMethod,
            orderShippingAddress: shippingAddress,
            orderBillingAddress: billingAddress,
            orderProducts: newDataList,
            productTotal: proTotal,
            setIsLoad: false
          });
        }
        else {
          this.apiService.swalServices.Toaster(responseData.message);
        }
        break;
      case "deleteOrder":
        if (responseData !== null) {
          this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "order"));
          this.onGetRequests(pagination)
        }
        break;
    }
  };
}
