import { Navigate } from "react-router-dom";
import BaseComponent from "../../shared/baseComponent";
import Button from "../../components/common/Button";
import ProductDetails from "../../components/productDetails/ProductDetails";
import PriceList from "../../components/productDetails/price/priceList";
import StockList from "../../components/productDetails/stock/stockList";
import ProductPropertyList from "../../components/productDetails/productProperty/productPropertyList";
import ProductSafetyList from "../../components/productDetails/productSafety/productSafetyList";
import DataNotFound from "../../components/DataNotFound";
import { ButtonTypes } from "../../utils/Constants";
import ProductDomainService from "../../services/domainServices/productDomainService";
import { checkParameters } from "../../utils/Encryption";

export default class NewProduct extends BaseComponent {
    constructor(props) {
        super(props)
        this.productService = new ProductDomainService();
        this.state = {
            priceList: [],
            setIsLoad: false,
            isBtnLoading: false,
            productsDetails: [],
            deliveryDetails: {
                deliveryTimeSpan: ""
            },
            productStockAndDeliveryTime: [],
            openTab: 1
        }
        this.handleChange = this.handleChange.bind(this);
        this.onPriceSubmit = this.onPriceSubmit.bind(this);
        this.onDeliveryTime = this.onDeliveryTime.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.productService.onUpdateState = this.updateState.bind(this);
    }


    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let aurumCatalogId = params.get("");
        if (aurumCatalogId && (aurumCatalogId = checkParameters(aurumCatalogId, "String"))) {
            this.productService.onGetProductById(aurumCatalogId);
            document.title = 'Product details';
        }
    }
    updateproductById() {
        this.productService.onUpdateProductById(this.state.productsDetails);
    }

    updateProductDeliveryTime = (deliveryAvailibilityId, deliveryTimeSpan) => {
        let request = {
            deliveryAvailibilityId: deliveryAvailibilityId,
            deliveryTimeSpan: deliveryTimeSpan,
        }
        this.productService.onUpdateDeliveryProduct(request);
    }

    // price
    updateProductPriceById = (priceId, price) => {
        let pricee = price.split('$')
        let request = {
            priceId: priceId,
            price: pricee[1],
        }
        this.productService.onUpdateProductPrice(request);
    }

    handleChange = (index, packQuantity) => {
        let quantityList = [...this.state.productStockAndDeliveryTime]
        quantityList[index].deliveryTimeSpan = packQuantity;
        this.setState({ deliveryDetails: quantityList });
    }
    handleChangePrice = (index, packQuantity, productSize) => {
        let quantityList = [...this.state.priceList]
        quantityList[index].price = packQuantity;
        this.setState({ deliveryDetails: quantityList });
    }

    // Other Function 
    setFormValues = (key, value) => {
        let newFormvalues = { ...this.state.productsDetails };
        newFormvalues[key] = value;
        this.setState({ productsDetails: newFormvalues });
    }
    onDeliveryTime = (deliveryAvailibilityId, deliveryTimeSpan) => {
        this.updateProductDeliveryTime(deliveryAvailibilityId, deliveryTimeSpan)
    }
    back = () => {
        this.setState({ redirect: "/ProductList" });
    }
    onPriceSubmit = (priceId, price) => {
        this.updateProductPriceById(priceId, price)
    }
    setOpenTab = (tab) => {
        this.setState({ openTab: tab });
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-4 w-full max-w-9xl mx-auto">
                            <div className='flex items-center justify-between mb-4'>
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Product Details</h1>
                                </div>
                                <Button type={ButtonTypes.Primary} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.back()} />
                            </div>
                            <ProductDetails
                                productStockAndDeliveryTime={this.state.productStockAndDeliveryTime}
                                handleChange={this.handleChange.bind(this)}
                                productsDetails={this.state.productsDetails}
                                onDeliveryTime={this.onDeliveryTime.bind(this)}
                                priceList={this.state.priceList}
                                handleChangePrice={this.handleChangePrice.bind(this)}
                                onPriceSubmit={this.onPriceSubmit.bind(this)}
                                form={this.productService.form}
                                formValues={this.state.productsDetails}
                                setFormValues={this.setFormValues.bind(this)}
                                isBtnLoading={this.state.isBtnLoading}
                                submitForm={this.updateproductById.bind(this)}
                                cancel={this.back.bind(this)}
                                setIsLoad={this.state.setIsLoad}
                            />
                        </div>
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto">
                            <div className="px-4 sm:px-6 lg:px-8  w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200 mb-5">
                                <div className='custom-tab-section'>
                                    <div>
                                        <div className="flex flex-wrap w-full border-b border-[#1e293b] mt-4">
                                            <div className="flex 2xl:justify-center lg:justify-center w-full ">
                                                <div
                                                    className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-12 gap-6 flex-row xl:w-full lg:w-full w-full mobile-view-tab-part" role="tablist">
                                                    <div
                                                        className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 last:mr-0 flex-auto text-center chapter-e-sec `}
                                                    >
                                                        <a
                                                            className={
                                                                this.state.openTab === 1
                                                                    ? "bg-[#fff9e5] text-black tab-background2 inner-title-font-small-button -button font-bold 2xl:p-4 lg:p-3 p-2 py-0 flex items-center justify-center h-full"
                                                                    : " inner-title-font-small-button font-bold py-2 text-[#1e293b]  flex items-center justify-center h-full"
                                                            }
                                                            onClick={(e) => { this.setOpenTab(1); }}
                                                            data-toggle="tab"
                                                            href="#."
                                                            role="tabdivst"
                                                        >
                                                            Price
                                                        </a>
                                                    </div>

                                                    <div
                                                        className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 mx-2 mr-2 last:mr-0 flex-auto text-center chapter-e-sec`}
                                                    >
                                                        <a
                                                            className={
                                                                this.state.openTab === 2
                                                                    ? "bg-[#fff9e5] text-black tab-background2 inner-title-font-small-button  font-bold 2xl:p-4 lg:p-3 p-2 flex items-center justify-center h-full"
                                                                    : " inner-title-font-small-button font-bold  py-2 text-[#1e293b]  flex items-center justify-center h-full"
                                                            }
                                                            onClick={(e) => { this.setOpenTab(2); }}
                                                            data-toggle="tab"
                                                            href="#."
                                                            role="tabdivst"
                                                        >
                                                            Stock
                                                        </a>
                                                    </div>

                                                    <div
                                                        className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 mx-2 mr-2 last:mr-0 flex-auto text-center chapter-e-sec`}
                                                    >
                                                        <a
                                                            className={
                                                                this.state.openTab === 3
                                                                    ? "bg-[#fff9e5] text-black tab-background2 inner-title-font-small-button  font-bold 2xl:p-4 lg:p-3 p-2 flex items-center justify-center h-full"
                                                                    : " inner-title-font-small-button font-bold py-2 text-[#1e293b]  flex items-center justify-center h-full"
                                                            }
                                                            onClick={(e) => { this.setOpenTab(3); }}
                                                            data-toggle="tab"
                                                            href="#."
                                                            role="tabdivst"
                                                        >
                                                            Property
                                                        </a>
                                                    </div>
                                                    <div
                                                        className={`2xl:col-span-1 lg:col-span-1 md:col-span-1 col-span-6 mx-2 mr-2 last:mr-0 flex-auto text-center chapter-e-sec `}
                                                    >
                                                        <a
                                                            className={
                                                                this.state.openTab === 4
                                                                    ? "bg-[#fff9e5] text-black tab-background2 inner-title-font-small-button  font-bold 2xl:p-4 lg:p-3 p-2 flex items-center justify-center h-full"
                                                                    : " inner-title-font-small-button font-bold py-2 text-[#1e293b]  flex items-center justify-center h-full"
                                                            }
                                                            onClick={(e) => { this.setOpenTab(4); }}
                                                            data-toggle="tab"
                                                            href="#."
                                                            role="tabdivst"
                                                        >
                                                            Safety Details
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.state.openTab === 1 ? "block" : this.state.openTab === 2 ? "block" : this.state.openTab === 3 ? "block" : this.state.openTab === 4 ? "block" : this.state.openTab === 5 ? "block" : "hidden"}
                                            id="link1">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-12 ">
                                                    <div className="user-details-section ">
                                                        <div className="py-4 relative h-full w-full min-h-[100px] ">
                                                            {
                                                                this.state.openTab === 1 ? (<PriceList />)
                                                                    : this.state.openTab === 2 ? (<StockList priceList={this.state.priceList} />)
                                                                        : this.state.openTab === 3 ? (<ProductPropertyList productsDetails={this.state.productsDetails} />)
                                                                            : this.state.openTab === 4 ? (<ProductSafetyList priceList={this.state.priceList} />)
                                                                                : <div className='py-12'><DataNotFound /></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
