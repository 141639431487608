/* Component  */

import react from "react"
import DemoPage from "../user/DemoPage";

function Report3() {
    return (
        <>
        <DemoPage />
        </>
    )
}
export default Report3;