/* Component  */

import React, { useState, useMemo } from 'react';

/* Library  */
import { Bars } from 'react-loader-spinner';


const defaultScopeAction = (element, value) => {

    return (value) ?
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`inline-flex font-medium rounded-full text-center activeColor px-2.5 py-0.5 `}>Active</div>
        </td >
        :
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`inline-flex font-medium rounded-full text-center  bg-red-500 px-2.5 py-0.5 `}>In Active</div>
        </td >
}

const availabilityAction = (element, value) => {

    return (
        <>
            {(() => {
                switch (value) {
                    case 1:
                        return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center activeColor px-2.5 py-0.5 `}><i className=" icon-sm fal fa-check-circle fw-bold mt-1 mr-1"></i> In Stock</div></td >
                    case 2:
                        return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center activeColor px-2.5 py-0.5 `}><i className=" icon-sm fal fa-history fw-bold mt-1 mr-1"></i> Backordered</div></td >
                    case 3:
                        return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center activeColor px-2.5 py-0.5 `}><i className="icon-sm fal fa-ban fw-bold mt-1 mr-1"></i> Suspended</div></td >
                    case 4:
                        return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center activeColor px-2.5 py-0.5 `}><i className=" icon-sm fal fa-conveyor-belt-alt fw-bold mt-1 mr-1"></i> In Production</div></td >
                    default:
                        return null
                }
            })()}
        </>
    )
}

const defaultScope = [
    {
        column: 'isActive',
        renderTableData: defaultScopeAction.bind(this)
    },
    {
        column: 'availabilityId',
        renderTableData: availabilityAction.bind(this)
    }
]

export function Rows(props) {
    return (
        props && props.columns && props.columns.map((element, index) => {
            return (
                (props.customScope && props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase()).length > 0) ?
                    props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].renderTableData(element.name, props.index, props.tableData[Object.keys(props.tableData).find(objKey => objKey.toLowerCase() === element.name.toLowerCase())],
                        props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].column1 ? props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].column1 : false,
                        props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].isViewRequired ? props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].isViewRequired : false,
                        props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].isDeleteRequired ? props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].isDeleteRequired : false)
                    :
                    (props.defaultScope && props.defaultScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase()).length > 0) ?
                        props.defaultScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].renderTableData(element.name, props.tableData[Object.keys(props.tableData).find(objKey => objKey.toLowerCase() === element.name.toLowerCase())])
                        :
                        <td className={(props.isCollapsedSection) ? "px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap pointer" : "px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap"} onClick={() => (props.isCollapsedSection) ? props.setCollapse(props.index, props.isCollapsed) : null}>
                            {props.tableData[Object.keys(props.tableData).find(objKey => objKey.toLowerCase() === element.name.toLowerCase())]}
                        </td>
            )
        })
    )
}

// Other function 

const Table = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * props.pagination.pageSize;
        const lastPageIndex = firstPageIndex + props.pagination.pageSize;
        return props.columns.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, props.columns, props.pagination.pageSize]);

    const [basePageNo, setBasePageNo] = useState(1);
    const [inputValue, setInputValue] = React.useState(props.pagination.pageNo);

    const handleSorting = (columnName, value) => {
        props.pagination.pageNo = 1;
        props.pagination.orderByColumn = columnName;
        props.pagination.orderFlag = (value) ? 1 : 0;
        props.setPagination(props.pagination);
    }

    const handlePageChange = (pageNumber, isButton = false) => {
        if (props.pagination.pageNo !== pageNumber && pageNumber > 0 && pageNumber <= Math.ceil(props.totalCount / props.pagination.pageSize)) {
            if (pageNumber > props.pagination.pageNo && (pageNumber - basePageNo) > 1) {
                setBasePageNo(pageNumber + 1);
            }
            else if (pageNumber < props.pagination.pageNo && pageNumber > 1 && (basePageNo - pageNumber) === 0) {
                setBasePageNo(pageNumber - 1);
            }
            else if (isButton && pageNumber > 1) {
                setBasePageNo(pageNumber - 1);
            }
            props.pagination.pageNo = pageNumber;
            props.setPagination(props.pagination);
        }
    };

    const onChangeHandler = (event) => {
        const re = /^[0-9\b]+$/;
        if (inputValue === "" || re.test(inputValue)) {
            setInputValue(inputValue);
            if (event.key === "Enter") {
                handlePageChange(Number(inputValue), true);
                // alert(`You must have pressed Enter - ${event.target.value} `);
            }
            // handlePageChange(Number(5), true);
            // handlePageChange(event.target.value + inputValue, true);
        }
    };

    return (
        <div className='table-container'>
            <div className='bg-[#fff] border-radius-10'>
                <div className='shadowColor p-5 mt-5 common-table '>
                    {(props.isLoading) ?
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: '0px' }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={props.isLoading}
                        />
                        :
                        <div className="overflow-x-auto">
                            <table className="table-auto bg-white rounded-md  w-full border-radius-10">
                                <thead className="text-[14px] capitalize text-black bg-[#fff9e5] sidemenucolor-thead rounded-sm">
                                    <tr>
                                        {(props && props.columns.map((value, key) => {
                                            return (
                                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="font-semibold text-left" key={key}>{value.title}
                                                        {(props.sortingColumns.includes(value.name)) ? (props.pagination.orderFlag === 1 && props.pagination.orderByColumn.toLowerCase() === value.name.toLowerCase()) ?
                                                            <i className="" ></i>
                                                            :
                                                            <i className=""></i>
                                                            :
                                                            null}
                                                    </div>
                                                </th>
                                            )
                                        }))}
                                    </tr>
                                </thead>
                                <tbody className={`text-sm text-left ${(props && props.rows && props.rows.length > 0) ? "" : "data-not-found-tbody"}`}>
                                    {(props && props.rows && props.rows.length > 0) ?
                                        props.rows.map((value, key) => {
                                            return (
                                                <>
                                                    <tr className='table-tr-custom '><Rows key={key} columns={props.columns} tableData={value}
                                                        customScope={props.customScope} defaultScope={defaultScope} index={key} setCollapse={props.setCollapse}
                                                        isCollapsedSection={props.isCollapsedSection}
                                                        isCollapsed={value.isCollapsed} /></tr>
                                                    {(value.isCollapsed) ?
                                                        props.collapsibleSection(value)
                                                        : null
                                                    }
                                                </>
                                            )
                                        })
                                        :
                                        <tr colSpan={props.columns.length} className='no-data-found p-4 d-flex justify-center items-center'>
                                            {/* <td colSpan={props.columns.length} className="text-center p-4"> */}
                                                <i className="fas fa-empty-set pr-2"></i>No Records Found
                                            {/* </td> */}
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            {/* {(props && props.rows && props.rows.length > 0) ?
                            <div className=" pagination-table mt-5 mb-5">
                                <div className="text-sm text-slate-500 text-center sm:text-left mb-4 textCenter">
                                    Showing <span className="font-medium text-slate-600">{((props.pagination.pageNo * props.pagination.pageSize) - props.pagination.pageSize) + 1}</span> to <span className="font-medium text-slate-600">{props.pagination.pageNo * props.pagination.pageSize}</span> of <span className="font-medium text-slate-600">{props.totalResultes}</span> results
                                </div>
                                <nav className="flex sm:justify-center " role="navigation" aria-label="Navigation">
                                    <div className="grid grid-flow-col  justify-center  mb-1">
                                        <a className={`inline-flex items-center  justify-center  leading-5 h-[50px] w-[50px] border mr-2 ${(props.pagination.pageNo > 1) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} disabled={(props.pagination.pageNo > 1) ? false : true} onClick={() => { handlePageChange(1, true); setBasePageNo(1) }}>&lt;&lt;</a>
                                        <a className={`inline-flex items-center  justify-center leading-5 h-[50px] w-[50px] border mr-2 ${(props.pagination.pageNo > 1) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} disabled={(props.pagination.pageNo > 1) ? false : true} onClick={() => handlePageChange(props.pagination.pageNo - 1, true)}>&lt;</a>
                                        <ul className="inline-flex text-sm font-medium -space-x-px pointer">
                                            <li>
                                                <a className={`inline-flex items-center  justify-center leading-5 h-[50px] w-[50px] mr-2 border ${props.pagination.pageNo === props.pagination.pageNo ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} onClick={() => handlePageChange(props.pagination.pageNo)}><span className="">{props.pagination.pageNo}</span></a>
                                            </li>
                                            {Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo ?
                                                <li>
                                                    <a className={`inline-flex items-center justify-center leading-5 h-[50px] w-[50px] mr-2 border ${props.pagination.pageNo === (props.pagination.pageNo + 1) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} onClick={() => handlePageChange(props.pagination.pageNo + 1)}><span className="">{props.pagination.pageNo + 1}</span></a>
                                                </li>
                                                : null}
                                            {Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo + 1 ?
                                                <li>
                                                    <a className={`inline-flex items-center justify-center leading-5 h-[50px] w-[50px] mr-2 border ${props.pagination.pageNo === (props.pagination.pageNo + 2) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} onClick={() => handlePageChange(props.pagination.pageNo + 2)}><span className="">{props.pagination.pageNo + 2}</span></a>
                                                </li>
                                                : null}
                                            {Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo + 2 ?
                                                <>
                                                    <li>
                                                        <span className="inline-flex items-center justify-center leading-5 h-[50px] w-[50px] mr-2 text-slate-400">…</span>
                                                    </li>
                                                    <li>
                                                        <a className={`inline-flex items-center justify-center leading-5 h-[50px] w-[50px] mr-2 border mr-4 ${props.pagination.pageNo === (Math.ceil(props.totalCount / props.pagination.pageSize)) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} onClick={() => handlePageChange(Math.ceil(props.totalCount / props.pagination.pageSize) - 1)}><span className="">{Math.ceil(props.totalCount / props.pagination.pageSize)}</span></a>
                                                    </li>
                                                </>
                                                : null}
                                        </ul>
                                        <a className={`inline-flex items-center justify-center leading-5 h-[50px] w-[50px] border mr-2 ${(Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} disabled={Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo ? false : true} onClick={() => handlePageChange(props.pagination.pageNo + 1, true)}>&gt;</a>
                                        <a className={`inline-flex items-center justify-center leading-5 h-[50px] w-[50px] border mr-2 ${(Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} disabled={Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo ? false : true} onClick={() => handlePageChange(Math.ceil(props.totalCount / props.pagination.pageSize), true)}>&gt;&gt;</a>
                                    </div>
                                </nav>
                            </div>
                            : null} */}

                            {props && props.rows && props.rows.length > 0 ? (
                                <div className="mt-5 pagination-table mb-5">
                                    <div className="table-pagination">
                                        <div className="text-sm text-slate-500 text-center sm:text-left mb-4 textCenter">
                                            Showing <span className="font-medium text-slate-600">{((props.pagination.pageNo * props.pagination.pageSize) - props.pagination.pageSize) + 1}</span> to <span className="font-medium text-slate-600">{props.pagination.pageNo * props.pagination.pageSize}</span> of <span className="font-medium text-slate-600">{props.totalResultes}</span> results
                                        </div>
                                        <div className="flex gap-2 justify-center items-center">
                                            <button className={`page-link ${(props.pagination.pageNo > 1) ? "border-slate-200 new-color-hover shadow-sm rounded-full" : "page-link-active cursor-not-allowed"}`} disabled={(props.pagination.pageNo > 1) ? false : true} onClick={() => { handlePageChange(1, true); setBasePageNo(1) }}>
                                                <span className='text-dark'>&lt;&lt;</span>
                                            </button>
                                            <button
                                                className={`${props.pagination.pageNo > 1
                                                    ? "active-button table_paginations "
                                                    : "inactive-button table_paginations"
                                                    }`}
                                                onClick={() =>
                                                    handlePageChange(props.pagination.pageNo - 1, true)
                                                }
                                                disabled={props.pagination.pageNo > 1 ? false : true}
                                            >
                                                <button
                                                    type="button"
                                                    className="page-link page-link--with-arrow pagination_svg"
                                                    aria-label="Previous"
                                                >
                                                    <span className='text-dark'>&lt;</span>
                                                </button>{" "}
                                            </button>
                                            <ul className="flex items-center">
                                                <li>
                                                    <input
                                                        className="pagination-input form-control"
                                                        type="text"
                                                        name="name"
                                                        onChange={(event) =>
                                                            setInputValue(event.target.value)
                                                        }
                                                        onKeyPress={(e) => onChangeHandler(e)}
                                                        value={inputValue}
                                                    />
                                                </li>

                                                <span className="ml-5 mr-5">
                                                    Of
                                                </span>

                                                <li>
                                                    <input
                                                        className="pagination-input-disabled form-control cursor-not-allowed"
                                                        disabled
                                                        value={Math.ceil(
                                                            props.totalCount / props.pagination.pageSize
                                                        )}
                                                    />
                                                </li>
                                            </ul>
                                            <button
                                                className={`${Math.ceil(
                                                    props.totalCount / props.pagination.pageSize
                                                ) > props.pagination.pageNo
                                                    ? "active-button"
                                                    : "inactive-button"
                                                    }`}
                                                onClick={() =>
                                                    handlePageChange(props.pagination.pageNo + 1, true)
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="page-link pagination_svg"
                                                    aria-label="Next"
                                                >
                                                    <span className='text-dark'>&gt;</span>
                                                </button>
                                            </button>
                                            <button className={`page-link ${(Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo) ? "border-slate-200 new-color-hover shadow-sm rounded-full" : "page-link-active cursor-not-allowed"}`} disabled={Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo ? false : true} onClick={() => handlePageChange(Math.ceil(props.totalCount / props.pagination.pageSize), true)}>
                                                <span className='text-dark'>&gt;&gt;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className='pagination-table mt-1'>
                                {/* <Pagination
                                className="pagination"
                                currentPage={currentPage}
                                totalCount={props.columns.length}
                                pageSize={props.pagination.pageSize}
                                onPageChange={page => setCurrentPage(page)}
                            /> */}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Table;
