import BaseDomainService from "./baseDomainServices";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";

export default class productdashboardService extends BaseDomainService {
    getproductCountList = this.apiService.productDashServices.admin_GetProductDashboardCounts;

    ProductListColmns = [
        { name: "totalBackordered", title: "Total Backordered" },
        { name: "totalInProduction", title: "Total In Production " },
        { name: "totalInStock", title: "Total In Stock" },
        { name: "totalSuspended", title: "Total Suspended" },
    ]

    constructor() {
        super();
        // this.onPaginationUpdate = this.onGetproductCountList.bind(this);
    }

    //** Service Event */
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    //** API Calls

    onGetproductCountList = (requst) => {
        this.apiService.onAPICall("getproductCountList", this.getproductCountList, requst, this.onAPIResponse, this.onUpdateState);
    };


    onRedirectoList = () => {
        this.onRedirect("/");
    }

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "getproductCountList":
                if (responseData !== null) {

                    let TotalCount = responseData[0]
                    let InActiveCount = responseData[0]
                    let TotalBackordered = responseData[0]
                    let TotalInProduction = responseData[0]
                    let TotalInStock = responseData[0]
                    let TotalSuspended = responseData[0]
                    this.onUpdateState({
                        ProductCountList: responseData,
                        ProductCount: TotalCount,
                        ProductInActiveCount: InActiveCount,
                        TotalBackordered: TotalBackordered,
                        TotalInProduction: TotalInProduction,
                        TotalInStock: TotalInStock,
                        TotalSuspended: TotalSuspended,
                        setIsLoad: false
                    });
                }
                this.onUpdateState({
                    setIsLoad: false
                })
                break;
        }
    };
}

