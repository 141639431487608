import React from "react";

const SelectField = (request) => {
    let props = request.request;
    let column = props.column;
    return (<>
        <select
            className="form-input w-full AdjustForm"
            value={props.value}
            onChange={props.onChange}
            id={props.column.fieldName}
            name={props.column.fieldName}>
            {props.column.placeholder ? <option value="" disabled>{props.column.placeholder}</option> : null}
            {props.options?.map((optionList, index) => (
                <>
                    <option key={index} value={optionList.id} name={optionList.name}>
                        {optionList.name}
                    </option>
                </>
            ))}
        </select>
    </>)
}


export default SelectField;
