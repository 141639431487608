import React from "react";
import { ButtonTypes } from "../../utils/Constants";
import { Bars } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import Button from "../../components/common/Button";
import TinyEceEditor from "../../components/TinyEceEditor";
import emailServices from "../../services/domainServices/emailServices";
import FormFieldGroup from "../../components/formGroupFields/FormFieldGroup";
import BaseComponent from "../../shared/baseComponent";
import ValidationText from "../../utils/validation/ValidationText";
import ButtonGroup from "../../components/common/ButtonGroup";


export default class AddEmailTemplate extends BaseComponent {
  constructor(props) {
    super(props);
    this.emailService = new emailServices();
    this.state = {
      ...this.state,
      emailDetails: this.emailService.dataItem,
      resetValidationRule: {},
      isBtnLoading: false,
      isLoading: false,
      redirect: null,
      setIsLoad: false
    };
    this.emailService.onUpdateState = this.updateState.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  handleChange(name, value) {
    let detail = this.state.emailDetails;
    detail[name] = value;
    this.setState({ emailDetails: { ...detail } });
  }

  handleChangeDefault = (e) => {
    const { name, checked } = e.target;
    let detail = this.state.emailDetails;
    detail[name] = checked;
    this.setState({ text: "" });
  };

  onAddEmailTemplate = () => {
    if (this.emailService.isValidSubmit(this.state.emailDetails, this.state.validationRules, this.state.validState)) {
      this.setState({ isLoading: true });
      let request = {
        emailTemplateName: this.state.emailDetails.emailTemplateName,
        subject: this.state.emailDetails.subject,
        emailBody: this.state.emailDetails.emailBody,
        isActive: this.state.emailDetails.isActive
      }
      this.emailService.onAddEmailDetails(request);
    }
  };

  submitForm = () => { };

  onChange(event) {
    var newContent = event.editor.getData();
    let detail = this.state.emailDetails;
    detail["emailBody"] = newContent;
    this.setState({ emailDetails: { ...detail } });
  }

  componentDidMount() {
    this.emailService.onInitService();
    document.title = "Email Template";
  }

  cancel = () => {
    this.setState({ redirect: "/EmailHistory" });
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  reset = () => {
    this.setState({
      emailDetails: "",
    });
  };

  setDescription = (data) => {
    if (typeof data === "string") {
      let detail = this.state.emailDetails;
      detail["emailBody"] = data;
      this.setState({ emailDetails: { ...detail } });
    }
  };
  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            {this.state.setIsLoad ? (
              <Bars
                className="row"
                wrapperStyle={{ marginTop: "150px" }}
                color="#3c4b64"
                height={55}
                width="100%"
                visible={this.state.setIsLoad}
              />
            ) : (
              <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                      Add Email Template
                    </h1>
                  </div>
                  <Button
                    type={ButtonTypes.Primary}
                    title={<i className="fas fa-arrow-left"></i>}
                    buttonClick={() => this.emailService.onRedirectoList()}
                  />
                </div>
                <div className="col-span-full xl:col-span-8 border bg-white shadow-lg rounded-sm mb-3 ">
                  <div className="grid mb-3">
                    <div className="col-span-full xl:col-span-8  rounded-sm  ">
                      <div className="px-4 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                        <div className="space-y-8">
                          <div>
                            <div className="grid gap-5 md:grid-cols-3">
                              {this.emailService.formFields.map(
                                (Item, index) => {
                                  return (
                                    <>
                                      {Item.emailTemplate != true ? (
                                        <FormFieldGroup
                                          column={Item}
                                          value={this.state.emailDetails[Item.fieldName]}
                                          error={this.state.validState.error[Item.fieldName]}
                                          onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                          onBlur={() => this.emailService.validateField(Item.fieldName, this.state.emailDetails, this.state.validationRules, this.state.validState)}
                                          key={index}
                                        />
                                      ) : null}
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm mb-3 mt-5">
                  <div className="grid mb-3">
                    <div className="col-span-full xl:col-span-8  rounded-sm  ">

                      <TinyEceEditor
                        className="rounded-none"
                        key={"fullPageCKEditor"}
                        onChange={(data) => {
                          this.setDescription(data);
                        }}

                        onBlur={() => {
                          this.emailService.validateField("emailBody", this.state.emailDetails.emailBody, this.state.validationRules, this.state.validState);
                        }}
                      />
                      {
                        this.state.emailDetails.emailBody ?
                          null :

                          <ValidationText
                            error={this.state.validState.error.emailBody}
                          />

                      }
                      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mr-1">
                          <label className="flex items-center">
                            {this.emailService.formFields.map((Item, index) => {
                              return (
                                <>
                                  {Item.checked ? (
                                    <FormFieldGroup
                                      column={Item}
                                      value={this.state.emailDetails[Item.fieldName]}
                                      onChange={(event) => this.handleChange(Item.fieldName, event.target.checked)}
                                      checked={this.state.emailDetails[Item.fieldName]}
                                      key={index}
                                    />
                                  ) : null}
                                </>
                              );
                            }
                            )}
                            <span className="text-sm ml-2">
                              <b>IsActive </b>
                            </span>
                          </label>
                        </div>
                        <div className="flex flex-wrap mt-4 sm:justify-end justify-center">
                          <ButtonGroup
                            onCancelClick={(e) => this.emailService.onRedirectoList(e)}
                            onAddClick={(e) => this.onAddEmailTemplate(e)}
                            isLoading={this.state.isLoading}

                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    );
  }
}
