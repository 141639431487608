
/* Component  */

import React from 'react'
import Button from '../../components/common/Button';

/* Library  */

import CKEditor from 'react-ckeditor-component';
import { ButtonTypes } from '../../utils/Constants';

const ContactusResponse = (props) => {
    return (
        <div className="m-1.5">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Send Response </h1>
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                    <button className="btn commanColorbtn"
                        onClick={(e) => { e.stopPropagation(); props.handleContactModal(true); }}>
                        <i className="fas fa-info-circle  cursor-pointer"></i>
                    </button>
                </div>
            </div>
            <div className="pr-2 sm:pr-3 lg:pr-4 w-full max-w-9xl mx-auto">
                <div className="border-slate-200">
                    <div className="space-y-8 mt-8 mb-8">
                        <div>
                            <div className="grid gap-5 md:grid-cols-4">
                                <div>
                                    <input type='email' name='toEmail' className='form-input' placeholder='To :' onChange={(event) => props.handleEmailIdsResponse(event)} />
                                </div>
                                <div>
                                    <input type='email' name='ccEmail' className='form-input' placeholder='Cc :' onChange={(event) => props.handleEmailIdsResponse(event)} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="grid gap-5 md:grid-cols-1">
                                <div>
                                    <label className="block text-sm font-medium mb-1">Send Response</label>
                                    <CKEditor
                                        activeClass="p0"
                                        content={`${props.contactResponse.responseBody}                                         
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" style=" padding:10px 0px;">
                                        <tr>
                                            <td style="font-family: Arial, Helvetica, sans-serif; font-size:20px; line-height: 22px; color: #000; font-weight: bold; text-align: left;">
                                                Aurum Pharmatech, LLC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family: Arial, Helvetica, sans-serif; font-size: 12.2px; line-height: 14px; color: #000; font-weight: normal; text-align: left;">
                                                P.O. Box 36
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family: Arial, Helvetica, sans-serif; font-size: 12.2px; line-height: 14px; color: #000; font-weight: normal; text-align: left;">
                                                Franklin Park, NJ 08823, USA
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-family: Arial, Helvetica, sans-serif; font-size: 12.2px; line-height: 14px; color: #000; font-weight: normal; text-align: left;">
                                                <p style="margin: unset;">
                                                    Phone:<a href="tel:1-888-614-9970" style="text-decoration:underline;color:#fd961f; font-family: 'Nunito Sans', sans-serif; font-size: 12.2px; font-weight: bold;" target="_blank">
                                                    1-888-614-9970 </a> &nbsp;|&nbsp;Fax: <a href="mailto:1-855-534-2087" style="text-decoration:underline;color:#fd961f; font-family: 'Nunito Sans', sans-serif;font-size: 12.2px; font-weight: bold;"
                                                        target="_blank">1-855-534-2087</a>&nbsp;|&nbsp;<br class="mobile-block">Email: <a href="mailto:sales@aurumpharmatech.com" style="text-decoration:underline;color:#fd961f; font-family: 'Nunito Sans', sans-serif;font-size: 12.2px; font-weight: bold;"
                                                        target="_blank">sales@aurumpharmatech.com</a>&nbsp;|&nbsp;<br class="mobile-block">Web:
                                                    <a href="www.aurumpharmatech.com" style="text-decoration:underline;color:#fd961f; font-family: 'Nunito Sans', sans-serif;font-size: 12.2px; font-weight: bold;" target="_blank">www.aurumpharmatech.com
                                                    </a>
                                                </p>
                                            </td>
                                        </tr>
                                    </table> `}
                                        events={{
                                            "change": (e) => props.onChange(e)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="grid gap-5 md:grid-cols-1">
                                <div>
                                    <Button type={ButtonTypes.Primary}
                                        title="Send Response"
                                        isLoading={props.isBtnLoading}
                                        buttonClick={props.addContactUsResponse} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactusResponse
