/* Component  */

import React from "react";

function DemoPage() {
  return (
    <>
      <div className="container under-construction-sec demopage">
        <div className="card border-0 shadow-lg py-5 my-5 rounded-lg h-full bg-white">
          <div className="card-body">
            <h1 className="text-center absolute top-[30%] left-[25%] text-[35px] text-[#6c6e70]">
              <b>
                {" "}
                This Page is Currently <br />{" "}
                <span className="text-[35px]">Under Construction</span>
              </b>
            </h1>
            <img
              className="m-5"
              src="./undercunstruction.png"
              alt="img not found"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoPage;
