/* Component  */
import { axiosPost } from '../AxiosRequests';
import {  GetAllCompaniesList } from '../ApiEndPoints';


export default class CompaniesServices {

   // API function

    async getAllCompaniesList(request) {
        return axiosPost(GetAllCompaniesList, request);
    }
}