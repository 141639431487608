import React from 'react';

/* Lib  */
import ModalBasic from '../../ModalBasic';
import BaseComponent from '../../../shared/baseComponent';
import FormFieldGroup from '../../formGroupFields/FormFieldGroup';

//** Service */
import ProductPropertyDomainService from '../../../services/domainServices/ProductPropertyDomainService';
import ButtonGroup from '../../common/ButtonGroup';

export class PropertyModal extends BaseComponent {

    constructor(props) {
        super(props)
        this.productPropertyService = props.productPropertyService;
        this.productPropertys = new ProductPropertyDomainService();
        this.productPropertys.onUpdateState = this.updateState.bind(this);
    }
    //** Constructor  and Component method */
    componentDidMount() {
        this.productPropertys.onInitPropertyService();
    }

    render() {
        return (
            <ModalBasic id="basic-modal_1" modalOpen={this.props.openStateModal} setModalOpen={this.props.modalOpen} title={this.props.updateProperty ? "Update ProductProperties" : "Add ProductProperties"}>
                <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
                    <div className="border-slate-200">
                        <div className="space-y-8 mt-8 pb-5">
                            <div className="grid gap-5 md:grid-cols-3">
                                {this.productPropertys.productPropertyForm.map((Item, index) => {
                                    return (
                                        <FormFieldGroup
                                            column={Item}
                                            options={Item.options ? Item.options : null}
                                            value={this.props.productPropertiesData.length >= 1 && this.props.updateProperty ? this.props.updatePS[Item.fieldName] : this.props.productPropertiesData[Item.fieldName]}
                                            error={this.props.validState.error[Item.fieldName]}
                                            onBlur={() => {
                                                this.props.updateProperty === true ? this.props.validateField(Item.fieldName, this.props.updatePS, this.props.validationRules, this.props.validState) :
                                                    this.props.validateField(Item.fieldName, this.props.product, this.props.validationRules, this.props.validState)
                                            }}
                                            onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                            onChange={(event) => { this.props.updateProperty === true ? this.props.handleChangeupdate(Item.fieldName, event.target.value, this.props.propertyIndexList) : this.props.handleChange(Item.fieldName, event.target.value, this.props.propertyIndexList) }} key={index}

                                        />
                                    );
                                })}
                            </div>
                            <div className="flex flex-wrap justify-center mt-3 mb-4">
                                <ButtonGroup
                                    onCancelClick={(e) => this.props.onCloseModal(e)}
                                    onUpdateClick={(e) => this.props.updatePropertyDetails(e)}
                                    onAddClick={(e) => this.props.addPropertyDetails(e)}
                                    updateState={this.props.updateProperty}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBasic>
        )
    }
}

export default PropertyModal;