/* Component  */
import { axiosGet, axiosPost } from '../AxiosRequests';
import { GetErrorLogList, UpdateErrorLogStatus ,GetErrorLogsById} from '../ApiEndPoints';

export default class ExceptionServices {
    // API function

    async getErrorLogList(request) {
        return axiosPost(GetErrorLogList, request);
    }
    async updateErrorLogStatus(request) {
        return axiosPost(UpdateErrorLogStatus, request);
    }
    async getErrorLogsById(request) {
        return axiosGet(GetErrorLogsById, request);
    }

}
