
/* Component  */
import React, { Component } from 'react';
import Button from '../../components/common/Button';
import Table from '../../components/tables/Table';

/* Library  */
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { ButtonTypes } from '../../utils/Constants';

/* Services  */
import SwalServices from '../../services/swalServices/SwalServices';
import ShoppingCartServices from '../../services/axiosServices/apiServices/ShoppingCartServices';

export default class ShoppingCarts extends Component {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.getShoppingCartUser = new ShoppingCartServices();
    this.swalServices = new SwalServices();
    this.state = {
      getshoppingcart: [],
      searchingText: "",
      setSidebarOpen: false,
      setSelectedItems: [],
      totalResultes: '',
      setBasicModalOpen: false,
      isLoading: false,
      setuserId: '',
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedDate",
        orderFlag: 1,
        searchText: "",
      }
    }
  }

  // Other Function 

  handleSelectedItems = (selectedItems) => {
    this.setState({ setSelectedItems: selectedItems })
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getShoppingCartProductsByUser(this.state.setuserId)
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let userId = params.get("");
    this.getShoppingCartProductsByUser(userId);
    this.setState({ setuserId: userId })
    document.title = 'Shopping Cart';

  }

  cancel = () => {
    this.setState({ redirect: "/UserList" });
  }


  // API function 

  getShoppingCartProductsByUser = (id) => {
    let request = {
      pageNo: 1,
      pageSize: 10,
      orderByColumn: "SearchDate",
      orderFlag: 1,
      searchText: "",
      id: id
    }
    this.setState({ isLoading: true });
    this.getShoppingCartUser.admin_GetShoppingCartProductsByUser(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getshoppingcart: response.responseItem.responseContent });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
      }
      else {
        this.swalServices.Toaster(response.message);
      }
      this.setState({ isLoading: false });
    })
  }


  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-8 w-full max-w-9xl mx-auto">
              <Button type={ButtonTypes.Cancel} buttonClick={() => this.cancel()} title={<i className="fas fa-arrow-left"></i>} />
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Shopping Cart History </h1>
                </div>
              </div>
              {
                (this.state.isLoading) ?
                  <Bars
                    className="row"
                    wrapperStyle={{ marginTop: '150px' }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.isLoading}
                  />
                  :
                  <Table
                    columns={[{ name: 'productName', title: 'Product Name' }, { name: 'size', title: 'size ' }, { name: 'price', title: 'price ' }, { name: 'quantityAmount', title: 'quantity ' }, { name: 'aurumCatalogId', title: 'Catalog' }, { name: 'casNo', title: 'CAS No' }, { name: 'mdlNo', title: 'mdlNo' }, { name: 'availabilityId', title: 'availability' }]}
                    rows={this.state.getshoppingcart}
                    sortingColumns={["productName", "aurumCatalogId", "casNo", "mdlNo", "price", "quantityAmount", "size"]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                  />
              }
            </div>
          </main>
        </div>
      </div>
    )
  }
}
