/* Component  */
import { axiosPostAuthorize,axiosGetAuthorize} from '../AxiosRequests';
import { Admin_GetDashboardCounts, Admin_GetRecentRFQ, Admin_GetRecentsOrders, Admin_GetRecentUsers, Admin_GetRecentContactUs } from '../ApiEndPoints';

export default class DashboardServices {
// API function

    async admin_GetDashboardCounts(request) {
        return axiosGetAuthorize(Admin_GetDashboardCounts, request);
    }

    async admin_GetRecentRFQ(request) {
        return axiosPostAuthorize(Admin_GetRecentRFQ, request);
    }

    async admin_GetRecentsOrders(request) {
 
         return axiosPostAuthorize(Admin_GetRecentsOrders, request);

    }

    async admin_GetRecentUsers(request) {
        return axiosPostAuthorize(Admin_GetRecentUsers, request);
    }

    async admin_GetRecentContactUs(request) {
        return axiosPostAuthorize(Admin_GetRecentContactUs, request);
    }
}