/* Component  */
import React, { Component } from "react";
import Unauthorize from '../../images/unauthorizepage1.jpg';
import { signOut } from '../../utils/AuthenticationLibrary';

export default class UnAuthorized extends Component {
  render() {
    return (
      <div className=" unauthorizecard mt-5">
        <div className="container">
          <div className=" p-5 ">
            <div className="flex items-center flex-col justify-center">
              <img className="mt-5" src={Unauthorize} width="832" height="832" alt="User" />
              <button className="btn btn-primary  p-5 py-5 mt-2" onClick={() => signOut()}>
                Back to Login Page</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}