import React from 'react';

//** Lib */
import PriceModal from './PriceModal';
import { Bars } from 'react-loader-spinner';
import BaseComponent from '../../../shared/baseComponent';
import { checkParameters } from '../../../utils/Encryption';
import FormFieldGroup from '../../formGroupFields/FormFieldGroup';

//** Service */
import PriceDomainService from '../../../services/domainServices/PriceDomainService';
import { ErrorMessage } from '../../../utils/Messages';

export class PriceList extends BaseComponent {

    constructor(props) {
        super(props)
        this.priceService = new PriceDomainService();
        this.state = {
            ...this.state,
            priceIndex: "",
            isUpdate: false,
            setOpenModal: false,
            setIsLoad: false,
            priceLists: [],
            getAllCurrencies: [],
            size: [],
            pdId: 0,
            addPriceList: this.priceService.dataItem,
        }
        this.priceService.onUpdateState = this.updateState.bind(this);
    }

    openModal = (event, index) => {
        event.stopPropagation();
        if (index !== '') {
            this.priceService.onResetData()
            this.setState({
                setOpenModal: true,
                isUpdate: true,
                priceIndex: index
            });
        }
        else {
            this.priceService.onResetData()
            this.setState({
                setOpenModal: true,
                isUpdate: false,
                priceIndex: ''
            });
        }
    }
    componentDidMount() {
        this.priceService.onInitService();
        let params = new URLSearchParams(window.location.search);
        let productId = params.get("");
        if (productId != '') {
            productId = checkParameters(productId, "String")
            this.priceService.onGetPriceDetailsByProductId(productId);
            this.setState({ pdId: productId })
            document.title = 'Product details';
        }
        this.priceService.onGetSizes();
        this.priceService.onGetAllCurrencies();
    }
    onCloseModal = (event) => {
        // event.stopPropagation();
        this.setState({ setOpenModal: false });
        this.priceService.onResetData();
    }
    handleChangeupdate(name, value, index) {
        let detail = this.state.priceLists[index];
        detail[name] = value;
        this.state.priceLists[index] = detail;
        let obj = this.state.priceLists
        this.setState({ priceLists: obj });
    }
    // *** handleChange function **//
    handleChange(name, value) {
        let details = this.state.addPriceList;
        details[name] = value;
        this.setState({ addPriceList: details });
    }
    addPrice = (e) => {
        let request = {
            productId: this.state.pdId,
            sizeId: this.state.addPriceList.sizeId,
            price: this.state.addPriceList.price,
            currencyId: this.state.addPriceList.currencyId,
            quantity: this.state.addPriceList.quantity,
            createdBy: this.priceService.authUser.adminUserId,
        }
        if (this.priceService.isValidSubmit(this.state.addPriceList, this.state.validationRules, this.state.validState)) {
            this.priceService.onAddPrice(request, this.state.pdId);
        }
    }
    updateProductPriceById = (priceId, price, e, index) => {
        let request = {
            priceId: priceId,
            price: this.state.priceLists[index].price,
            sizeId: this.state.priceLists[index].sizeId,
            currencyId: this.state.priceLists[index].currencyId,
            quantity: this.state.priceLists[index].quantity,
            productId: this.state.priceLists[index].productId,
        }
        this.priceService.onUpdateProductPrice(request, this.state.pdId);
    }
    DeletePrice = (priceId) => {
        this.priceService.apiService.swalServices.Confirm("Delete Warning", "Are you sure, you want to Delete Price?", "Yes", "No")
            .then(async confirm => {
                if (confirm) {
                    let request = {
                        productId: this.state.pdId,
                        priceId: priceId,
                        deletedBy: this.priceService.authUser.adminUserId,
                    };
                    this.priceService.ondeleteProductPriceById(request, this.state.pdId);
                }
            });
    }

    saveAllPrice() {
        let isValid = true;
        let isValidQuantity = true;
        let priceListStore = this.state.priceLists;

        for (let obj of priceListStore) {
            if (obj.quantity === '' || obj.quantity === 0 || obj.quantity === '0' || obj.quantity === null) {
                this.priceService.apiService.serviceManager.swalServices.Error(ErrorMessage.empty.replace("{0}", "Quantity"));
                isValidQuantity = false;
                break;
            }

            if (obj.price === '' || obj.price === 0 || obj.price === null) {
                this.priceService.apiService.serviceManager.swalServices.Error(ErrorMessage.empty.replace("{0}", "Price"));
                isValid = false;
                break;
            }
        }

        if (isValid && isValidQuantity) {
            let request = {
                productId: this.state.pdId,
                ProductPriceList: priceListStore
            }
            this.priceService.onUpdateAllPriceList(request);
        } else {
            return false;
        }

    }


    render() {
        return (
            <>
                <div className='flex flex-wrap float-right2 mt-3 mb-4 '>
                    <button className='btn buttonDataImport Secondary-button transition-all text-white mr-5'
                        onClick={() => this.saveAllPrice()}>
                        Save Price Details
                    </button>
                    <button className='btn buttonDataImport commanColorbtn transition-all ms-3'
                        onClick={(e) => this.openModal(e, '')}>Add</button>
                </div>
                <div className="pagination-remove fixed_header_dashboard_pricelist">
                    <table className="w-full bg-white shadow-lg rounded-md fixed_header mt-5">
                        <thead className="text-[14px] capitalize text-black bg-[#fff9e5] sidemenucolor-thead rounded-sm">
                            <tr className="flex">
                                <th className="p-3 whitespace-nowrap lg:w-[100%] w-[100px]">
                                    <div className="font-bold text-left">
                                        Quantity X
                                    </div>
                                </th>

                                <th className="p-3 whitespace-nowrap lg:w-[100%] w-[100px]">
                                    <div className="font-bold text-left">
                                        Pack Size
                                    </div>
                                </th>
                                <th className="p-3 whitespace-nowrap lg:w-[100%] w-[100px]">
                                    <div className="font-bold text-left">
                                        Price
                                    </div>
                                </th>
                                <th className="p-3 whitespace-nowrap lg:w-[100%] w-[100px]">
                                    <div className="font-bold text-left">
                                        Currency
                                    </div>
                                </th>
                                <th className="p-3 whitespace-nowrap lg:w-[100%] w-[100px]">
                                    <div className="font-bold text-left">
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-sm font-medium ">
                            {(this.state.setIsLoad) ?
                                < div className='Bars_details'>
                                    <Bars
                                        className="row"
                                        wrapperStyle={{ marginTop: '150px' }}
                                        color="#3c4b64"
                                        height={55}
                                        width="100%"
                                        visible={this.state.setIsLoad}
                                    />
                                </div>
                                :
                                <>
                                    {this.state.priceLists && this.state.priceLists.length > 0 ? this.state.priceLists.map((item, index) =>
                                        <tr className="table-tr-custom flex items-center">
                                            <td className="px-3 py-2 whitespace-nowrap text-left text-[#1e293b] lg:w-[100%] w-[100px]">
                                                <input value={item.quantity} className="p-2 input-text-box" onKeyDown={(e) => /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()}
                                                    onChange={(event) => { this.handleChangeupdate(event.target.name, event.target.value, index) }} name="quantity" />&nbsp; X &nbsp;
                                            </td>
                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b] lg:w-[100%] w-[100px]">
                                                {this.priceService.productsizeId.map((Item, sizeIndex) => {
                                                    return (
                                                        <FormFieldGroup
                                                            column={Item}
                                                            options={Item.options ? Item.fieldName === 'sizeId' ? this.state.size : this.state.getAllCurrencies : null}
                                                            value={item.sizeId}
                                                            onChange={(event) => this.handleChangeupdate(Item.fieldName, event.target.value, index)}
                                                            key={sizeIndex} />
                                                    );
                                                })}
                                            </td>
                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b] lg:w-[100%] w-[100px]">
                                                <input value={item.price} className="p-2 input-text-box" onKeyDown={(e) => /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()}
                                                    onChange={(event) => { this.handleChangeupdate(event.target.name, event.target.value, index) }} name="price" />
                                            </td>
                                            <td className="px-3 py-2 whitespace-nowrap text-[#1e293b] lg:w-[100%] w-[100px]">
                                                {this.priceService.productcurrencyId.map((Item, currencyIndex) => {
                                                    return (
                                                        <>
                                                            <FormFieldGroup
                                                                column={Item}
                                                                options={Item.options ? Item.fieldName === 'currencyId' ? this.state.getAllCurrencies : this.state.size : null}
                                                                value={item.currencyId}
                                                                onChange={(event) => this.handleChangeupdate(Item.fieldName, event.target.value, index)}
                                                                key={currencyIndex} />
                                                        </>
                                                    );
                                                })}
                                            </td>

                                            <td className="px-3 py-2 whitespace-nowrap text-left text-[#1e293b] lg:w-[100%] w-[100px]">
                                                {/* <i class="fas fa-check themeColor cursor-pointer mr-3"
                                                    onClick={(e) => this.updateProductPriceById(this.state.priceLists[index].priceId, this.state.priceLists[index].price, e, index)} ></i> */}
                                                <i class="fas fa-trash themeColor cursor-pointer" onClick={(e) => this.DeletePrice(this.state.priceLists[index].priceId, this.state.priceLists[index].price, e, index)}></i>
                                            </td>

                                        </tr>
                                    )
                                        :
                                        <>
                                            {this.state.priceLists.length === 0 || this.state.priceLists.length == undefined ?
                                                <p className="text-center p-4">
                                                    <i className="fas fa-empty-set pr-2"></i>No Records Found
                                                </p> : null}
                                        </>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div >
                <PriceModal
                    openStateModal={this.state.setOpenModal}
                    updatePrice={this.state.isUpdate}
                    priceIndexList={this.state.priceIndex}
                    priceList={this.state.priceLists}
                    addPriceLists={this.state.addPriceList}
                    onCloseModal={this.onCloseModal.bind(this)}
                    modalOpen={(e) => { this.setState({ setOpenModal: false }); this.priceService.onResetData(); }}
                    onClose={(e) => { e.stopPropagation(); this.setState({ setOpenModal: false }); this.priceService.onResetData(); }}
                    priceServices={this.priceService}
                    getAllCurrencies={this.state.getAllCurrencies}
                    size={this.state.size}
                    addPrice={this.addPrice.bind(this)}
                    updateProductPriceById={this.updateProductPriceById.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                    handleChangeupdate={this.handleChangeupdate.bind(this)}
                    validState={this.state.validState}
                    validationRules={this.state.validationRules}
                    validateField={this.priceService.validateField.bind(this)}
                />
            </>
        )
    }
}

export default PriceList;