import React from 'react';
/* Lib  */
import ModalBasic from '../../ModalBasic';
import BaseComponent from '../../../shared/baseComponent';
import FormFieldGroup from '../../formGroupFields/FormFieldGroup';
import ButtonGroup from '../../common/ButtonGroup';

export class PriceModal extends BaseComponent {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }
    render() {
        return (
            <>
                <ModalBasic id="basic-modal_1" modalOpen={this.props.openStateModal} setModalOpen={this.props.modalOpen} title={this.props.updatePrice ? "Update Price " : "Add Price"}>
                    <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
                        <div className="border-slate-200">
                            <div className="space-y-8 mt-8 pb-5">
                                <div className="grid gap-5 md:grid-cols-3">
                                    {this.props.priceServices.productPriceForm.map((Item, index) => {
                                        return (
                                            <FormFieldGroup
                                                column={Item}
                                                options={Item.options ? Item.fieldName === 'sizeId' ? this.props.size : this.props.getAllCurrencies : null}
                                                value={this.props.priceList.length >= 1 && this.props.updatePrice ?
                                                    this.props.priceList[this.props.priceIndexList][Item.fieldName] :
                                                    this.props.addPriceLists[Item.fieldName]}
                                                error={this.props.validState.error[Item.fieldName]}
                                                onBlur={() => {
                                                    this.props.updatePrice === true ? this.props.validateField(Item.fieldName, this.props.addPriceLists, this.props.validationRules, this.props.validState) :
                                                        this.props.validateField(Item.fieldName, this.props.addPriceLists, this.props.validationRules, this.props.validState)
                                                }}
                                                onChange={(event) => this.props.handleChange(Item.fieldName, event.target.value)}
                                                key={index}
                                                onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                            />
                                        );
                                    })}
                                </div>
                                <div className="flex flex-wrap justify-center mt-3 mb-4">
                                    <ButtonGroup
                                        onCancelClick={() => this.props.onCloseModal()}
                                        onAddClick={() => this.props.addPrice()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBasic>
            </>
        )
    }
}

export default PriceModal