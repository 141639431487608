import { axiosPostAuthorize, axiosGetAuthorize } from '../AxiosRequests';
import { GetInformaticsConfigurationList, GetInformaticsConfigurationById, UpdateInformaticsConfiguration } from '../ApiEndPoints';

export default class InformaticsConfigurationServices {

    async getInformaticsConfigurationList(request) {
        return axiosPostAuthorize(GetInformaticsConfigurationList, request);
    }
    async getInformaticsConfigurationById(request) {
        return axiosGetAuthorize(GetInformaticsConfigurationById, request);
    }
    async updateInformaticsConfiguration(request) {
        return axiosPostAuthorize(UpdateInformaticsConfiguration, request);
    }
}