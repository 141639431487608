/* Component  */
import { axiosGetUserDetailsAuthorize, axiosPost, axiosPostSSO } from '../AxiosRequests';
import { AdminLogin, GetUserDetailsByClientIdAndSecretkey, ProjectAuthentication, ValidateToken } from '../ApiEndPoints';

export default class AuthenticationServices {

    // API function

    async adminLogin(request) {
        return axiosPostSSO(AdminLogin, request);
    }

    async validateToken(request) {
        return axiosPostSSO(ValidateToken, request);
    }
    async projectAuthentication(request) {
        return axiosPost(ProjectAuthentication, request);
    }
    async getUserDetailsByClientIdAndSecretkey(request) {
        return axiosGetUserDetailsAuthorize(GetUserDetailsByClientIdAndSecretkey, request);
    }
}
