import React from 'react';

/* Lib  */
import ModalBasic from '../../ModalBasic';
import BaseComponent from '../../../shared/baseComponent';
import FormFieldGroup from '../../formGroupFields/FormFieldGroup';
/* Services  */
import SafetyDetialsDomainSevice from '../../../services/domainServices/SafetyDetailsDomainSevice';
import ButtonGroup from '../../common/ButtonGroup';
export class SafetyDetailsModal extends BaseComponent {
    constructor(props) {
        super(props)
        this.safetyDetailsService = new SafetyDetialsDomainSevice();
        this.safetyDetailsServices = props.safetyDetailsService;
        this.state = {
            ...this.state,
        }
        this.safetyDetailsService.onUpdateState = this.updateState.bind(this);
    }
    componentDidMount() {
        this.safetyDetailsService.onProductSafetyForm();
    }

    render() {
        return (
            <ModalBasic
                id="basic-modal_3" modalOpen={this.props.openStateModal} setModalOpen={this.props.modalOpen} title={this.props.safetyDetailsUpdate ? "Update Details " : "Add Details"}>
                <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
                    <div className="border-slate-200">
                        <div className="space-y-8 mt-8 pb-5">
                            <div className="grid gap-5 md:grid-cols-3">
                                {this.safetyDetailsService.productSafetyForm.map((Item, index) => {
                                    return (
                                        <>
                                            <FormFieldGroup
                                                value={this.props.safetyDetails.length >= 1 && this.props.safetyDetailsUpdate ? this.props.updateSafetyState[Item.fieldName] : this.props.safetyDetails[Item.fieldName]}
                                                error={this.props.validState.error[Item.fieldName]}
                                                onChange={(event) => { this.props.safetyDetailsUpdate === true ? this.props.handleChangeupdate(Item.fieldName, event.target.value, this.props.saftyindex) : this.props.handleChange(Item.fieldName, event.target.value, this.props.saftyindex) }}
                                                onBlur={() => {
                                                    this.props.safetyDetailsUpdate === true ? this.props.validateField(Item.fieldName, this.props.updateSafetyState, this.props.validationRules, this.props.validState)
                                                        : this.props.validateField(Item.fieldName, this.props.product, this.props.validationRules, this.props.validState)
                                                }}
                                                onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                column={Item}
                                                key={index}
                                            />
                                        </>
                                    )
                                })
                                }
                            </div>
                            <div className="flex flex-wrap justify-center mt-3 mb-4">
                                <ButtonGroup
                                    onCancelClick={(e) => this.props.onCloseModal(e)}
                                    onUpdateClick={(e) => this.props.updateSafety(e)}
                                    onAddClick={(e) => this.props.addSafety(e)}
                                    updateState={this.props.safetyDetailsUpdate}
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </ModalBasic>
        )
    }

}

export default SafetyDetailsModal