
/* Component  */
import React from 'react';
import Table from '../../components/tables/Table';
import { encryptAES } from "../../utils/Encryption";

/* Library  */
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';

/* Services  */
import orderServices from '../../services/domainServices/orderServices';
import BaseComponent from '../../shared/baseComponent';
import FormFieldGroup from '../../components/formGroupFields/FormFieldGroup';

export default class OrderLists extends BaseComponent {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.orderService = new orderServices();
    this.state = {
      pagination: {
        searchCatalogNumber: "",
        searchUserName: "",
        searchCASNo: "",
        searchProductNo: "",
        searchOrderNumber: "",
        ...this.orderService.pagination,
      },
      setIsLoad: false,
      route: null,
      dataLists: [],
      orderTypeList: this.orderService.dataItem,
    }
    this.orderService.onUpdateState = this.updateState.bind(this);
    this.orderService.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.orderService.onGetRequests(this.state.pagination);
    document.title = 'Order List';
  }


  // *** Other function **//

  onActionCall = (type, data) => {
    let det = this.state.dataLists;
    switch (type) {
      case "userName":
        let value = det.find(x => x.userName === data);
        window.open(`/UserDetails/userId?=${encryptAES(value.userId)}`, '_blank');
        break;
      case "View":
        window.open(`/OrderDetails/OrderId?=${encryptAES(data)}`, '_blank');
        break;
      case "Delete":
        let userDetails = det.find(x => x.orderId === data);
        this.orderService.apiService.serviceManager.swalServices.Confirm("Are you sure, you want delete order ?", " " + "", "Yes", "No")
          .then(async confirm => {
            if (confirm) {
              let datas = {
                Ordernumber: userDetails.orderNumber,
              };
              this.orderService.onDeleteOrder(datas, this.state.pagination);
            }
          });

        break;
      case "Views":
        this.setState({});
        break;
    }
  };

  handleChange(name, value) {
    const detail = this.state.pagination;
    detail[name] = value.trim();

    const resetFields = {
      searchProductNo: ['searchText', 'searchCatalogNumber', 'searchUserName', 'searchCASNo', 'searchOrderNumber'],
      searchCASNo: ['searchText', 'searchCatalogNumber', 'searchUserName', 'searchProductNo', 'searchOrderNumber'],
      searchCatalogNumber: ['searchText', 'searchUserName', 'searchCASNo', 'searchProductNo', 'searchOrderNumber'],
      searchUserName: ['searchText', 'searchCatalogNumber', 'searchCASNo', 'searchProductNo', 'searchOrderNumber'],
      searchText: ['searchCatalogNumber', 'searchUserName', 'searchCASNo', 'searchProductNo', 'searchOrderNumber'],
      searchOrderNumber: ['searchText', 'searchCatalogNumber', 'searchUserName', 'searchCASNo', 'searchProductNo']
    };

    const data = { ...this.state.pagination, pageNo: 1 };
    resetFields[name]?.forEach(field => data[field] = null);
    if (value.trim().length >= 3 || value.trim().length === 0) {
      this.orderService.onGetRequests(data);
    }
    this.setState({ pagination: { ...data }, orderTypeList: { ...data } });
  }



  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
              <div className="sm:flex sm:justify-between sm:items-center mb-6">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold whitespace-nowrap mr-3">Order History </h1>
                </div>
                <div className="overflow-x-auto">
                  <div className="grid grid-cols-5 sm:grid-flow-col sm:auto-cols-max sm:justify-end justify-center gap-3">
                    {this.orderService.formFields.map((Item, index) => {
                      return (
                        <>
                          {Item.inputType === "text" ? (

                            <div className="col-md-12">
                              <FormFieldGroup
                                className='ms-auto relative search-bar-input'

                                column={Item}
                                value={
                                  this.state.orderTypeList[Item.fieldName]
                                }
                                onChange={(event) =>
                                  this.handleChange(Item.fieldName, event.target.value)
                                }
                                onKeyPress={(e) =>
                                  e.key === "Enter" &&
                                  this.handleChange(Item.fieldName, e.target.value)
                                }
                                onBlur={() => null}

                                key={index} />
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>

              </div>
              <div className='main-order-history-list'>
                {
                  (this.state.setIsLoad) ?
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: '150px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                    :
                    <div className=''>
                      <Table
                        columns={this.orderService.gridColmns}
                        rows={this.state.dataLists}
                        sortingColumns={[]}
                        pagination={this.state.pagination}
                        totalCount={this.state.totalCount}
                        totalResultes={this.state.totalCount}
                        setPagination={this.orderService.onPageChange.bind(this)}
                        customScope={[
                          {
                            column: "orderId",
                            renderTableData:
                              this.orderService.gridActionViewColOrderId.bind(this),
                          },
                          {
                            column: "paymentType",
                            renderTableData:
                              this.orderService.gridActionViewColpayment.bind(this),
                          },
                          {
                            column: 'userName',
                            renderTableData: this.orderService.UserEmailactions.bind(this)
                          },
                        ]}
                      />
                    </div>
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
