//** Component  */
import React from "react";
import Validation from '../../utils/validation/ValidationText';
import InputField from "./InputField";
import FileField from "./FileField";
import CheckboxField from "./CheckboxField";
import SelectField from "./SelectField";
import TextAreaField from "./TextAreaField";


const FormFieldGroup = (props) => {
    return (
        <>
            {props.column.inputType === "text" || props.column.inputType === "number" || props.column.inputType === "password" ? (
                <div className={`form-group  ${props.column.className} ${props.className}`}>
                    <label className={`block text-sm font-medium mb-1`} hidden={props.column.tittle === ""}
                        style={{ display: props.column.labelStyleName }} for={props.column.fieldName}>
                        {props.column.tittle}
                    </label>
                    <InputField request={props} />
                    <Validation error={props.error} />
                </div>
            ) : null}

            {props.column.inputType === "textarea" ? (
                <div className={`${props.column.className}`}>
                    <label className="block text-sm font-medium mb-1">{props.column.tittle} </label>

                    <TextAreaField request={props} />
                    <Validation error={props.error} />
                </div>
            ) : null}

            {props.column.inputType === "file" ? (
                <div className={`form-group  ${props.column.className}`}>
                    <label className="block text-sm font-medium mb-1" for={props.column.fieldName}>
                        {props.column.tittle}
                    </label>
                    <FileField request={props} />
                    <Validation error={props.error} />
                </div>
            ) : null}

            {props.column.inputType === "checkbox" ? (
                <div className={`form-group  ${props.column.className}`}>
                    <CheckboxField request={props} />
                    <label
                        className={`text-sm font-medium mb-1 ${props.column.checkLabelClass}`}
                        for={props.column.fieldName}>
                        {props.column.tittle}
                    </label>
                </div>
            ) : null}
            {props.column.inputType === "select" ? (
                <div className={`${props.column.className}`}>
                    <label className="block text-sm font-medium mb-1">{props.column.tittle} </label>
                    <SelectField request={props} />
                    <Validation error={props.error} />
                </div>
            ) : null}

            {props.column.inputType === "label" ?
                <div className={`mb-2 label-section-main ${props.column.className}`}>
                    <label className="fw-bold" style={{ fontSize: '16px' }} hidden={props.column.tittle === ""}>{props.column.tittle}  </label>
                    <label className="ml-2" style={{ fontSize: '16px' }} hidden={props.column.tittle === ""}>{props.value ? props.value : '-'} </label>
                </div>
                : null}
        </>
    );
};

export default FormFieldGroup;
