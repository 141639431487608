/* Component  */
import React, { Component } from 'react';
import Form from '../../components/form/Form';

/* Library  */
import { FormFieldTypes } from '../../utils/Constants';

export default class FormPage extends Component {
  /* Constructor and Component method */
  constructor(props) {
    super(props)
    this.state = {
      form: {
        formTitle: "Form",
        formFields: [
          {
            type: FormFieldTypes.Input,
            title: "Normal Input",
            isRequired: false,
            isDisabled: false,
            placeholder: "Input",
            key: "normalInput",
          },
          {
            type: FormFieldTypes.Input,
            title: "Required Input",
            isRequired: true,
            isDisabled: false,
            placeholder: "Input",
            key: "requiredInput",
            validation: [{ type: "require", message: "Please enter input" }]
          },
          {
            type: FormFieldTypes.Input,
            title: "Disabled Input",
            isRequired: false,
            isDisabled: true,
            placeholder: "Input",
            key: "disabledInput",
          },
          {
            type: FormFieldTypes.Input,
            title: "Special Input",
            isRequired: false,
            isDisabled: false,
            placeholder: "Input",
            key: "specialInput",
            isPrefix: true,
            prefix: "$",
            isSuffix: true,
            suffix: "%",
          },
          {
            type: FormFieldTypes.Numeric,
            title: "Numeric Input",
            isRequired: false,
            isDisabled: false,
            placeholder: "Input",
            key: "numericInput",
          },
          {
            type: FormFieldTypes.Password,
            title: "Password Input",
            isRequired: false,
            isDisabled: false,
            placeholder: "Input",
            key: "passwordInput",
          },
          {
            type: FormFieldTypes.Select,
            title: "Normal Select",
            isRequired: false,
            isDisabled: false,
            placeholder: "Select",
            key: "normalSelect",
            options: [{ id: 1, name: "India" }, { id: 2, name: "USA" }, { id: 3, name: "UK" }],
            textKey: "name",
            valueKey: "id"
          },
          {
            type: FormFieldTypes.Select,
            title: "Required Select",
            isRequired: true,
            isDisabled: false,
            placeholder: "Select",
            key: "requiredSelect",
            options: [{ id: 1, name: "India" }, { id: 2, name: "USA" }, { id: 3, name: "UK" }],
            textKey: "name",
            valueKey: "id",
            validation: [{ type: "require", message: "Please select input" }]
          },
          {
            type: FormFieldTypes.Select,
            title: "Disabled Select",
            isRequired: false,
            isDisabled: true,
            placeholder: "Select",
            key: "disabledSelect",
            options: [{ id: 1, name: "India" }, { id: 2, name: "USA" }, { id: 3, name: "UK" }],
            textKey: "name",
            valueKey: "id"
          },
          {
            type: FormFieldTypes.DatePicker,
            title: "Date Picker",
            isRequired: false,
            isDisabled: false,
            isDisablePastDates: true,
            placeholder: "Date",
            key: "date",
          },
          {
            type: FormFieldTypes.DatePicker,
            title: "Disabled Date Picker",
            isRequired: false,
            isDisabled: true,
            isDisablePastDates: true,
            placeholder: "Date",
            key: "disabledDate",
          },
          {
            type: FormFieldTypes.CheckBox,
            title: "Check Box",
            isDisabled: false,
            key: "checkBox",
          },
          {
            type: FormFieldTypes.CheckBox,
            title: "Disabled Check Box",
            isDisabled: true,
            key: "disabledCheckBox",
          },
          {
            type: FormFieldTypes.textarea,
            title: "Text Area",
            isRequired: false,
            isDisabled: false,
            key: "textarea",
          },
          {
            type: FormFieldTypes.textarea,
            title: "Disabled Custom Text Area",
            isRequired: true,
            isDisabled: true,
            rows: 8,
            key: "disabledTextArea",
          },
        ]
      },
      formObject: {
        normalInput: "",
        requiredInput: "",
        disabledInput: "disabled value",
        numericInput: "",
        passwordInput: "",
        specialInput: "",
        normalSelect: "",
        requiredSelect: "",
        disabledSelect: "1",
        checkBox: false,
        disabledCheckBox: true,
        textArea: "",
        disabledTextArea: "Test",
        date: new Date().toISOString().slice(0, 10),
        disabledDate: new Date().toISOString().slice(0, 10)
      }
    }
  }


  // Other Function 

  setFormValues = (key, value) => {
    let newFormvalues = { ...this.state.formObject };
    newFormvalues[key] = value;
    this.setState({ formObject: newFormvalues });
  }

  submitForm = () => {
  }

  cancel = () => {
  }

  render() {
    return (
      <Form form={this.state.form}
        formValues={this.state.formObject}
        setFormValues={this.setFormValues.bind(this)}
        submitForm={this.submitForm.bind(this)}
        cancel={this.cancel.bind(this)}
      />
    )
  }
}


