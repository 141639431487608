/* Component  */
import ModalBasic from '../../components/ModalBasic'
import React, { Component } from 'react';
import ViewAddressModelStyle from './ViewAddressModelStyle'
import ShoppingCartServices from '../../services/axiosServices/apiServices/ShoppingCartServices';
import { AddressType } from '../../utils/enums/AddressTypeEnum';

export default class ViewAddressModel extends Component {
    constructor(props) {
        super(props)
    this.getShoppingCartUser = new ShoppingCartServices();

        this.state = {
            shippingAddresses: [],
            billingAddresses: [],
            commonAddressArr :[],
      address: [],

        }

    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let userId = params.get("");
        if (userId > 0) {
          this.getAddress(userId);
    
        }
      }

      getAddress = userId => {
        let data = {
          userId: userId,
          pageNo: 1,
          pageSize: 100,
          orderByColumn: "CreatedDate",
          orderFlag: 0,
          searchText: ""
        };
        this.setState({ pageLoader: true });
        this.getShoppingCartUser.getaddresses(data).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            let filteredShippingAddresses = response.responseItem.responseContent.itemList.filter(a => a.addressTypeId === AddressType.ShippingAddress);
            let filteredBillingAddresses = response.responseItem.responseContent.itemList.filter(a => a.addressTypeId === AddressType.BillingAddress);
            this.setState({ shippingAddresses: filteredShippingAddresses });
            this.setState({ billingAddresses: filteredBillingAddresses });
            this.setState({ address: response.responseItem.responseContent.itemList },()=>{
                if(this.props.addressType == "BillingAddress"){
                    this.setState({commonAddressArr : this.state.billingAddresses});

                }else{
                    this.setState({commonAddressArr : this.state.shippingAddresses});
                }
            });
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ pageLoader: false });
        })
      }

    render(props) {
       
    return (
        <>
        <ModalBasic id="basic-modal" modalOpen={props.basicModalOpen} setModalOpen={props.setModalOpen} title="View Address">
            <div class="flex flex-col px-6 py-5 bg-gray-50">
                <div className="card-body">
                    <form className="AdjustingForm  ">
                        <div className='pt-3'>
                            <div class="grid grid-cols-2 gap-5">
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Address Type <code>*</code></label>
                                    <select className="form-control AdjustForm" name="addressTypeId"
                                    >
                                    </select>

                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Address Name <code>*</code></label>

                                    <input type="text" className="form-control AdjustForm" name="addressName"

                                        placeholder="Dr.Abc's Lab - 1001 Main St" />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Attn To <code>*</code></label>
                                    <input type="text" className="form-control AdjustForm" name="attendantName"

                                        placeholder="Attn To"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Phone No <code>*</code></label>
                                    <input
                                        type="text"
                                        className="form-control AdjustForm"
                                        name="attendantPhoneNo"

                                        placeholder="Phone No"
                                    />

                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Address Line 1 <code>*</code></label>
                                    <input type="text" className="form-control AdjustForm" name="addressLine1"

                                        placeholder="Address Line 1"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Address Line 2</label>
                                    <input type="text" className="form-control AdjustForm" name="addressLine2"
                                        placeholder="Address Line 2" />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Country <code>*</code></label>
                                    <select className="form-control form-control AdjustForm" id="countryName" name="countryId"
                                        placeholder="countryName">
                                        <option value="">Select Country</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">State <code>*</code></label>
                                    <select className="form-control form-control AdjustForm" id="stateName" name="stateId"
                                        placeholder="stateName">
                                        <option value="">Select State</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">City <code>*</code></label>
                                    <input type="text" className="form-control AdjustForm"
                                        name="city" maxLength={60}

                                        placeholder="City"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Zip Code <code>*</code></label>
                                    <input type="text" className="form-control AdjustForm"
                                        name="zipCode" maxLength={10}

                                        placeholder="Zip Code"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Fax</label>
                                    <input type="text" className="form-control AdjustForm" name="fax"

                                    />
                                </div>
                                <div className="checkbox mt-5">
                                    <input type="checkbox" name="isDefault" id="defaultAddress"

                                    />
                                    <label className="ml-2" for="defaultAddress">
                                        Is Default Address
                                            <span className="checkbox-icon"></span></label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ViewAddressModelStyle />
        </ModalBasic>

        </>
    )
}
}
