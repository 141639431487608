// Component
import BaseComponent from "../../shared/baseComponent";
import Table from '../../components/tables/Table';
// library
import { Bars } from 'react-loader-spinner';
// Services 
import { encryptAES } from "../../utils/Encryption";
import shoppingCartService from '../../services/domainServices/shoppingCartService';
import { Navigate } from 'react-router-dom';
import FormFieldGroup from "../../components/formGroupFields/FormFieldGroup";

export default class ShoppingCart extends BaseComponent {
    constructor(props) {
        super(props)
        this.shoppingCartService = new shoppingCartService();
        this.state = {
            dataList: [],
            setIsLoad: false,
            pagination: this.shoppingCartService.pagination,
            redirect: null,
            searchList: this.shoppingCartService.dataItem,
        }
        this.shoppingCartService.onUpdateState = this.updateState.bind(this);
        this.shoppingCartService.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.shoppingCartService.onShoppingCartList(this.state.pagination)
    }

    // *** Other function **//
    onActionCall = (type, data) => {
        let det = this.state.dataList;
        switch (type) {
            case "userName":
                let value = det.find(x => x.userName === data);
                window.open(`/UserDetails/userId?=${encryptAES(value.userId)}`, '_blank');
                break;
            case "View":
                window.open(`/ShoppingCartDetails/id?=${encryptAES(data)}`, '_blank');

                break;
        }
    };
    handleChangeEvent = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ searchList: { ...detail } });
    }

    handleChange = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        if (name === "searchText") {
            detail[name] = value.trim();
            let data = { ...this.state.pagination };
            data.pageNo = 1;
            this.shoppingCartService.onShoppingCartList(data);
            this.setState({ pagination: { ...data } });

        }
        this.setState({ searchList: { ...detail } });
    }
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="flex overflow-hidden">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main>
                        <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">User Shopping Cart </h1>
                                </div>
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end justify-center gap-3">
                                    <div className="relative">
                                        <label htmlFor="action-search" className="sr-only">Search</label>
                                        {this.shoppingCartService.formFields.map((Item, index) => {
                                            return (
                                                <FormFieldGroup
                                                    className='ms-auto relative search-bar-input'
                                                    column={Item}
                                                    value={
                                                        this.state.searchList[Item.fieldName]
                                                    }
                                                    onChange={(event) =>
                                                        this.handleChangeEvent(Item.fieldName, event.target.value)
                                                    }
                                                    onKeyPress={(e) =>
                                                        e.key === "Enter" &&
                                                        this.handleChange(Item.fieldName, e.target.value)
                                                    }
                                                    key={index} />
                                            );
                                        })}
                                    </div>
                                    <button className="btnn commanColorbtn text-dark" onClick={(e) => { e.stopPropagation(); this.handleChange("searchText", this.state.pagination.searchText) }}>
                                        <span className="xs:block">Search</span>
                                    </button>
                                </div>
                            </div>

                            <div className='main-shopping-cart-list'>
                                {
                                    (this.state.setIsLoad) ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: '150px' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <Table
                                            columns={this.shoppingCartService.gridColmns}
                                            rows={this.state.dataList}
                                            sortingColumns={[]}
                                            pagination={this.state.pagination}
                                            totalCount={this.state.TotalCount}
                                            totalResultes={this.state.TotalCount}
                                            setPagination={this.shoppingCartService.onPageChange.bind(this)}
                                            customScope={[
                                                {
                                                    column: 'userId',
                                                    renderTableData: this.shoppingCartService.gridActionViewCols.bind(this)
                                                },
                                                {
                                                    column: 'userName',
                                                    renderTableData: this.shoppingCartService.UserEmailactions.bind(this)
                                                }
                                            ]}
                                        />
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}
