/* Component  */
import React from 'react'

/* Library  */
import { ButtonTypes } from '../../utils/Constants';

const Button = (props) => {
    return (
        props.type === ButtonTypes.Primary ?
            <button className={`btn buttonDataImport commanColorbtn transition-all ${props.isLoading ? "disabled:cursor-not-allowed" : ""}`} onClick={() => props.buttonClick()} disabled={props.isLoading}>
                {props.isLoading ?
                    <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                    : <span >{props.title}</span>}
            </button>
            :
            props.type === ButtonTypes.Secondary ?
                <button className={`btn Secondary-button hover:border-slate-300 text-[#3c5e96] ${props.isLoading ? "disabled:cursor-not-allowed" : ""}`} onClick={() => props.buttonClick()} disabled={props.isLoading}>
                    {/* {props.isLoading ?
                        <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                        : null} */}
                    <span >{props.title}</span>
                </button>
                :
                <button className="btn buttonDataImport bg-[#1e293b] hover:bg-[#3c5e96] transition-all text-white" onClick={() => props.buttonClick()}>
                    <span>
                        {props.title}
                    </span>
                </button>
    )
}

export default Button;
