import baseDomainService from './baseDomainServices';
import { getValidationRuleList } from "../../utils/validation/generateValidationRule"
import { SuccessMessage } from '../../utils/Messages';

const configurationDetails = {
    title: "",
    isActive: false,
    informaticsConfigurationId: 0
}

export default class informaticsConfigurationServices extends baseDomainService {

    getInformaticsConfigurationList = this.apiService.informaticsConfigurationServices.getInformaticsConfigurationList;
    getInformaticsConfigurationById = this.apiService.informaticsConfigurationServices.getInformaticsConfigurationById;
    updateInformaticsConfiguration = this.apiService.informaticsConfigurationServices.updateInformaticsConfiguration;

    onGetInformaticsConfigurationList = (request) => {
        this.apiService.onAPICall("getInformaticsConfigurationList", this.getInformaticsConfigurationList, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetInformaticsConfigurationById = (requst) => {
        this.apiService.onAPICall("getInformaticsConfigurationById", this.getInformaticsConfigurationById, requst, this.onAPIResponse, this.onUpdateState);
    };

    onUpdateInformaticsConfiguration = (requst) => {
        this.apiService.onAPICall("updateInformaticsConfiguration", this.updateInformaticsConfiguration, requst, this.onAPIResponse, this.onUpdateState);
    };

    formFields = [
        {
            inputType: "text",
            tittle: "Title ",
            placeholder: "Title ",
            fieldName: "title",
            validation: [{ type: "require" }],
            isUpdate: true,
            disabled: true

        },
        {
            inputType: "checkbox",
            tittle: "IsActive",
            fieldName: "isActive",
            checkLabelClass: 'ml-2',
            isUpdate: true,
            checked: true,
        },
    ];

    gridColmns = [
        { name: "title", title: "Title" },
        { name: "isActive", title: "IsActive" },
        { name: "informaticsConfigurationId", title: "Actions" },
    ];

    dataItem;

    constructor() {
        super();
        this.onPaginationUpdate = this.onGetInformaticsConfigurationList.bind(this);
        this.actionKey = "informaticsConfigurationId";
        this.dataItem = { ...configurationDetails };
    }

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
        })
    }

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getInformaticsConfigurationList":
                let itemList = responseData.itemList;
                if (responseData !== null) {
                    this.onUpdateState({
                        "dataList": itemList,
                        "setIsLoad": false,
                        "TotalCount": responseData.totalCount,
                    });
                }
                break;
            case "getInformaticsConfigurationById":
                let userDet = ({ ...responseData });
                this.dataItem = { ...userDet };
                if (responseData != null) {
                    this.onUpdateState({
                        configurationDetails: {
                            title: userDet.title ? userDet.title : '-',
                            isActive: userDet.isActive ? userDet.isActive : 0,
                            informaticsConfigurationId: userDet.informaticsConfigurationId
                        },
                        // updateEmail: userDet.digestEmailId,
                        // paramList: JSON.parse(userDet.queryData),
                        setIsLoad: false,
                        isBtnLoading: false
                    });
                }
                else {
                    this.apiService.swalServices.Toaster("The column are invalid")
                }
                break;
            case "updateInformaticsConfiguration":
                if (responseData.hasError === false) {
                    this.apiService.swalServices.Toaster(SuccessMessage.Update_Success.replace("{0}", "Informatics ConfigurationId"));
                    //this.onGetDigestEmailDetailsById(responseData.keyId)
                    this.onUpdateState({
                        configurationDetails: responseData,
                        setIsLoad: false
                    });
                    this.onRedirectoList();
                }
                else {
                    this.apiService.swalServices.Toaster(responseData.message.DefaultMessage);
                }
                break;
            default:
                break;
        }
    }
}
