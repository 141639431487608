/* Component  */
import { axiosGet, axiosGetAuthorize, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
import { GetIpAddressList, GetIpAddressById,UpdateIpAddress,GetAllIPAddresses, Admin_GetIPAddressCounts , Admin_ProductsSearchByIpAddressCounts } from '../ApiEndPoints';

export default class SettingServices {
    // API function

    async getIpAddressList(request) {
        return axiosPostAuthorize(GetIpAddressList, request);
    }
    async updateIpAddress(request) {
        return axiosPost(UpdateIpAddress, request);
    }
    async getIpAddressById(request) {
        return axiosGet(GetIpAddressById, request);
    }
    async getAllIPAddresses(request) {
        return axiosGet(GetAllIPAddresses, request);
    }
    async admin_GetIPAddressCounts(request) {
        return axiosGetAuthorize(Admin_GetIPAddressCounts, request);
    }
    async getProductsByIpAddress(request) {
        return axiosGetAuthorize(Admin_ProductsSearchByIpAddressCounts, request);
    }
}
