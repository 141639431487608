/* Components  */
import FormPage from '../pages/formPage/FormPage';
import UserList from '../pages/user/UserList';
import UserDashboard from '../pages/user/UserDashboard';
import AdminUserList from '../pages/user/AdminUserList';
import ProductList from '../pages/product-details/ProductList';
import ProductDetail from '../pages/product-details/ProductDetail';
import ContactUs from '../pages/ContactUs/ContactUs';
import SearchHistorylist from '../pages/searchHistory/SearchHistoryList';
import DashboardCard from '../pages/dashboard/Dashboard';
import EmailHistory from '../pages/email/EmailHistory';
import ShoppingCarts from '../pages/shoppingcarts/ShoppingCarts';
import AdminProfile from '../pages/profile/AdminProfile';
import AuditLogs from '../pages/AuditLogs/AuditLogs';
import SiteVisitor from '../pages/SiteVisitor/SiteVisitor';
import DataImportList from '../pages/DataImport/DataImportList';
import DataImport from '../pages/DataImport/DataImport';
import DataImportInfo from '../components/DataImport/DataImportInfo';
import BlockIpAddress from '../pages/blockIpAddress/BlockIpAddress';
import ContactUsResponse from '../pages/ContactUs/ContactUsResponse';
import ManageReports from '../pages/ManageReports/ManageReports';
import AddNewProducts from '../pages/product-details/AddNewProducts';
import SearchHistoryListGrid from '../pages/searchHistory/SearchHistoryListGrid';
import UserDetails from '../components/users/UserDetails';
import CompaniesList from '../pages/companiesDetails/companiesList';
import ContactUsList from '../pages/ContactUs/ContactUsList';
import ViewAddressModel from '../pages/ViewAddressModel/ViewAddressModel';
import UnAuthorized from '../pages/authentication/UnAuthorized';
import DemoPage from '../pages/user/DemoPage';
import Report3 from '../pages/ManageReports/Report3';
import Report4 from '../pages/ManageReports/Report4';
import RFQLists from '../pages/rfq-details/RFQLists';
import RFQDetails from '../pages/rfq-details/RFQDetails';
import OrderLists from '../pages/order/OrderLists';
import OrderDetails from '../pages/order/OrderDetails';
import AddEmailTemplate from '../pages/email/AddEmailTemplate';
import EmailDetails from '../pages/email/EmailDetails';
import ShoppingCartDetails from '../pages/shoppingcarts/ShoppingCartDetails';
import ShoppingCart from '../pages/shoppingcarts/ShoppingCart';
import ViewProducts from '../pages/searchHistory/ProductDetailByUsers';
import ProductReports from '../pages/ManageReports/ProductReports';
import NewProductDetail from '../pages/product-details/NewProductDetail';
import NewProduct from '../pages/product-details/NewProduct';
import Exception from '../pages/Exception/Exception';
import ExceptionDetails from '../pages/Exception/ExceptionDetails';
import UserActivityDetails from '../components/users/UserActivityDetails';
import UserActivity from '../pages/user-activity/UserActivity';
import ActivityDetails from '../pages/user-activity/ActivityDetails';
import Setting from '../pages/Setting/Setting';
import ViewIpAddress from '../pages/Setting/ViewIpAddress';
import IpAddressDetails from '../pages/Setting/IpAddressDetails';
import blockIp from '../pages/Setting/BlockIP';
import ProductDashboard from '../pages/product-details/ProductDashboard';
import DigestEmailHistory from '../pages/digestEmail/DigestEmailHistory';
import AddDigestEmailTemplate from '../pages/digestEmail/AddDigestEmailTemplate';
import DigestEmailDetails from '../pages/digestEmail/DigestEmailDetails';
import DatabaseHealth from "../pages/databaseHealth/DatabaseHealth";

/** Informatics */
import Informatics from '../pages/Informatics/Informatics';

/** Informatics Configuration */
import InformaticsConfigurationList from '../pages/InformaticsConfiguration/InformaticsConfigurationList';
import EditInformaticsConfiguration from '../pages/InformaticsConfiguration/EditInformaticsConfiguration';
import { RoleType } from '../utils/enums/RoleType';

const ComponentNavigation = [
    { id: '', path: '', title: 'Dashboard', component: DashboardCard, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'UserList', path: 'UserList', title: 'UserList', component: UserList, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
    { id: 'UserDashboard', path: 'UserDashboard', title: 'UserDashboard', component: UserDashboard, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
    { id: 'ViewAddressModel', path: 'ViewAddressModel', title: 'ViewAddressModel', component: ViewAddressModel, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'ProductDashboard', path: 'ProductDashboard', title: 'ProductDashboard', component: ProductDashboard, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'AdminUserList', path: 'AdminUserList', title: 'AdminUserList', component: AdminUserList, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
    { id: 'ProductList', path: 'ProductList', title: 'ProductList', component: ProductList, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL]},
    { id: 'ProductDetail', path: 'ProductDetail/:productId', title: 'Product Details', component: ProductDetail, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'ContactUs', path: 'ContactUs', title: 'ContactUs', component: ContactUs, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'formPage', path: 'formPage', title: 'Form', component: FormPage, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'SearchHistorylist', path: 'SearchHistorylist/:userId', title: 'Form', component: SearchHistorylist, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'EmailHistory', path: 'EmailHistory', title: 'EmailHistory', component: EmailHistory, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
    { id: 'ShoppingCarts', path: 'ShoppingCarts/:userId', title: 'ShoppingCarts', component: ShoppingCarts, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'AdminProfile', path: 'AdminProfile', title: 'AdminProfile', component: AdminProfile, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'AuditLogs', path: 'AuditLogs', title: 'AuditLogs', component: AuditLogs, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR] },
    { id: 'SiteVisitor', path: 'SiteVisitor', title: 'SiteVisitor', component: SiteVisitor, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'ContactusResponse', path: "ContactusResponse/:contactUsId", title: 'ContactUsResponse', component: ContactUsResponse, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'DataImport', path: "DataImport", title: 'DataImport', component: DataImport, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'DataImportInfo', path: "DataImportInfo/:importId", title: 'DataImportDetails', component: DataImportInfo, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'DataImportList', path: "DataImportList", title: 'DataImportList', component: DataImportList, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'BlockIpAddress', path: 'BlockIpAddress', title: 'BlockIpAddress', component: BlockIpAddress, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'AddNewProducts', path: 'AddNewProducts', title: 'AddNewProducts', component: AddNewProducts, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'SearchHistoryListGrid', path: 'SearchHistoryListGrid', title: 'SearchHistoryListGrid', component: SearchHistoryListGrid, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'AddNewProducts', path: 'AddNewProducts', title: 'AddNewProducts', component: AddNewProducts, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'UserDetails', path: "UserDetails/:Id", title: 'UserDetails', component: UserDetails, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    // { id: 'UserDetails', path: "UserDetails", title: 'UserDetails', component: UserDetails, hasParams: false, allowrole: ["administrator", "manager" , "general"] },
    { id: 'ViewProducts', path: 'ViewProducts', title: 'ProductDetailByUsers', component: ViewProducts, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'CompaniesList', path: 'CompaniesList', title: 'CompaniesList', component: CompaniesList, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
    { id: 'ContactUsList', path: "ContactUsList", title: 'ContactUsList', component: ContactUsList, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
    { id: 'ContactUs', path: "ContactUs/:Id", title: 'ContactUs', component: ContactUs, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'UnAuthorized', path: "UnAuthorized", title: 'UnAuthorized', component: UnAuthorized, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'DemoPage', path: "DemoPage", title: 'DemoPage', component: DemoPage, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'ProductSearchReports', path: 'ProductSearchReports', title: 'ProductSearchReports', component: ManageReports, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER ] },
    { id: 'ProductReports', path: "ProductReports", title: 'ProductReports', component: ProductReports, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'Report3', path: "Report3", title: 'Report3', component: Report3, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'Report4', path: "Report4", title: 'Report4', component: Report4, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'RFQLists', path: 'RFQLists', title: 'RFQLists', component: RFQLists, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'RFQDetails', path: 'RFQDetails/:RFQId', title: 'RFQDetails', component: RFQDetails, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'OrderLists', path: 'OrderLists', title: 'OrderLists', component: OrderLists, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'OrderDetails', path: 'OrderDetails/:OrderId', title: 'OrderDetails', component: OrderDetails, hasParams: true, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'AddEmailTemplate', path: 'AddEmailTemplate/', title: 'AddEmailTemplate', component: AddEmailTemplate, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'EmailDetails', path: 'EmailDetails/:emailTemplateId', title: 'EmailDetails', component: EmailDetails, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'ShoppingCart', path: 'ShoppingCart', title: 'ShoppingCart', component: ShoppingCart, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'ShoppingCartDetails', path: 'ShoppingCartDetails/:id', title: 'ShoppingCartDetails', component: ShoppingCartDetails, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'NewProduct', path: 'NewProduct/:productId', title: 'New Product Details', component: NewProduct, hasParams: true, allowrole: [ RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'Exception', path: 'Exception', title: 'Exception', component: Exception, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR,RoleType.MANAGER]},
    { id: 'ExceptionDetails', path: 'ExceptionDetails/:id', title: 'ExceptionDetails', component: ExceptionDetails, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'UserActivityDetails', path: 'UserActivityDetails/:id', title: 'UserActivityDetails', component: UserActivityDetails, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'UserActivity', path: 'UserActivity', title: 'UserActivity', component: UserActivity, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER] },
    { id: 'ActivityDetails', path: 'ActivityDetails/:id', title: 'ActivityDetails', component: ActivityDetails, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'Setting', path: 'Setting', title: 'Setting', component: Setting, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR] },
    { id: 'ViewIpAddress', path: 'ViewIpAddress', title: 'View Ip Address', component: ViewIpAddress, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR] },
    { id: 'blockIp', path: 'blockIp', title: 'Block Ip Address', component: blockIp, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR] },
    { id: 'IpAddressDetails', path: 'IpAddressDetails/:id', title: 'Ip Address Details', component: IpAddressDetails, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'DigestEmailHistory', path: 'DigestEmailHistory', title: 'DigestEmailHistory', component: DigestEmailHistory, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
    { id: 'AddDigestEmailTemplate', path: 'AddDigestEmailTemplate/:emailTemplateId', title: 'AddDigestEmailTemplate', component: AddDigestEmailTemplate, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'DigestEmailDetails', path: 'DigestEmailDetails/:emailTemplateId', title: 'DigestEmailDetails', component: DigestEmailDetails, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'AddDigestEmailTemplate', path: 'AddDigestEmailTemplate/', title: 'AddDigestEmailTemplate', component: AddDigestEmailTemplate, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },

    /** Informatics */
    { id: 'Informatics', path: 'Informatics', title: 'Informatics', component: Informatics, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },

    /** Informatics Configuration */
    { id: 'InformaticsConfigurationList', path: 'InformaticsConfigurationList', title: 'Informatics Configurations', component: InformaticsConfigurationList, hasParams: false, allowrole: [ RoleType.ADMINISTRATOR] },
    { id: 'EditInformaticsConfiguration', path: 'EditInformaticsConfiguration/:id', title: 'Informatics Configurations', component: EditInformaticsConfiguration, hasParams: false, allowrole: [RoleType.ADMINISTRATOR,RoleType.MANAGER,RoleType.GENERAL] },
    { id: 'DatabaseHealth', path: "DatabaseHealth", title: "Database Health", component: DatabaseHealth, hasParams: false, allowrole: [RoleType.ADMINISTRATOR] },
];
export default ComponentNavigation;
