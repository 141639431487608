// Component
import React from 'react';
import Table from '../../components/tables/Table';
import baseComponent from "../../shared/baseComponent"

// Services
import ManageUserServices from '../../services/axiosServices/apiServices/ManageUserServices';

// Library
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import UserService from '../../services/domainServices/AdminuserService';

export default class AdminUserList extends baseComponent {
  //Constructor  and Component method 
  constructor(props) {
    super(props)
    this.AdminuserService = new UserService();
    this.state = {
      pagination: this.AdminuserService.pagination,
      setIsLoad: false,
      getAdminUsers: [],
    }
    this.AdminuserService.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.AdminuserService.onGetAdminList(this.state.pagination);
    document.title = 'User List';
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className="flex overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="pr-2 sm:pr-3 lg:pr-4 py-5 w-full max-w-9xl mx-auto pb-0">
              <div className="mb-4 sm:mb-4">
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Admin Users </h1>
              </div>
              <div className='main-Admin-user-list'>
                {
                  (this.state.setIsLoad) ?
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: '150px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                    :
                    <Table
                      columns={this.AdminuserService.AdminListgridColmns}
                      rows={this.state.getAdminUsers}
                      sortingColumns={[]}
                      pagination={this.state.pagination}
                      totalCount={this.state.TotalCount}
                      totalResultes={this.state.TotalCount}
                      setPagination={this.AdminuserService.onPageChange.bind(this)}
                    />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}
