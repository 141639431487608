/* Component  */
import React from 'react';

/* Library  */
import { Bars } from 'react-loader-spinner';
import FormFieldGroup from '../formGroupFields/FormFieldGroup';
import DataImportService from '../../services/domainServices/dataImportService';


const DataImportDetails = (props) => {

  const dataImportService = new DataImportService();

  return (
    <div>
      {(props.isLoading) ?
        <Bars className="row" wrapperStyle={{ marginTop: '0px' }} color="#3c4b64" height={55}
          width="100%" visible={props.isLoading} />
        :
        <>
          <div className="w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200 p-3" >
            <header className="sm:px-5 py-2 border-b border-slate-100">
              <h1 className="font-semibold text-slate-800">Indofine Stock</h1>
            </header>
            <div className=' p-3 grid gap-5 md:grid-cols-3'>
              {dataImportService.detailInfoForm.map((Item, index) => {
                return (
                  Item.formType != true ?
                    <FormFieldGroup
                      column={Item}
                      value={props.importDetails[Item.fieldName]} />
                    : null)
              })}
            </div>
          </div>
          <br />
          <div className="w-full max-w-9xl mx-auto bg-white shadow-lg rounded-sm border border-slate-200 p-3" >
            <header className="sm:px-5 py-2 border-b border-slate-100">
              <h2 className="font-semibold text-slate-800">Import Process Report</h2>
            </header>
            <div className=' p-3 grid gap-5 md:grid-cols-3'>
              {dataImportService.detailInfoForm.map((Item, index) => {
                return (
                  Item.formType != true ?
                    null
                    : <FormFieldGroup
                      column={Item}
                      value={props.importDetails[Item.fieldName]} />)
              })}
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default DataImportDetails