import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";


const emailDetails = {
  emailTemplateName: "",
  subject: "",
  emailBody: "",
  isActive: false,
  emailTemplateId: 0,
}

export default class emailServices extends BaseDomainService {
  // API Get
  getList = this.apiService.emailServices.admin_GetEmailList;
  addEmailDetails = this.apiService.emailServices.admin_AddEmailTemplate
  getEmailDetailsById = this.apiService.emailServices.admin_GetEmailTemplateById
  updateEmailDetails = this.apiService.emailServices.admin_UpdateEmailDetailsById

  //Table Grid columns
  gridColmns = [
    { name: "emailTemplateName", title: "Email Template Name" },
    { name: "subject", title: "Subject" },
    { name: "isActive", title: "IsActive" },
    { name: "emailTemplateId", title: "Actions" },
  ];

  // Define the state variables
  dataItem;

  // define the form fields
  formFields = [
    {
      inputType: "text",
      tittle: "Email Template Name",
      placeholder: "Email Template Name",
      fieldName: "emailTemplateName",
      validation: [{ type: "require" }]

    },
    {
      inputType: "text",
      tittle: "Subject",
      placeholder: "Subject",
      fieldName: "subject",
      validation: [{ type: "require" }],
      isUpdate: true
    },
    {
      inputType: "checkbox",
      fieldName: "isActive",
      checked: true,
      emailTemplate: true
    },
    {
      inputType: "",
      tittle: "Email Body",
      fieldName: "emailBody",
      validation: [{ type: "require" }],
      emailTemplate: true
    },
  ];


  constructor() {
    super();
    this.onPaginationUpdate = this.onGetRequests.bind(this);
    this.actionKey = "emailTemplateId";
    this.dataItem = { ...emailDetails };
  }

  //** API Calls

  onGetRequests = (requst) => {
    this.apiService.onAPICall(
      "getRequest",
      this.getList,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };

  onAddEmailDetails = (requst) => {
    this.apiService.onAPICall(
      "addEmailDetails",
      this.addEmailDetails,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };

  onGetEmailDetailsById = (requst) => {
    this.apiService.onAPICall(
      "getEmailDetailsById",
      this.getEmailDetailsById,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };
  onUpdateEmailDetails = (requst) => {
    this.apiService.onAPICall(
      "updateEmailDetails",
      this.updateEmailDetails,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };

  //** Service Event */

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
    let valRule = getValidationRuleList(this.formFields)
    this.validationRules = valRule;
    this.onUpdateState({
      "validationRules": valRule,
    })
  }

  onResetData = () => {
    this.dataItem = { ...emailDetails };
    this.onUpdateState({ emailDetails: this.dataItem });
  };
  //** Methods     */

  onRedirectoList = () => {
    this.onRedirect("/EmailHistory");
  }

  onAPIResponse = (key, isSuccess, responseData) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "getRequest":
        if (responseData.itemList !== null) {
          let itemList = responseData.itemList;
          itemList.forEach(function (i) {
            i.emailTemplateName = i.emailTemplateName ? i.emailTemplateName : "-"
            i.subject = i.subject ? i.subject : "-"
          });
        }
        this.onUpdateState({
          dataList: responseData.itemList,
          TotalCount: responseData.totalCount,
          setIsLoad: false,
        });
        break;

      case "addEmailDetails":
        if (responseData.hasError == false) {
          this.apiService.swalServices.Toaster(SuccessMessage.Add_Success.replace("{0}", "Email Template"));
          this.onUpdateState({
            addEmailTemplate: responseData,
            setIsLoad: false
          });
          this.onResetData();
        }
        else {
          this.apiService.swalServices.Toaster("The column are invalid")
        }
        break;

      case "getEmailDetailsById":
        if (responseData != null) {
          this.onUpdateState({
            emailDetails: responseData,
            setIsLoad: false
          });
        }
        else {
          this.apiService.swalServices.Toaster("The column are invalid")
        }
        break;


      case "updateEmailDetails":
        if (responseData.hasError === false) {
          this.apiService.swalServices.Toaster(SuccessMessage.Update_Success.replace("{0}", "Email Template"));
          this.onGetEmailDetailsById(responseData.keyId)
          this.onUpdateState({
            emailDetails: responseData,
            setIsLoad: false
          });
        }
        else {
          this.apiService.swalServices.Toaster(responseData.message.DefaultMessage);
        }
        break;

    }

  };
}
