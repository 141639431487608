/* Component  */

import React from 'react';
import DemoPage from '../user/DemoPage';

function AddNewProducts() {
  return (
    <>
    <DemoPage />
    </>
  );
}

export default AddNewProducts;