import React, { useState } from "react";
import ComponentWizardServices from "../../services/axiosServices/apiServices/ComponentWizardServices";
import moment from 'moment';
import { saveAs } from 'file-saver';

const Table = ({ data: { data }, size, height, title, selectedDate, configurationId, componentWizardId }) => {
    // Parse the incoming data prop
    const parsedData = JSON.parse(data);
    // Extracting unique keys without duplicates from all objects in the data array
    const label = Array.from(new Set(parsedData.flatMap(Object.keys)));

    let colSpan;

    switch (size) {
        case 1:
            colSpan = '4';
            break;
        case 2:
            colSpan = '6';
            break;
        case 3:
            colSpan = '12';
            break;
        default:
            colSpan = '4'; // Default value if size doesn't match any case
    }
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const downloadlist = new ComponentWizardServices();

    const exportFile = () => {
        let req = [
            configurationId,
            moment(new Date(selectedDate)).format("YYYY-MM-DD hh:mm:ss"),
            componentWizardId
        ]
        downloadlist.exportGetComponentWizardsList(req).then((res) => {
            var formattedDate = moment(new Date()).format("YYMMDD")
            var filename = 'Export Data' + formattedDate;
            var blob = new Blob([res.data])
            saveAs(blob, filename + ".xlsx");
        })
    }

    return (
        <div
            className={`col-span-${colSpan} ${colSpan === "4" ? "table-4-height" : ""
                } shadow rounded-lg center-div Dashboard-card-count table-height-fix table-container`}
        >
            {parsedData.length === 0 ? (
                <p className="text-center text-gray-500">No records found</p>
            ) : (
                <table className="min-w-full bg-white">
                    <caption className="sticky top-0 bg-sidebar p-2 bg-[#fff9e5] ">
                        <span className="text-[#00000] flex justify-start items-center">
                            {title}
                            <i
                                className="fas fa-download ml-2 cursor-pointer xlsx-file"
                                onMouseEnter={() => setTooltipVisible(true)}
                                onMouseLeave={() => setTooltipVisible(false)}
                                onClick={() => exportFile()}
                            >
                                {tooltipVisible && (
                                    <div className="absolute bg-black text-white p-1 rounded-md xlsx-hover">
                                        Download
                                    </div>
                                )}
                            </i>
                        </span>
                    </caption>
                    <thead className="bg-gray-200 sticky top-[40px]">
                        <tr>
                            {label.map((item, index) => (
                                <th
                                    key={index}
                                    className="py-2 px-4 text-left font-bold text-gray-700 text-[15px]"
                                >
                                    {item}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                        {parsedData.map((item, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className={rowIndex % 2 === 0 ? "bg-gray-100" : ""}
                            >
                                {label.map((key, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="py-2 px-4 text-gray-800 text-[14px]"
                                    >
                                        {item[key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Table;